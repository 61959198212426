/* istanbul ignore file */

const ADDRESS_LABEL = {
  HOME: 'Home',
  WORK: 'Work',
  MAILBOX: 'Mailbox',
  OTHER: 'Other',
};

module.exports = ADDRESS_LABEL;
