import { useState, useCallback } from 'react';

const useToggle = (init = false) => {
  const [value, setValue] = useState(init);

  const toggle = useCallback(() => setValue((prev) => !prev), []);

  return [value, setValue, toggle];
};

export default useToggle;
