import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { blogMetadata } from '../queryKeys';
import { useFetchBlogMetadata, config } from '../query/useBlogMetadata';
import useTokenIsValid from '../useTokenIsValid';

const usePrefetchBlogMetadata = () => {
  const queryClient = useQueryClient();

  const fetchBlogMetadata = useFetchBlogMetadata();
  const isValid = useTokenIsValid();

  return useCallback(async () => {
    if (isValid()) {
      await queryClient.prefetchQuery({
        queryKey: blogMetadata(),
        queryFn: fetchBlogMetadata,
        ...config,
      });
    }
  }, [fetchBlogMetadata, queryClient, isValid]);
};

export default usePrefetchBlogMetadata;
