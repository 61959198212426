import { useCallback } from 'react';
import { useEditContext, useModalContext } from '../../../hooks/context';
import { modalTypes } from '../../../contexts/ModalContext';
import RemovableItem from './RemovableItem';
import Content from './Content';
import styles from './styles.module.scss';

const Church = ({ name, address = {}, id }) => {
  const { isEditing } = useEditContext();
  const { openModal } = useModalContext();

  const onDelete = useCallback(
    (e) => {
      e.stopPropagation();

      openModal(modalTypes.CONFIRM_DELETE_CHURCH, {
        churchId: id,
        churchName: name,
      });
    },
    [id, openModal, name]
  );

  return (
    <RemovableItem isEditing={isEditing} onRemove={onDelete}>
      <div className={styles.basicFlex}>
        <Content name={name}>
          <p>{name}</p>
          <p>{address.city}</p>
        </Content>
      </div>
    </RemovableItem>
  );
};

export default Church;
