const matchesSex = (sex, men, women, isAdult) => {
  if (men && women) {
    return isAdult;
  }

  if (men) {
    return sex === 'male' && isAdult;
  }

  if (women) {
    return sex === 'female' && isAdult;
  }

  return true;
};

export default matchesSex;
