import { locale } from '@churchms/shared';
import { ReactComponent as HiddenSVG } from '../../../icons/visibility_off.svg';
import styles from './styles.module.scss';

const HideUser = ({ onClick, onCloseContextMenu, isHidden }) => {
  return (
    <li className={styles.contextItem}>
      <button
        onClick={() => {
          onClick();
          onCloseContextMenu();
        }}
      >
        {isHidden ? locale.MODALS.SHOW_USER._ : locale.MODALS.HIDE_USER._}
        <HiddenSVG />
      </button>
    </li>
  );
};

export default HideUser;
