import { locale, toDateString } from '@churchms/shared';
import Form from './Form';

const sexOptions = [
  { key: 'male', value: 'male', children: 'male' },
  { key: 'female', value: 'female', children: 'female' },
];

const CreateUserForm = ({
  legend,
  state,
  onFirstName,
  onLastName,
  onEmail,
  onSex,
  onHideUser,
  onBirthday,
  onIsAdult,
  isNewChurch = false,
  isNewFamily = false,
  error,
}) => {
  const isEmailRequired = isNewChurch;
  const max = toDateString(new Date());

  return (
    <Form.Fieldset legend={legend}>
      <Form.TextField
        label={locale.COMMON.FIRST_NAME}
        value={state.name.first}
        onChange={onFirstName}
        type="text"
        required
      />
      <Form.TextField
        label={locale.COMMON.LAST_NAME}
        value={state.name.last}
        onChange={onLastName}
        type="text"
        required
      />
      <Form.TextField
        label={locale.COMMON.EMAIL}
        value={state.email}
        onChange={onEmail}
        type="email"
        required={isEmailRequired}
      />
      <Form.Dropdown
        label={locale.COMMON.SEX}
        options={sexOptions}
        onSelect={onSex}
        selected={state.sex}
        required
        includeEmptyOption
      />
      {!isEmailRequired && !state.email && (
        <p>{locale.MODALS.CREATE_USER.NO_EMAIL}</p>
      )}
      {!(isNewChurch || isNewFamily) && (
        <>
          <Form.Date
            label={locale.COMMON.BIRTHDAY}
            value={state.birthday}
            onChange={onBirthday}
            max={max}
          />
          <Form.Checkbox
            label={locale.MODALS.CREATE_USER.IS_ADULT}
            checked={state.isAdult}
            onChange={onIsAdult}
          />
        </>
      )}
      {!isNewChurch && (
        <Form.Checkbox
          label={locale.MODALS.CREATE_USER.HIDE_USER_LABEL}
          checked={state.isHidden}
          onChange={onHideUser}
        />
      )}
      {state.isHidden && <p>{locale.MODALS.CREATE_USER.HIDE_USER_WARNING}</p>}
      {error && <p>{error.message}</p>}
    </Form.Fieldset>
  );
};

export default CreateUserForm;
