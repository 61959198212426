import { locale } from '@churchms/shared';
import { modalTypes } from '../../../contexts/ModalContext';
import { usePerms } from '../../../hooks';
import { useEditContext, useModalContext } from '../../../hooks/context';
import { ReactComponent as EmailSVG } from '../../../icons/email.svg';
import ButtonAsLink from '../../ButtonAsLink';
import Content from './Content';
import styles from './styles.module.scss';
import Label from '../../Label';

const Email = ({ email, id }) => {
  const { isEditing } = useEditContext();
  const { isSelf } = usePerms({ targetUserId: id });
  const { openModal } = useModalContext();

  const onEdit = () => openModal(modalTypes.CHANGE_EMAIL, {});

  const content = (
    <Content name="Email" Icon={EmailSVG}>
      <Label>{locale.COMMON.EMAIL}</Label>
      <p>{email}</p>
    </Content>
  );

  if (isEditing) {
    return (
      <ButtonAsLink
        disabled={!isSelf}
        className={styles.clickableFlex}
        onClick={onEdit}
      >
        {content}
      </ButtonAsLink>
    );
  }

  return (
    <a href={`mailto:${email}`} className={styles.clickableFlex}>
      {content}
    </a>
  );
};

export default Email;
