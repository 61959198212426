import { useState } from 'react';
import { LISTING_TYPE, locale } from '@churchms/shared';
import { Form } from '../../../components';
import Body from '../Body';
import Footer from '../Footer';
import Header from '../Header';

const listingOptions = Object.values(LISTING_TYPE).map((t) => ({
  key: t,
  value: t,
  children: t.replace(/-/g, ' '),
}));

const emptyListing = {
  title: '',
  price: '',
  body: '',
  keywords: '',
};

export const useListingState = (listing = emptyListing) => {
  const [type, setType] = useState(
    listingOptions.find((o) => listing.type === o.value) || listingOptions[0]
  );
  const [title, setTitle] = useState(listing.title);
  const [price, setPrice] = useState(listing.price);
  const [body, setBody] = useState(listing.body);
  const [keywords, setKeywords] = useState(listing.keywords);

  const onTitle = (e) => setTitle(e.target.value);
  const onPrice = (e) => setPrice(e.target.value);
  const onBody = (e) => setBody(e.target.value);
  const onKeywords = (e) => setKeywords(e.target.value);

  return {
    type: type.value,
    setType,
    title,
    onTitle,
    price,
    onPrice,
    body,
    onBody,
    keywords,
    onKeywords,
  };
};

const Listing = ({
  modalTitle,
  submitButtonText,
  mutate,
  modalType,
  type,
  setType,
  title,
  onTitle,
  price,
  onPrice,
  body,
  onBody,
  keywords,
  onKeywords,
  id,
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    // Force it to check validity:
    const res = document.forms[modalType].reportValidity();

    if (res) {
      mutate({
        id,
        type,
        title,
        price,
        body,
        keywords,
      });
    }
  };

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSubmit,
      primary: true,
      children: submitButtonText,
      disabled: !title.trim() || !body.trim(),
      type: 'submit',
    },
  ];

  return (
    <Form.Wrapper onSubmit={onSubmit} id={modalType}>
      <Header title={modalTitle} />
      <Body>
        <Form.Dropdown
          label={locale.COMMON.TYPE}
          options={listingOptions}
          onSelect={setType}
          selected={type}
          required
        />
        <Form.TextField
          label={locale.COMMON.PRICE}
          value={price}
          onChange={onPrice}
        />
        <Form.TextField
          label={locale.COMMON.TITLE}
          value={title}
          onChange={onTitle}
          required
          fullWidth
        />
        <Form.TextArea
          label={locale.COMMON.BODY}
          value={body}
          onChange={onBody}
          required
          fullWidth
        />
        <Form.TextField
          label={locale.COMMON.KEYWORDS}
          value={keywords}
          onChange={onKeywords}
          fullWidth
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default Listing;
