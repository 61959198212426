import Header from './Header';
import Search from '../Search';
import styles from './styles.module.scss';

const Wrapper = ({
  title = '',
  className,
  visible,
  children,
  canSearch = false,
  renderH1 = true,
  ...props
}) => {
  return (
    <>
      <Header title={title} visible={visible} renderH1={renderH1} />
      {canSearch && <Search />}
      <div
        className={`${styles.page}${className ? ` page--${className}` : ''}`}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

export default Wrapper;
