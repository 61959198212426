import { locale } from '@churchms/shared';
import TextField from './TextField';

const URLInput = (props) => (
  <TextField
    label={locale.COMMON.URL}
    {...props}
    type="url"
    pattern="http(s|)://.*"
  />
);

export default URLInput;
