import { Link } from 'react-router-dom';
import { ReactComponent as BackSVG } from '../../icons/arrow_back.svg';
import styles from './styles.module.scss';

const BackLink = () => (
  <Link className={styles.backLink} to="/">
    <BackSVG />
    Back
  </Link>
);

export default BackLink;
