import { ReactComponent as DownSVG } from '../../icons/arrow_drop_down.svg';
import styles from './styles.module.scss';

const Dropdown = ({
  label,
  hideLabel = false,
  required = false,
  options = [],
  onSelect,
  selected,
  includeEmptyOption = false,
  className,
}) => (
  <label
    className={`${styles.textFieldLabel}${className ? ` ${className}` : ''}`}
  >
    <span className={hideLabel ? styles.hideLabel : ''}>
      {label}
      {required && '*'}
    </span>
    <div className={styles.dropdownWrapper}>
      <select
        required={required}
        onChange={(e) => {
          const value = options.find(({ key }) => `${key}` === e.target.value);

          onSelect(value);
        }}
        value={selected?.key}
        className={styles.dropdown}
      >
        {includeEmptyOption && <option value=""></option>}
        {options.map(({ key, children }) => (
          <option key={key} value={key}>
            {children}
          </option>
        ))}
      </select>
      <DownSVG />
    </div>
  </label>
);

export default Dropdown;
