import styles from './styles.module.scss';

const Spinner = ({ size = 80, isLoading, children }) => {
  const coordinate = size / 2;
  const strokeWidth = (() => {
    const s = size / 14;

    return s >= 2 ? s : 2;
  })();
  const radius = size / 2 - strokeWidth;
  const diameter = radius * 2;
  const strokeDasharray = (diameter * Math.PI) / 4;

  return (
    <>
      {isLoading && (
        <svg
          viewBox={`0 0 ${size} ${size}`}
          xmlns="http://www.w3.org/2000/svg"
          width={`${size}px`}
          height={`${size}px`}
          className={styles.svg}
        >
          <circle
            cx={coordinate}
            cy={coordinate}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
            fill="none"
            strokeLinecap="round"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;1"
              values={`0 ${coordinate} ${coordinate};360 ${coordinate} ${coordinate}`}
            />
          </circle>
        </svg>
      )}
      {children}
    </>
  );
};

export default Spinner;
