import { localeFormat } from '@churchms/shared';
import { LoadingOrError } from '../../../../components';
import styles from './styles.module.scss';

const List = ({ items, countText, renderFn, isLoading }) => (
  <div className={styles.feedList}>
    <LoadingOrError isLoading={isLoading} showChildrenWhileLoading size={64}>
      <p>
        {localeFormat(countText, items.length, items.length === 1 ? '' : 's')}
      </p>
      <ul>{items.map(renderFn)}</ul>
    </LoadingOrError>
  </div>
);

export default List;
