/* istanbul ignore file */

const emptyUser = {
  name: {
    first: '',
    last: '',
  },
  email: '',
  isHidden: false,
  phoneNumbers: [],
  addresses: [],
  birthday: '',
  isAdult: false,
  sex: 'male',
};

module.exports = emptyUser;
