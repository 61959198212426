import { useModalContext } from '../../hooks/context';
import { ReactComponent as CloseSVG } from '../../icons/cancel.svg';
import { IconButton } from '../../components';
import styles from './styles.module.scss';

const Wrapper = ({ children, modalType }) => {
  const { closeModal } = useModalContext();

  return (
    <div className={`${styles.wrapper} ${modalType}`}>
      <IconButton className={styles.closeBtn} onClick={closeModal}>
        <CloseSVG />
      </IconButton>
      <div>{children}</div>
    </div>
  );
};

export default Wrapper;
