function fetchFn(path, config = {}, clientErrorMessage) {
  const {
    method = 'GET',
    headers = {},
    body,
    isMultiPart = false,
    isBlob = false,
    ...rest
  } = config;

  return fetch(path, {
    method,
    headers: {
      ...(isMultiPart ? {} : { 'Content-Type': 'application/json' }),
      ...headers,
    },
    body: body ? (isMultiPart ? body : JSON.stringify(body)) : undefined,
    credentials: 'include',
    ...rest,
  }).then((res) => {
    if (res.status === 200) {
      if (isBlob) {
        return res.blob();
      }
      return res.json();
    }
    throw new Error(clientErrorMessage);
  });
}

export default fetchFn;
