import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dialogPolyfill from 'dialog-polyfill';
import { useModalContext } from '../../hooks/context';
import Switcher from './Switcher';
import styles from './styles.module.scss';
import Wrapper from './Wrapper';
import { ErrorBoundary } from '../../components';
import ErrorModal from './Modals/ErrorModal';

const Modal = () => {
  const { isOpen, modalType, content, closeModal } = useModalContext();
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.close) {
      const dialog = ref.current;
      dialogPolyfill.registerDialog(dialog);
      if (isOpen) {
        dialog.close();
        dialog.showModal();
      } else {
        dialog.close();
      }
    }
  }, [isOpen]);

  // Close modal when user navigates (swipe to navigate on mobile)
  useEffect(() => {
    closeModal();
  }, [closeModal, location]);

  return (
    <dialog
      className={`${styles.self}${isOpen ? ` ${styles.fixed}` : ''}`}
      ref={ref}
      onCancel={closeModal}
    >
      {isOpen && (
        <Wrapper modalType={modalType}>
          <ErrorBoundary
            modalType={modalType}
            {...content}
            FallbackComponent={ErrorModal}
          >
            <Switcher
              closeModal={closeModal}
              modalType={modalType}
              {...content}
            />
          </ErrorBoundary>
        </Wrapper>
      )}
    </dialog>
  );
};

export default Modal;
