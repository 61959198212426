import { useCallback, useMemo, useState } from 'react';
import { formatDatePickerDate, isAdult, locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useChangeHeadOfHousehold } from '../../../hooks/mutate';

const ChangeHeadOfHousehold = ({ closeModal, modalType, id }) => {
  const { data: found } = useDirectory((d) =>
    d.families.find((f) => f.id === id)
  );
  const options = found.members
    .filter(
      (u) =>
        u.id !== found.headOfHousehold &&
        (u.isAdult || isAdult(formatDatePickerDate(u.birthday)))
    )
    .map(({ name, id }) => ({
      key: id,
      value: id,
      children: `${name.first} ${name.last}`,
    }))
    .sort(({ children: a }, { children: b }) => a.localeCompare(b));
  const [newHead, setNewHead] = useState(options[0]);

  const { mutate, error } = useChangeHeadOfHousehold(id, {
    _onMutate: closeModal,
  });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate(newHead.value);
      }
    },
    [modalType, newHead, mutate]
  );

  const footerBtns = useMemo(() => {
    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        type: 'submit',
      },
    ];
  }, [onSave]);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.CHANGE_HEAD_OF_HOUSEHOLD._} />
      <Body>
        <Form.Dropdown
          label={locale.MODALS.CHANGE_HEAD_OF_HOUSEHOLD.LABEL}
          selected={newHead}
          options={options}
          onSelect={setNewHead}
          required
          error={error}
          hideLabel
        />
        <p>{locale.MODALS.CHANGE_HEAD_OF_HOUSEHOLD.DESCRIPTION}</p>
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default ChangeHeadOfHousehold;
