import { locale } from '@churchms/shared';
import Form from './Form';

const CreateAddressForm = ({
  legend = locale.COMMON.ADDRESS,
  required = false,
  hideLegend = false,
  state,
  onStreet1,
  onStreet2,
  onCity,
  onState,
  onCountry,
  onZipCode,
  error,
  children,
}) => (
  <Form.Fieldset legend={hideLegend ? '' : legend}>
    {children}
    <Form.TextField
      label={locale.COMMON.STREET1}
      value={state.street1}
      onChange={onStreet1}
      required={required}
    />
    <Form.TextField
      label={locale.COMMON.STREET2}
      value={state.street2}
      onChange={onStreet2}
    />
    <Form.TextField
      required={required}
      label={locale.COMMON.CITY}
      value={state.city}
      onChange={onCity}
    />
    <Form.DataList
      label={locale.COMMON.STATE}
      value={state.state}
      onChange={onState}
      pattern="(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])"
      list={Form.statesOfAmerica}
      required={required}
    />
    <Form.ZipCode
      label={locale.COMMON.ZIP_CODE}
      value={state.zipCode}
      onChange={onZipCode}
      required={required}
    />
    <Form.DataList
      label={locale.COMMON.COUNTRY}
      value={state.country}
      onChange={onCountry}
      required={required}
      list={Form.countries}
      error={error}
    />
  </Form.Fieldset>
);

export default CreateAddressForm;
