import { locale } from '@churchms/shared';
import { useChurch } from '../../../../hooks/query';
import { useEditChurch } from '../../../../hooks/mutate';
import Church from '../Church';

const EditChurch = ({ modalType, closeModal }) => {
  const { data } = useChurch();
  const { mutate } = useEditChurch({ _onMutate: closeModal });

  return (
    <Church
      title={locale.MODALS.EDIT_CHURCH._}
      modalType={modalType}
      mutate={mutate}
      church={data}
    />
  );
};

export default EditChurch;
