/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale, objectToQueryParams } from '@churchms/shared';
import { church } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import useLogin from './useLogin';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 12 * HOUR,
};

export const useFetchChurch = () => {
  const fetchWithJWT = useFetchWithJWT();

  const fetchChurch = useCallback(
    ({ queryKey }) => {
      const [, role] = queryKey;

      return fetchWithJWT(
        `/api/v1/church${objectToQueryParams({ role })}`,
        {},
        locale.HTTP.ERROR.GET_CHURCH
      );
    },
    [fetchWithJWT]
  );

  return fetchChurch;
};

const useChurch = (select) => {
  const { data: role } = useLogin((d) => d.role);
  const fetchChurch = useFetchChurch();
  const isValid = useTokenIsValid();

  return useQuery({
    queryKey: church(role),
    queryFn: fetchChurch,
    select,
    enabled: !!role && isValid(),
    ...config,
  });
};

export default useChurch;
