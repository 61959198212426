import IconButton from '../IconButton';
import { ReactComponent as DeleteSVG } from '../../icons/delete.svg';
import styles from './styles.module.scss';

const RemoveableItemButton = ({ className, ...props }) => (
  <IconButton
    className={`${styles.removeableItemButton}${
      className ? ` ${className}` : ''
    }`}
    {...props}
  >
    <DeleteSVG />
  </IconButton>
);

export default RemoveableItemButton;
