import DocumentTitle from 'react-document-title';
import { APP_NAME } from '@churchms/shared';
import styles from './styles.module.scss';

const Header = ({ renderH1, title, visible }) => (
  <div className={visible ? styles.visibleHeader : styles.header}>
    <DocumentTitle
      title={!title ? APP_NAME.SHORT : `${title} | ${APP_NAME.SHORT}`}
    />
    {renderH1 && title && <h1>{title}</h1>}
  </div>
);

export default Header;
