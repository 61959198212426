import styles from './styles.module.scss';

const size = 100;
const radius = size / 2;
export const smallerRadius = radius * 0.9;
const imgSize = smallerRadius * 2;
const borderWidth = size - imgSize;
export const middle = size / 2;
const fontSize = 42;
const imageOffset = borderWidth / 2;
const iconSize = imgSize * 0.4;
const iconOffset = (size - iconSize) / 2;

const Svg = ({
  outerBg,
  imgBg,
  initials = '',
  img,
  Icon,
  isEditing = false,
}) => (
  <svg
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
  >
    {/* Border */}
    <circle cx={middle} cy={middle} r={radius} fill={outerBg} />

    {/* Background */}
    <circle cx={middle} cy={middle} r={smallerRadius} fill={imgBg} />

    {/* Text */}
    {!(img || Icon) && (
      <text
        x={middle}
        y={middle + 5}
        fontSize={`${fontSize / 10}rem`}
        fill="white"
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        {initials}
      </text>
    )}

    {/* Image */}
    {img && (
      <image
        x={imageOffset}
        y={imageOffset}
        href={img}
        width={imgSize}
        height={imgSize}
        clipPath="url(#clip)"
        className={styles.image}
        filter={isEditing ? 'url(#blur)' : ''}
      />
    )}

    {/* Icon */}
    {Icon && (
      <Icon
        className={styles.icon}
        width={iconSize}
        height={iconSize}
        x={iconOffset}
        y={iconOffset}
      />
    )}
  </svg>
);

export default Svg;
