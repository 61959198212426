// const statesOfAmerica = [
//   { value: 'AL', desc: 'Alabama' },
//   { value: 'AK', desc: 'Alaska' },
//   { value: 'AZ', desc: 'Arizona' },
//   { value: 'AR', desc: 'Arkansas' },
//   { value: 'CA', desc: 'California' },
//   { value: 'CO', desc: 'Colorado' },
//   { value: 'CT', desc: 'Connecticut' },
//   { value: 'DE', desc: 'Delaware' },
//   { value: 'FL', desc: 'Florida' },
//   { value: 'GA', desc: 'Georgia' },
//   { value: 'HI', desc: 'Hawaii' },
//   { value: 'ID', desc: 'Idaho' },
//   { value: 'IL', desc: 'Illinois' },
//   { value: 'IN', desc: 'Indiana' },
//   { value: 'IA', desc: 'Iowa' },
//   { value: 'KS', desc: 'Kansas' },
//   { value: 'KY', desc: 'Kentucky' },
//   { value: 'LA', desc: 'Louisiana' },
//   { value: 'ME', desc: 'Maine' },
//   { value: 'MD', desc: 'Maryland' },
//   { value: 'MA', desc: 'Massachusetts' },
//   { value: 'MI', desc: 'Michigan' },
//   { value: 'MN', desc: 'Minnesota' },
//   { value: 'MS', desc: 'Mississippi' },
//   { value: 'MO', desc: 'Missouri' },
//   { value: 'MT', desc: 'Montana' },
//   { value: 'NE', desc: 'Nebraska' },
//   { value: 'NV', desc: 'Nevada' },
//   { value: 'NH', desc: 'New Hampshire' },
//   { value: 'NJ', desc: 'New Jersey' },
//   { value: 'NM', desc: 'New Mexico' },
//   { value: 'NY', desc: 'New York' },
//   { value: 'NC', desc: 'North Carolina' },
//   { value: 'ND', desc: 'North Dakota' },
//   { value: 'OH', desc: 'Ohio' },
//   { value: 'OK', desc: 'Oklahoma' },
//   { value: 'OR', desc: 'Oregon' },
//   { value: 'PA', desc: 'Pennsylvania' },
//   { value: 'RI', desc: 'Rhode Island' },
//   { value: 'SC', desc: 'South Carolina' },
//   { value: 'SD', desc: 'South Dakota' },
//   { value: 'TN', desc: 'Tennessee' },
//   { value: 'TX', desc: 'Texas' },
//   { value: 'UT', desc: 'Utah' },
//   { value: 'VT', desc: 'Vermont' },
//   { value: 'VA', desc: 'Virginia' },
//   { value: 'WA', desc: 'Washington' },
//   { value: 'WV', desc: 'West Virginia' },
//   { value: 'WI', desc: 'Wisconsin' },
//   { value: 'WY', desc: 'Wyoming' },
// ];

const statesOfAmerica = [
  { value: 'AL' },
  { value: 'AK' },
  { value: 'AZ' },
  { value: 'AR' },
  { value: 'CA' },
  { value: 'CO' },
  { value: 'CT' },
  { value: 'DE' },
  { value: 'FL' },
  { value: 'GA' },
  { value: 'HI' },
  { value: 'ID' },
  { value: 'IL' },
  { value: 'IN' },
  { value: 'IA' },
  { value: 'KS' },
  { value: 'KY' },
  { value: 'LA' },
  { value: 'ME' },
  { value: 'MD' },
  { value: 'MA' },
  { value: 'MI' },
  { value: 'MN' },
  { value: 'MS' },
  { value: 'MO' },
  { value: 'MT' },
  { value: 'NE' },
  { value: 'NV' },
  { value: 'NH' },
  { value: 'NJ' },
  { value: 'NM' },
  { value: 'NY' },
  { value: 'NC' },
  { value: 'ND' },
  { value: 'OH' },
  { value: 'OK' },
  { value: 'OR' },
  { value: 'PA' },
  { value: 'RI' },
  { value: 'SC' },
  { value: 'SD' },
  { value: 'TN' },
  { value: 'TX' },
  { value: 'UT' },
  { value: 'VT' },
  { value: 'VA' },
  { value: 'WA' },
  { value: 'WV' },
  { value: 'WI' },
  { value: 'WY' },
];

export default statesOfAmerica;
