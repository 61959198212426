import { locale } from '@churchms/shared';
import styles from './styles.module.scss';

const { FEEDBACK } = locale.MODALS;

const SimilarIssues = ({ data = [] }) =>
  !!data.length && (
    <div className={styles.similarIssues}>
      <p>
        {data.length}{' '}
        {data.length === 1
          ? FEEDBACK.SIMILAR_ISSUE_SINGLE
          : FEEDBACK.SIMILAR_ISSUE_MULTIPLE}
        :
      </p>
      <ul>
        {data.map(({ id, title, labels, body }) => (
          <li key={id}>
            {body ? (
              <details>
                <summary>
                  <div className={styles.issueTitle}>{title}</div>
                </summary>

                <div className={styles.issueBody}>{body}</div>
              </details>
            ) : (
              <div className={styles.issueTitle}>{title}</div>
            )}

            <div className={styles.labels}>
              {labels.map((l) => (
                <span
                  key={l.id}
                  className={styles.label}
                  style={{ backgroundColor: `#${l.color}` }}
                >
                  {l.name}
                </span>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

export default SimilarIssues;
