import { ROLE } from '@churchms/shared';
import useLogin from './query/useLogin';
import useChurch from './query/useChurch';

const useCanShowBlog = () => {
  const { data: isAdmin } = useLogin((d) => d.role === ROLE.ADMIN);
  const { data: hasFeedUrls } = useChurch((d) => !!d?.feedUrls?.length);

  return isAdmin || hasFeedUrls;
};

export default useCanShowBlog;
