/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useChangePassword = ({ _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();

  const changePassword = useCallback(
    (payload) =>
      fetchWithJWT(
        `/api/v1/user/change-password`,
        {
          method: 'POST',
          body: payload,
        },
        locale.HTTP.ERROR.CHANGE_USER_NAME
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: changePassword,
    onMutate: _onMutate,
    onError: (err) => {
      openModal(modalTypes.ERROR, { message: err.message });
    },
  });
};

export default useChangePassword;
