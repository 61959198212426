import { useCallback } from 'react';
import { getNameFromUserOrFamily } from '../utils';

const useSortUsersAndFamilies = () => {
  const sortByProperty = (a, b, property) =>
    a[property].toLowerCase().localeCompare(b[property].toLowerCase());

  return useCallback((a, b) => {
    const _a = getNameFromUserOrFamily(a);
    const _b = getNameFromUserOrFamily(b);
    const sortByLast = sortByProperty(_a, _b, 'last');
    if (sortByLast !== 0) {
      return sortByLast;
    }
    return sortByProperty(_a, _b, 'first');
  }, []);
};

export default useSortUsersAndFamilies;
