import { useCallback, useMemo, useState } from 'react';
import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useChangeFamilyName } from '../../../hooks/mutate';

const ChangeFamilyName = ({ closeModal, modalType, id }) => {
  const { data: found } = useDirectory((d) =>
    d.families.find((f) => f.id === id)
  );
  const [changeMembers, setChangeMembers] = useState(true);
  const [surname, setSurname] = useState(found.surname);

  const { mutate, error } = useChangeFamilyName(id, {
    _onMutate: closeModal,
  });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({ surname, changeMembers });
      }
    },
    [modalType, surname, mutate, changeMembers]
  );

  const footerBtns = useMemo(() => {
    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        type: 'submit',
      },
    ];
  }, [onSave]);

  const onChange = (e) => setSurname(e.target.value);
  const onToggleChangeMembers = () => setChangeMembers((prev) => !prev);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.CHANGE_FAMILY_NAME._} />
      <Body>
        <Form.TextField
          label={locale.COMMON.SURNAME}
          value={surname}
          onChange={onChange}
          required
          error={error}
        />
        <Form.Checkbox
          label={locale.MODALS.CHANGE_FAMILY_NAME.CHANGE_MEMBERS_ALSO}
          checked={changeMembers}
          onChange={onToggleChangeMembers}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default ChangeFamilyName;
