import { emptyUser, locale, localeFormat } from '@churchms/shared';
import { useCreateUser } from '../../../hooks/mutate';
import CreateUserOrFamily from './CreateUserOrFamily';

const CreateUser = ({ modalType, familyId, surname, closeModal }) => {
  const { mutate, isLoading, error } = useCreateUser(familyId, {
    _onMutate: closeModal,
  });

  const init = {
    ...emptyUser,
    name: {
      ...emptyUser.name,
      last: surname,
    },
  };

  return (
    <CreateUserOrFamily
      mutate={mutate}
      isLoading={isLoading}
      error={error}
      modalType={modalType}
      legend={localeFormat(locale.MODALS.CREATE_USER.LEGEND, surname)}
      title={locale.MODALS.CREATE_USER._}
      init={init}
    />
  );
};

export default CreateUser;
