/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { issues } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import useLogin from './useLogin';
import { useTokenIsValid } from '..';

const useIssues = (search = '') => {
  const { data } = useLogin();

  const fetchWithJWT = useFetchWithJWT();
  const isValid = useTokenIsValid();

  const fetchIssues = useCallback(
    ({ queryKey }) => {
      const [, s] = queryKey;
      return fetchWithJWT(
        `/api/v1/issues?q=${s}`,
        {},
        locale.HTTP.ERROR.GET_ISSUES
      );
    },
    [fetchWithJWT]
  );

  return useQuery({
    queryKey: issues(search),
    queryFn: fetchIssues,
    staleTime: HOUR,
    enabled: !!data.id && isValid(),
  });
};

export default useIssues;
