import { useMemo } from 'react';
import { locale, localeFormat } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useDeleteChurch } from '../../../hooks/mutate';

const ConfirmDeleteChurch = ({ churchId, churchName, closeModal }) => {
  const { mutate } = useDeleteChurch({ _onMutate: closeModal });
  const footerBtns = useMemo(
    () => [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: () => mutate(churchId),
        primary: true,
        children: locale.COMMON.CONFIRM,
      },
    ],
    [mutate, churchId]
  );

  return (
    <>
      <Header title={locale.MODALS.DELETE_CHURCH._} />
      <Body>
        {localeFormat(locale.MODALS.DELETE_CHURCH.CONFIRM, churchName)}
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default ConfirmDeleteChurch;
