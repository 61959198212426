import { locale } from '@churchms/shared';
import { ReactComponent as AddProfilePictureSVG } from '../../../icons/add_a_photo.svg';
import styles from './styles.module.scss';

const AddProfilePicture = ({ onClick, onCloseContextMenu }) => (
  <li className={styles.contextItem}>
    <button
      onClick={() => {
        onClick();
        onCloseContextMenu();
      }}
    >
      {locale.MODALS.ADD_PROFILE_PICTURE._}
      <AddProfilePictureSVG />
    </button>
  </li>
);

export default AddProfilePicture;
