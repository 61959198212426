import { locale } from '@churchms/shared';
import { ReactComponent as CakeSVG } from '../../../icons/cake.svg';
import { usePerms } from '../../../hooks';
import { useEditContext, useModalContext } from '../../../hooks/context';
import ButtonAsLink from '../../ButtonAsLink';
import { modalTypes } from '../../../contexts/ModalContext';
import RemovableItem from './RemovableItem';
import Content from './Content';
import styles from './styles.module.scss';
import Label from '../../Label';

const Birthday = ({
  birthday,
  isAdult,
  id,
  isOptimistic = false,
  name,
  familyId,
}) => {
  const { isEditing } = useEditContext();
  const { isAdmin, isSelf, isHeadOfHousehold } = usePerms({
    targetUserId: id,
    targetFamilyId: familyId,
  });
  const canAdd = isAdmin || isSelf || isHeadOfHousehold;
  const { openModal } = useModalContext();
  const onDelete = () =>
    openModal(modalTypes.DELETE_BIRTHDAY, { userId: id, name });
  const onEdit = () =>
    openModal(modalTypes.EDIT_BIRTHDAY, { userId: id, birthday, isAdult });

  const content = (
    <Content name={locale.COMMON.BIRTHDAY} Icon={CakeSVG}>
      <Label>{locale.COMMON.BIRTHDAY}</Label>
      <p>
        {birthday
          ? new Date(birthday).toLocaleString('default', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : locale.USER_DETAILS.ADD_BIRTHDAY}
      </p>
    </Content>
  );

  const linkItem = (
    <ButtonAsLink
      disabled={isOptimistic}
      className={styles.clickableFlex}
      onClick={onEdit}
    >
      {content}
    </ButtonAsLink>
  );

  if (isEditing && canAdd) {
    return birthday ? (
      <RemovableItem isEditing onRemove={onDelete} disabled={isOptimistic}>
        {linkItem}
      </RemovableItem>
    ) : (
      linkItem
    );
  }

  return <div className={styles.basicFlex}>{content}</div>;
};

export default Birthday;
