import { locale } from '@churchms/shared';
import { useCreateListing } from '../../../hooks/mutate';
import Listing, { useListingState } from './Listing';

const CreateListing = ({ modalType, closeModal }) => {
  const { mutate } = useCreateListing({ _onMutate: closeModal });

  const props = useListingState();

  return (
    <Listing
      modalTitle={locale.MODALS.CREATE_LISTING._}
      submitButtonText={locale.COMMON.CREATE}
      mutate={mutate}
      modalType={modalType}
      {...props}
    />
  );
};

export default CreateListing;
