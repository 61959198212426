import { locale } from '@churchms/shared';
import { ReactComponent as MessageSVG } from '../../../icons/message.svg';
import { ReactComponent as EmailSVG } from '../../../icons/email.svg';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import styles from './shareDirectory.module.scss';

const ShareDirectory = ({
  closeModal,
  mailToLink,
  mailToCount,
  smsLink,
  smsCount,
}) => {
  const footerBtns = [
    {
      onClick: closeModal,
      primary: true,
      children: locale.COMMON.OK,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.SHARE_DIRECTORY._} />
      <Body>
        <ul className={styles.shareList}>
          {!!mailToCount && (
            <li className={styles.shareItem}>
              <a href={mailToLink} target="_blank" rel="noopener noreferrer">
                <EmailSVG />
                Email {mailToCount} users
              </a>
            </li>
          )}
          {!!smsCount && (
            <li className={styles.shareItem}>
              <a href={smsLink} target="_blank" rel="noopener noreferrer">
                <MessageSVG />
                Text {smsCount} users
              </a>
            </li>
          )}
        </ul>
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default ShareDirectory;
