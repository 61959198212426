import StickyHeader from './StickyHeader';
import Wrapper from './Wrapper';
import styles from './styles.module.scss';

const Grid = ({
  items = [],
  title,
  isLoading,
  isPlaceholderData,
  error,
  className,
  itemProps = {},
}) => (
  <>
    {title && <StickyHeader title={title} />}
    <Wrapper
      className={className}
      error={error}
      isLoading={isLoading}
      isPlaceholderData={isPlaceholderData}
      noItems={!isLoading && !error && !items.length}
    >
      {items.map(({ Component, key, ...item }) => (
        <div className={styles.gridItem} key={key}>
          <Component {...item} {...itemProps} />
        </div>
      ))}
    </Wrapper>
  </>
);

export default Grid;
