import { useCallback } from 'react';
import { PHONE_LABEL, locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';

export const phoneOptions = Object.values(PHONE_LABEL).map((key) => ({
  key,
  value: key,
  children: key,
}));

const PhoneNumber = ({
  formId,
  mutate,
  title,
  value,
  setValue,
  label,
  setLabel,
  userId,
}) => {
  const { data: isDuplicate } = useDirectory((d) => {
    const found = d.users.find(({ id }) => id === userId);
    return found.phoneNumbers.some(
      (p) => p.label === label.value && p.value === value
    );
  });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[formId].reportValidity();

      if (res) {
        mutate({
          label: label.value,
          value,
        });
      }
    },
    [label, value, mutate, formId]
  );

  const onNumber = (e) => setValue(e.target.value);

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: locale.COMMON.SAVE,
      disabled: isDuplicate,
      type: 'submit',
    },
  ];

  return (
    <Form.Wrapper onSubmit={onSave} id={formId}>
      <Header title={title} />
      <Body>
        <Form.Dropdown
          label={locale.COMMON.LABEL}
          options={phoneOptions}
          onSelect={setLabel}
          selected={label}
          required
        />
        <Form.PhoneNumber
          label={locale.COMMON.PHONE_NUMBER}
          value={value}
          onChange={onNumber}
          required
          error={isDuplicate && locale.HTTP.ERROR.DUPLICATE_PHONE_NUMBER}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default PhoneNumber;
