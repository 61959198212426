import { locale } from '@churchms/shared';
import { Form, IconButton } from '../../../../components';
import { ReactComponent as SearchSVG } from '../../../../icons/search.svg';
import FeedList from './FeedList';
import styles from './styles.module.scss';

const URLForm = ({
  value,
  setValue,
  onSearch,
  websites,
  formId,
  onToggleFeedUrl,
  feedUrls,
}) => (
  <>
    <Form.Wrapper className={styles.urlForm} onSubmit={onSearch} id={formId}>
      <div className={styles.addRow}>
        <Form.URL
          label={locale.COMMON.WEBSITE}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        <IconButton
          type="submit"
          onClick={onSearch}
          className={styles.addRowButton}
        >
          <SearchSVG />
        </IconButton>
      </div>
    </Form.Wrapper>

    <FeedList
      websites={websites}
      onToggleFeedUrl={onToggleFeedUrl}
      feedUrls={feedUrls}
    />
  </>
);

export default URLForm;
