import styles from './styles.module.scss';
import NavIcon from '../NavIcon';

const Crumb = ({ withSlash = false, isLast, ...props }) => {
  return (
    <>
      {withSlash && <span className={styles.slash}>{'/'}</span>}
      <NavIcon isStatic={isLast} {...props} />
    </>
  );
};

export default Crumb;
