/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { churches } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useDeleteChurch = ({ _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const queryClient = useQueryClient();
  const fetchWithJWT = useFetchWithJWT();
  const queryKey = churches();

  const deleteChurch = useCallback(
    (churchId) =>
      fetchWithJWT(
        `/api/v1/churches/${churchId}`,
        {
          method: 'DELETE',
        },
        locale.HTTP.ERROR.CHURCH_DELETE
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: deleteChurch,
    onMutate: async (churchId) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousChurchList = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (prev) => {
        // if no cache yet, do nothing
        if (!prev) return prev;

        return prev.filter(({ id }) => id !== churchId);
      });

      _onMutate();

      // Return a context object with the snapshotted value
      return { previousChurchList };
    },
    onError: (err, variables, { previousChurchList }) => {
      queryClient.setQueryData(queryKey, previousChurchList);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });
};

export default useDeleteChurch;
