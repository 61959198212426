import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { login } from './queryKeys';

const useTokenIsValid = () => {
  const queryClient = useQueryClient();
  const cache = queryClient.getQueryState(login());
  const { data, status } = cache || {};
  const { token, expiresAfter } = data || {};

  return useCallback(
    () =>
      !!status &&
      status === 'success' &&
      !!token &&
      !!expiresAfter &&
      Date.now() < expiresAfter,
    [status, token, expiresAfter]
  );
};

export default useTokenIsValid;
