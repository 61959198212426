/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useDeleteUser = (
  familyId,
  willDeleteFamily = false,
  { _onMutate = () => {} } = {}
) => {
  const history = useHistory();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const removeUser = useCallback(
    (userId) =>
      fetchWithJWT(
        `/api/v1/user${objectToQueryParams({ userId })}`,
        {
          method: 'DELETE',
        },
        locale.HTTP.ERROR.USER_DELETE
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: removeUser,
    onMutate: async (userId) => {
      _onMutate();
      if (willDeleteFamily) {
        history.push('/directory');
      } else {
        history.push(`/directory/${familyId}`);
      }

      const filterUser = (p) => p.id !== userId;

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        const users = prev.users.filter(filterUser);

        if (willDeleteFamily) {
          return {
            families: prev.families.filter(({ id }) => id !== familyId),
            users,
          };
        }

        return {
          families: prev.families.map((family) => {
            if (family.id === familyId) {
              return {
                ...family,
                members: family.members.filter(filterUser),
                headOfHousehold:
                  family.headOfHousehold === userId
                    ? undefined
                    : family.headOfHousehold,
              };
            }
            return family;
          }),
          users,
        };
      });

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, variables, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useDeleteUser;
