/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import useFetchWithJWT from '../useFetchWithJWT';

const useChangeEmail = () => {
  const fetchWithJWT = useFetchWithJWT();

  const changeEmail = useCallback(
    (email) =>
      fetchWithJWT(
        '/api/v1/user/change-email',
        {
          method: 'PUT',
          body: { email },
        },
        locale.HTTP.ERROR.UPDATE_USER
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: changeEmail,
  });
};

export default useChangeEmail;
