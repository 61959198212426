import { useMemo } from 'react';
import { locale } from '@churchms/shared';
import { usePerms } from '../../../hooks';
import { useEditContext, useModalContext } from '../../../hooks/context';
import { useDirectory } from '../../../hooks/query';
import Avatar from '../../Avatar';
import ContextMenu, { ContextMenuItem } from '../../ContextMenu';
import HiddenIcon from '../../HiddenIcon';
import styles from './styles.module.scss';
import { modalTypes } from '../../../contexts/ModalContext';
import BreadCrumbs from '../../BreadCrumbs';
import Label from '../../Label';
import BlurredBackground from './BlurredBackground';

const User = ({ name, isHidden, id, familyId, email, isOptimistic }) => {
  const { data: found } = useDirectory((d) => {
    return d.families.find((f) => f.id === familyId);
  });
  const targetUserIsHeadOfTheirHousehold = id === found.headOfHousehold;
  const fullName = name?.first ? `${name.first} ${name.last}` : '';
  const { openModal } = useModalContext();
  const { isSelf, isAdmin, isHeadOfHousehold } = usePerms({
    targetUserId: id,
    targetFamilyId: familyId,
  });

  const { isEditing } = useEditContext();

  const contextMenuItems = useMemo(() => {
    const items = [];

    if (isAdmin) {
      items.push({
        key: 'change-name',
        Component: ContextMenuItem.ChangeName,
        onClick: () => openModal(modalTypes.CHANGE_NAME, { id }),
      });

      if (!isSelf && email) {
        items.push({
          key: 'change-role',
          Component: ContextMenuItem.ChangeRole,
          onClick: () => openModal(modalTypes.CHANGE_ROLE, { id }),
        });
      }

      items.push({
        key: 'move-user',
        Component: ContextMenuItem.MoveUser,
        onClick: () => openModal(modalTypes.MOVE_USER, { id }),
      });

      if (found.members.length > 1) {
        items.push({
          key: 'change-head-of-household',
          Component: ContextMenuItem.ChangeHeadOfHousehold,
          onClick: () =>
            openModal(modalTypes.CHANGE_HEAD_OF_HOUSEHOLD, { id: familyId }),
        });
      }
    }

    if (!isSelf && isAdmin) {
      items.push({
        key: 'hide-user',
        Component: ContextMenuItem.HideUser,
        onClick: () => openModal(modalTypes.HIDE_USER, { id }),
        isHidden,
      });
      items.push({
        key: 'delete-user',
        Component: ContextMenuItem.DeleteUser,
        onClick: () =>
          openModal(modalTypes.DELETE_USER, { userId: id, name, familyId }),
      });
    }

    if (isSelf || isHeadOfHousehold || isAdmin) {
      items.push({
        key: 'add-profile-picture',
        Component: ContextMenuItem.AddProfilePicture,
        onClick: () => openModal(modalTypes.ADD_PROFILE_PICTURE, { id }),
      });
      items.push({
        key: 'delete-profile-picture',
        Component: ContextMenuItem.DeleteProfilePicture,
        id,
        onClick: () => openModal(modalTypes.DELETE_PROFILE_PICTURE, { id }),
      });
    }

    return items;
  }, [
    isAdmin,
    isSelf,
    isHeadOfHousehold,
    email,
    found.members.length,
    openModal,
    id,
    familyId,
    isHidden,
    name,
  ]);

  return (
    <>
      <div className={styles.topRow}>
        <div>
          <BreadCrumbs />
          <ContextMenu items={contextMenuItems} />
        </div>
      </div>
      <div className={styles.detailsHeader}>
        <Avatar
          name={fullName}
          size={isEditing ? 'medium' : 'x-large'}
          isEditing={isEditing && (isAdmin || isSelf || isHeadOfHousehold)}
          id={!isOptimistic && id}
        />
        <h2>{fullName}</h2>
        {targetUserIsHeadOfTheirHousehold && (
          <Label>{locale.COMMON.HEAD_OF_HOUSEHOLD}</Label>
        )}
        {isHidden && <HiddenIcon />}
        <BlurredBackground id={id} />
      </div>
    </>
  );
};

export default User;
