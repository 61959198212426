import { locale } from '@churchms/shared';
import { ReactComponent as MoveFamilySVG } from '../../../icons/group.svg';
import styles from './styles.module.scss';

const MoveUser = ({ onClick, onCloseContextMenu }) => (
  <li className={styles.contextItem}>
    <button
      onClick={() => {
        onClick();
        onCloseContextMenu();
      }}
    >
      {locale.MODALS.MOVE_USER._}
      <MoveFamilySVG />
    </button>
  </li>
);

export default MoveUser;
