import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { useParams } from '../../hooks';
import Crumb from './Crumb';
import { ReactComponent as DirectorySVG } from '../../icons/contact_page.svg';
import { ReactComponent as FamilySVG } from '../../icons/group.svg';
import { ReactComponent as UserSVG } from '../../icons/person.svg';

const BreadCrumbs = () => {
  const history = useHistory();
  const location = history.location;
  const params = useParams();

  const dictionary = Object.entries(params).reduce((acc, [key, value]) => {
    acc[key] = value;
    acc[value] = key;

    return acc;
  }, {});

  const mapParam = useCallback((key) => {
    switch (key) {
      case 'familyId':
        return { Icon: FamilySVG, label: 'Family' };
      case 'userId':
        return { Icon: UserSVG, label: 'User' };
      case 'directory':
        return { Icon: DirectorySVG, label: 'Directory' };
      default:
        // for testing
        return { label: key };
    }
  }, []);

  const paths = location.pathname.split('/').slice(1);

  return (
    <div className={styles.breadcrumbs}>
      {paths.map((path, index, arr) => (
        <Crumb
          key={path}
          to={`/${arr.slice(0, index + 1).join('/')}`}
          withSlash={index < arr.length}
          isLast={index === arr.length - 1}
          {...mapParam(dictionary[path] || path)}
        >
          {/* {mapParam(dictionary[path] || path)} */}
        </Crumb>
      ))}
    </div>
  );
};

export default BreadCrumbs;
