/* istanbul ignore file */

import { ReactComponent as HiddenSVG } from '../../icons/visibility_off.svg';
import styles from './styles.module.scss';

const HiddenIcon = ({ className }) => (
  <HiddenSVG
    className={`${styles.hiddenIcon}${className ? ` ${className}` : ''}`}
  />
);

export default HiddenIcon;
