/* istanbul ignore file */

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { fetchFn } from '../utils';
import mapJSend from '../utils/mapJSend';

const useLogout = () => {
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    fetchFn('/api/v1/logout')
      .then(mapJSend)
      .then(() => {
        queryClient.clear();
        window.location.reload();
      });
  }, [queryClient]);

  return logout;
};

export default useLogout;
