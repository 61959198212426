import { useCallback, createContext, useState, useMemo } from 'react';
import { useDebouncedSearch } from '../../hooks';

const SearchContext = createContext();

const SearchProvider = ({
  initialSelected = {},
  advancedSearchType,
  children,
}) => {
  const [search, setSearch, debounce, setDebounce] = useDebouncedSearch();
  const [selected, setSelected] = useState(initialSelected);

  const selectedCount = useMemo(() => {
    return Object.entries(selected).reduce((acc, [key, value]) => {
      if (initialSelected[key] !== value) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [selected, initialSelected]);

  const onClear = useCallback(() => {
    setSearch('');
    setSelected(initialSelected);
    setDebounce('');
  }, [setSearch, setDebounce, initialSelected]);

  const onClearSelected = useCallback(() => {
    setSelected({});
  }, []);

  const value = {
    search,
    setSearch,
    selected,
    setSelected,
    onClear,
    onClearSelected,
    debounce,
    setDebounce,
    selectedCount,
    advancedSearchType,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
