import { login } from '../hooks/queryKeys';

const getTokenCache = (queryClient) => {
  try {
    // get latest token from cache
    const tokenCache = queryClient.getQueryState(login());
    return tokenCache?.data || {};
  } catch (e) {
    return {};
  }
};

export default getTokenCache;
