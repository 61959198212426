import { useCallback, useMemo, useState } from 'react';
import { ROLE, locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useChangeRole } from '../../../hooks/mutate';

const ChangeRole = ({ closeModal, modalType, id }) => {
  const { data: found } = useDirectory((d) => d.users.find((u) => u.id === id));
  const roles = [ROLE.ADMIN, ROLE.USER].map((str) => ({
    key: str,
    value: str,
    children: str,
  }));
  const [role, setRole] = useState(roles.find((r) => r.value === found.role));

  const { mutate } = useChangeRole(id, { _onMutate: closeModal });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate(role.value);
      }
    },
    [modalType, mutate, role]
  );

  const footerBtns = useMemo(() => {
    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        type: 'submit',
      },
    ];
  }, [onSave]);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.CHANGE_ROLE._} />
      <Body>
        <Form.Dropdown
          label={locale.COMMON.ROLE}
          selected={role}
          options={roles}
          onSelect={setRole}
          required
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default ChangeRole;
