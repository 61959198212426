import { useMemo } from 'react';
import { PHONE_LABEL } from '@churchms/shared';
import Avatar from '../Avatar';
import styles from './styles.module.scss';

const User = ({ id, name, phoneNumbers, email, birthday }) => {
  const phoneNumber = useMemo(() => {
    // prefer cell
    const cell = phoneNumbers.find((p) => p.label === PHONE_LABEL.CELL);

    return cell || phoneNumbers[0];
  }, [phoneNumbers]);

  const formattedBirthday = useMemo(() => {
    if (!birthday) return '';

    const d = new Date(birthday);
    return d.toLocaleString('default', { month: 'long', day: '2-digit' });
  }, [birthday]);

  return (
    <div className={styles.user}>
      <Avatar id={id} name={name} />
      <div>
        <div className={styles.fullName}>
          {name.first} {name.last}
        </div>
        {phoneNumber && <div>{phoneNumber.value}</div>}
        {email && <div>{email}</div>}
        {formattedBirthday && <div>Birthday: {formattedBirthday}</div>}
      </div>
    </div>
  );
};

export default User;
