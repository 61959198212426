import { PHONE_LABEL } from '@churchms/shared';
import formatSMSLink from './formatSMSLink';

const formatSMSLinkGroup = (users) => {
  const cellPhones = users.reduce((acc, { phoneNumbers }) => {
    const found =
      phoneNumbers &&
      phoneNumbers.find(({ label }) => label === PHONE_LABEL.CELL);

    if (found) {
      acc = [...acc, `+${found.value.replace(/-/g, '')}`];
    }

    return acc;
  }, []);

  if (cellPhones.length === 1) {
    return formatSMSLink(cellPhones[0]);
  }

  return `sms:/open?addresses=${encodeURIComponent(cellPhones.join(','))}`;
};

export default formatSMSLinkGroup;
