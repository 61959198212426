import { locale } from '@churchms/shared';
import { ReactComponent as ChangeRoleSVG } from '../../../icons/theater_comedy.svg';
import styles from './styles.module.scss';

const ChangeRole = ({ onClick, onCloseContextMenu }) => (
  <li className={styles.contextItem}>
    <button
      onClick={() => {
        onClick();
        onCloseContextMenu();
      }}
    >
      {locale.MODALS.CHANGE_ROLE._}
      <ChangeRoleSVG />
    </button>
  </li>
);

export default ChangeRole;
