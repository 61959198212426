import { useCallback, useReducer } from 'react';
import { locale } from '@churchms/shared';
import Header from '../../Header';
import Body from '../../Body';
import Footer from '../../Footer';
import {
  Form,
  CreateUserForm,
  CreateAddressForm,
} from '../../../../components';
import { useCreateAddressForm, useCreateUserForm } from '../../../../hooks';
import {
  initialState,
  reducer,
  setName,
  setWebsite,
  setPhone,
  setEmail,
} from './reducer';

const Church = ({
  title,
  modalType,
  mutate,
  isCreating = false,
  church = {},
}) => {
  const { address: _address = {}, ..._rest } = church;
  const createUserProps = useCreateUserForm({ isAdult: true });
  const [state, dispatch] = useReducer(reducer, { ...initialState, ..._rest });
  const createAddressProps = useCreateAddressForm(_address);

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({
          ...state,
          address: createAddressProps.state,
          ...(isCreating ? { admin: createUserProps.state } : {}),
        });
      }
    },
    [
      modalType,
      state,
      mutate,
      createUserProps.state,
      createAddressProps.state,
      isCreating,
    ]
  );

  const onName = (e) => dispatch(setName(e.target.value));
  const onWebsite = (e) => dispatch(setWebsite(e.target.value));
  const onPhone = (e) => dispatch(setPhone(e.target.value));
  const onEmail = (e) => dispatch(setEmail(e.target.value));

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: isCreating ? locale.COMMON.CREATE : locale.COMMON.SAVE,
      type: 'submit',
    },
  ];

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={title} />
      <Body>
        <Form.Fieldset legend={locale.COMMON.CHURCH}>
          <Form.TextField
            label={locale.COMMON.CHURCH_NAME}
            value={state.name}
            onChange={onName}
            required
          />
          <Form.TextField
            label={locale.COMMON.WEBSITE}
            value={state.website}
            onChange={onWebsite}
            type="url"
          />
          <Form.PhoneNumber
            label={locale.COMMON.PHONE}
            value={state.phone}
            onChange={onPhone}
            type="tel"
          />
          <Form.TextField
            label={locale.COMMON.EMAIL}
            value={state.email}
            onChange={onEmail}
            type="email"
          />
        </Form.Fieldset>
        <CreateAddressForm {...createAddressProps} />
        {isCreating && (
          <CreateUserForm
            legend={locale.COMMON.ADMIN_USER}
            {...createUserProps}
            isNewChurch
          />
        )}
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default Church;
