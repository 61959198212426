/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { profilePicture } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useAddProfilePicture = (id, type = 'user', { _onMutate = () => {} }) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const profilePictureKey = profilePicture(id);

  const addProfilePicture = useCallback(
    ({ file }) => {
      const formData = new FormData();
      formData.append('file', file);

      return fetchWithJWT(
        `/api/v1/files/profile-picture${objectToQueryParams({ type, id })}`,
        {
          method: 'POST',
          body: formData,
          isMultiPart: true,
        },
        locale.HTTP.ERROR.ADD_PROFILE_PICTURE
      );
    },
    [fetchWithJWT, id, type]
  );

  return useMutation({
    mutationFn: addProfilePicture,
    onMutate: async ({ blob }) => {
      _onMutate();

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(profilePictureKey);

      // Snapshot the previous value
      const previousProfilePicture =
        queryClient.getQueryData(profilePictureKey);

      // Optimistically update to the new value
      queryClient.setQueryData(profilePictureKey, blob);

      // Return a context object with the snapshotted value
      return { previousProfilePicture };
    },
    onError: (err, variables, { previousProfilePicture }) => {
      queryClient.setQueryData(profilePictureKey, previousProfilePicture);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: profilePictureKey });
    },
  });
};

export default useAddProfilePicture;
