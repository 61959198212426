/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { churches } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 18 * HOUR,
};

export const useFetchChurches = () => {
  const fetchWithJWT = useFetchWithJWT();
  const fetchChurches = useCallback(
    () => fetchWithJWT('/api/v1/churches', {}, locale.HTTP.ERROR.CHURCHES),
    [fetchWithJWT]
  );

  return fetchChurches;
};

const useChurches = (select) => {
  const fetchChurches = useFetchChurches();
  const isValid = useTokenIsValid();

  return useQuery({
    queryKey: churches(),
    queryFn: fetchChurches,
    select,
    enabled: isValid(),
    ...config,
  });
};

export default useChurches;
