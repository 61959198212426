import { useEffect } from 'react';
import { useURLSearchParams } from '../hooks';
import { useModalContext } from '../hooks/context';
import MainNav from './MainNav';
import styles from './layout.module.scss';
import { modalTypes } from '../contexts/ModalContext';

export const changeEmailQueryParam = 'confirmEmailChangeRequest';

const Layout = ({ user, children }) => {
  const { openModal, isOpen } = useModalContext();
  const isLoggedIn = !!user?.id;

  const params = useURLSearchParams();

  useEffect(() => {
    const shouldChangeEmail = params.get(changeEmailQueryParam);
    const code = params.get('code');
    const id = params.get('id');
    if (isLoggedIn && code && !isOpen && shouldChangeEmail) {
      openModal(modalTypes.CONFIRM_NEW_EMAIL, { code, id });
    }
  }, [isLoggedIn, params, isOpen, openModal]);

  if (isLoggedIn) {
    return (
      <div
        className={`${styles.layout} ${styles.loggedIn}${
          isOpen ? ` ${styles.blurred}` : ''
        }`}
      >
        <MainNav user={user} />
        <main className={styles.body}>{children}</main>
      </div>
    );
  }

  return children;
};

export default Layout;
