import { useState } from 'react';
import { locale } from '@churchms/shared';
import Address, { addressOptions } from './Address';
import { useCreateAddressForm } from '../../../hooks';
import { useEditAddress } from '../../../hooks/mutate';

const EditAddress = ({
  closeModal,
  userId,
  addressId,
  modalType,
  ...address
}) => {
  const addressProps = useCreateAddressForm(address);
  const [label, setLabel] = useState(
    addressOptions.find(({ value }) => value === address.label)
  );
  const { mutate } = useEditAddress(userId, addressId, {
    _onMutate: closeModal,
  });

  return (
    <Address
      formId={modalType}
      mutate={mutate}
      title={locale.MODALS.EDIT_ADDRESS._}
      closeModal={closeModal}
      userId={userId}
      label={label}
      setLabel={setLabel}
      {...addressProps}
    />
  );
};

export default EditAddress;
