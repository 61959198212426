import Page from '../Page';
import styles from './styles.module.scss';
import EditControls from '../EditControls';
import { useEditContext } from '../../hooks/context';

const DetailsPage = ({
  title,
  search,
  searchData,
  createLinkTo,
  isLoading,
  error,
  details,
  DetailsItem,
  children,
  editControls,
}) => {
  const { isEditing } = useEditContext();
  return (
    <Page.Wrapper title={title} search={search}>
      <Page.Body
        className={styles.body}
        createLinkTo={createLinkTo}
        isLoading={isLoading}
        error={error}
      >
        <DetailsItem {...details} />
        <div
          className={`${styles.list}${
            isEditing ? ` ${styles.listEditing}` : ''
          }`}
        >
          {children}
        </div>
        <EditControls>{editControls}</EditControls>
      </Page.Body>
    </Page.Wrapper>
  );
};

export default DetailsPage;
