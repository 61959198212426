/* istanbul ignore file */

// Date picker uses Date String format (ie. 1998-09-23)
// This will return a Date object or ISO string

const formatDatePickerDate = (d, toISO = false) => {
  if (!d) return d;
  const date = new Date(d);
  date.setDate(date.getDate() + 1); // fix javascript 0 indexed months
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  if (toISO) {
    return date.toISOString();
  }
  return date;
};

module.exports = formatDatePickerDate;
