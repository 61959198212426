/* istanbul ignore file */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { fetchFn, formatLoginResponse } from '../../utils';
import { login } from '../queryKeys';

const useSubmitLogin = ({ _onSuccess = () => {} } = {}) => {
  const queryClient = useQueryClient();

  const auth = ({ email, password }) =>
    fetchFn(
      '/api/v1/login',
      {
        method: 'POST',
        body: {
          email,
          password,
        },
      },
      locale.HTTP.ERROR.LOGIN
    ).then(formatLoginResponse);

  return useMutation({
    mutationFn: auth,
    onSuccess: (data) => {
      _onSuccess();
      queryClient.setQueryData(login(), data);
    },
  });
};

export default useSubmitLogin;
