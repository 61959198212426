import { useCallback, useState } from 'react';
import { locale } from '@churchms/shared';
import Header from '../../Header';
import Body from '../../Body';
import Footer from '../../Footer';
import { useChurch } from '../../../../hooks/query';
import { useEditChurch } from '../../../../hooks/mutate';
import { LoadingOrError } from '../../../../components';
import URLForm from './URLForm';
import { ReactComponent as RemoveSVG } from '../../../../icons/remove_circle.svg';
import List from './List';
import ListItem from './ListItem';

const { CHANGE_BLOG_FEEDS } = locale.MODALS;

const Component = ({ data, isLoading, error, modalType, closeModal }) => {
  const [value, setValue] = useState('');
  const [websites, setWebsites] = useState([]);
  const [feedUrls, setFeedUrls] = useState(data?.feedUrls || []);

  const onSearch = useCallback(
    (e) => {
      e.preventDefault();

      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        setWebsites((prev) => {
          const trimmed = value.trim();
          if (!prev.includes(trimmed)) {
            return [...prev, trimmed];
          }
          return prev;
        });
        setValue('');
      }
    },
    [value, modalType]
  );

  const onToggleFeedUrl = (item) => {
    setFeedUrls((prev) => {
      if (prev.includes(item)) {
        return prev.filter((url) => url !== item);
      }
      return [...prev, item];
    });
  };

  const { mutate } = useEditChurch({ _onMutate: closeModal });

  const onSubmit = useCallback(() => {
    mutate({
      feedUrls,
    });
  }, [mutate, feedUrls]);

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSubmit,
      primary: true,
      children: locale.COMMON.SUBMIT,
    },
  ];

  return (
    <>
      <Header title={CHANGE_BLOG_FEEDS._} />
      <Body>
        <LoadingOrError isLoading={isLoading} error={error}>
          {/* MODE */}
          <URLForm
            value={value}
            setValue={setValue}
            onSearch={onSearch}
            websites={websites}
            formId={modalType}
            onToggleFeedUrl={onToggleFeedUrl}
            feedUrls={feedUrls}
          />
          <List
            items={feedUrls}
            countText={locale.MODALS.CHANGE_BLOG_FEEDS.SELECTED_COUNT}
            renderFn={(url) => (
              <ListItem
                key={url}
                url={url}
                onToggleFeedUrl={onToggleFeedUrl}
                Icon={RemoveSVG}
              />
            )}
          />
        </LoadingOrError>
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

const ChangeFeeds = (props) => {
  const query = useChurch();

  return <Component key={query.dataUpdatedAt} {...query} {...props} />;
};

export default ChangeFeeds;
