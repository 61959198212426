const EnvironmentBanner = () => {
  const className = 'environment-banner';
  if (process.env.NODE_ENV === 'development') {
    return <div className={className}>DEV</div>;
  }

  if (
    process.env.NODE_ENV === 'production' &&
    window.location.href.startsWith('https://stage')
  ) {
    return <div className={`${className} ${className}--stage`}>STAGE</div>;
  }

  return null;
};

export default EnvironmentBanner;
