import { useState, useEffect } from 'react';

const useDebounce = (value, delay = 250) => {
  const [debounce, setDebounce] = useState('');

  useEffect(() => {
    let isUnmounted = false;
    let timeout;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (!isUnmounted) {
        setDebounce(value);
      }
    }, delay);

    return () => {
      isUnmounted = true;
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return [debounce, setDebounce];
};

export default useDebounce;
