import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { churches } from '../queryKeys';
import { useFetchChurches, config } from '../query/useChurches';
import useTokenIsValid from '../useTokenIsValid';

const usePrefetchChurches = () => {
  const queryClient = useQueryClient();

  const fetchChurches = useFetchChurches();
  const isValid = useTokenIsValid();

  return useCallback(async () => {
    if (isValid()) {
      await queryClient.prefetchQuery({
        queryKey: churches(),
        queryFn: fetchChurches,
        ...config,
      });
    }
  }, [fetchChurches, queryClient, isValid]);
};

export default usePrefetchChurches;
