import { locale } from '@churchms/shared';
import { ReactComponent as DeleteSVG } from '../../../icons/delete.svg';
import styles from './styles.module.scss';

const DeleteUser = ({ onClick, onCloseContextMenu }) => {
  return (
    <li className={styles.contextItem}>
      <button
        onClick={() => {
          onClick();
          onCloseContextMenu();
        }}
      >
        {locale.MODALS.DELETE_USER._}
        <DeleteSVG />
      </button>
    </li>
  );
};

export default DeleteUser;
