import { useMemo } from 'react';
import { locale, localeFormat } from '@churchms/shared';
import Avatar from '../../Avatar';
import ContextMenu, { ContextMenuItem } from '../../ContextMenu';
import HiddenIcon from '../../HiddenIcon';
import styles from './styles.module.scss';
import { usePerms } from '../../../hooks';
import { useModalContext } from '../../../hooks/context';
import { modalTypes } from '../../../contexts/ModalContext';
import BreadCrumbs from '../../BreadCrumbs';
import BlurredBackground from './BlurredBackground';

const Family = ({ surname, members, id, isOptimistic }) => {
  const isHidden = members.every(({ isHidden }) => isHidden);
  const { openModal } = useModalContext();
  const { isAdmin, isOwnFamily, isHeadOfHousehold } = usePerms({
    targetFamilyId: id,
  });
  const contextMenuItems = useMemo(() => {
    const items = [];

    if (isAdmin) {
      items.push({
        key: 'change-name',
        Component: ContextMenuItem.ChangeName,
        onClick: () => openModal(modalTypes.CHANGE_FAMILY_NAME, { id }),
      });
      if (members.length > 1) {
        items.push({
          key: 'change-head-of-household',
          Component: ContextMenuItem.ChangeHeadOfHousehold,
          onClick: () => openModal(modalTypes.CHANGE_HEAD_OF_HOUSEHOLD, { id }),
        });
      }
    }

    if (isAdmin || isHeadOfHousehold) {
      items.push({
        key: 'add-profile-picture',
        Component: ContextMenuItem.AddProfilePicture,
        onClick: () =>
          openModal(modalTypes.ADD_PROFILE_PICTURE, { familyId: id }),
      });
      items.push({
        key: 'delete-profile-picture',
        Component: ContextMenuItem.DeleteProfilePicture,
        id,
        onClick: () =>
          openModal(modalTypes.DELETE_PROFILE_PICTURE, { familyId: id }),
      });
    }

    if (isAdmin && !isOwnFamily) {
      items.push({
        key: 'delete-user',
        Component: ContextMenuItem.DeleteFamily,
        onClick: () => openModal(modalTypes.DELETE_FAMILY, { familyId: id }),
      });
    }

    return items;
  }, [id, isAdmin, isHeadOfHousehold, isOwnFamily, members.length, openModal]);

  return (
    <>
      <div className={styles.topRow}>
        <div>
          <BreadCrumbs />
          <ContextMenu items={contextMenuItems} />
        </div>
      </div>
      <div className={styles.detailsHeader}>
        <Avatar name={surname} size="x-large" familyId={!isOptimistic && id} />
        <h2>{localeFormat(locale.COMMON.FAMILY_LABEL_WITH_NAME, surname)}</h2>
        {isHidden && <HiddenIcon />}
        <BlurredBackground id={id} />
      </div>
    </>
  );
};

export default Family;
