import { useState } from 'react';
import { locale } from '@churchms/shared';
import { useAddPhoneNumber } from '../../../hooks/mutate';
import PhoneNumber, { phoneOptions } from './PhoneNumber';

const AddPhoneNumber = ({ closeModal, userId, modalType }) => {
  const [value, setValue] = useState('');
  const [label, setLabel] = useState(phoneOptions[0]);
  const { mutate } = useAddPhoneNumber(userId, { _onMutate: closeModal });

  return (
    <PhoneNumber
      formId={modalType}
      mutate={mutate}
      title={locale.MODALS.ADD_PHONE._}
      value={value}
      setValue={setValue}
      label={label}
      setLabel={setLabel}
      closeModal={closeModal}
      userId={userId}
    />
  );
};

export default AddPhoneNumber;
