import { locale } from '@churchms/shared';
import styles from './styles.module.scss';
import { useProfilePicture } from '../../../hooks/query';

const DeleteProfilePicture = ({ onClick, onCloseContextMenu, id }) => {
  const { data } = useProfilePicture(id);
  return (
    <li className={styles.contextItem}>
      <button
        onClick={() => {
          onClick();
          onCloseContextMenu();
        }}
        disabled={!data}
      >
        {locale.MODALS.DELETE_PROFILE_PICTURE._}
      </button>
    </li>
  );
};

export default DeleteProfilePicture;
