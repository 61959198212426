import { locale } from '@churchms/shared';
import styles from './styles.module.scss';
import { ReactComponent as ChangeNameSVG } from '../../../icons/create.svg';

const ChangeName = ({ onClick, onCloseContextMenu }) => (
  <li className={styles.contextItem}>
    <button
      onClick={() => {
        onClick();
        onCloseContextMenu();
      }}
    >
      {locale.MODALS.CHANGE_NAME._}
      <ChangeNameSVG />
    </button>
  </li>
);

export default ChangeName;
