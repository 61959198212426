import styles from './styles.module.scss';

const ErrorMessage = ({ error }) => {
  const _error = error?.message || error;

  if (!_error) return null;

  return <p className={styles.error}>{_error}</p>;
};

export default ErrorMessage;
