import { locale } from '@churchms/shared';
import { useDeleteAddress } from '../../../hooks/mutate';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import styles from './deleteAddress.module.scss';

const DeleteAddress = ({
  closeModal,
  userId,
  addressId,
  label,
  street1,
  street2,
  city,
  state,
  zipCode,
  country,
}) => {
  const { mutate } = useDeleteAddress(userId, {
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate(addressId),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.DELETE_ADDRESS._} />
      <Body>
        <p>{locale.MODALS.DELETE_ADDRESS.CONFIRM}</p>
        <dl className={styles.address}>
          <dt>{label}</dt>
          <dd>
            {street1}
            <br />
            {street2}
            {street2 && <br />}
            {city}, {state} {zipCode}
            <br />
            {country}
          </dd>
        </dl>
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeleteAddress;
