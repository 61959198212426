/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { directory, login } from '../queryKeys';
import { formatLoginResponse } from '../../utils';
import useFetchWithJWT from '../useFetchWithJWT';

const useConfirmChangeEmail = () => {
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const confirmChangeEmail = useCallback(
    (payload) => {
      return fetchWithJWT(
        '/api/v1/user/confirm-change-email',
        {
          raw: true,
          method: 'POST',
          body: payload,
        },
        locale.HTTP.ERROR.CONFIRM_EMAIL_CHANGE
      ).then(formatLoginResponse);
    },
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: confirmChangeEmail,
    onSuccess: (data) => {
      queryClient.setQueryData(login(), data);
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useConfirmChangeEmail;
