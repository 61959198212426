const formatMailtoLink = (users = []) => {
  if (users.length === 1) {
    return `mailto:${encodeURIComponent(users[0].email)}`;
  }

  return `mailto:?bcc=${encodeURIComponent(
    users.map(({ email }) => email).join(',')
  )}`;
};

export default formatMailtoLink;
