/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { blogMetadata } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 12 * HOUR,
};

export const useFetchBlogMetadata = () => {
  const fetchWithJWT = useFetchWithJWT();

  const fetchBlog = useCallback(() => {
    return fetchWithJWT(
      '/api/v1/rss/blog-metadata',
      {},
      locale.HTTP.ERROR.GET_BLOG_METADATA
    );
  }, [fetchWithJWT]);

  return fetchBlog;
};

const useBlogMetadata = (select) => {
  const fetchBlog = useFetchBlogMetadata();
  const isValid = useTokenIsValid();

  return useQuery({
    queryKey: blogMetadata(),
    queryFn: fetchBlog,
    enabled: isValid(),
    select,
    ...config,
  });
};

export default useBlogMetadata;
