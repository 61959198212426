/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { labels } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import useLogin from './useLogin';
import { useTokenIsValid } from '..';

const availableLabels = ['bug', 'enhancement', 'question'];

const useLabels = (select) => {
  const { data } = useLogin();

  const fetchWithJWT = useFetchWithJWT();
  const isValid = useTokenIsValid();

  const fetchLabels = useCallback(() => {
    return fetchWithJWT(
      '/api/v1/labels',
      {},
      locale.HTTP.ERROR.GET_LABELS
    ).then((data) => {
      return data.filter(({ name }) => availableLabels.includes(name));
    });
  }, [fetchWithJWT]);

  return useQuery({
    queryKey: labels(),
    queryFn: fetchLabels,
    staleTime: 8 * HOUR,
    enabled: !!data.id && isValid(),
    placeholderData: [],
    select,
  });
};

export default useLabels;
