import { useCallback } from 'react';
import { ReactComponent as ClearSVG } from '../../icons/clear.svg';
import { ReactComponent as AdvancedSearchSVG } from '../../icons/more.svg';
import styles from './styles.module.scss';
import IconButton from '../IconButton';
import { useModalContext, useSearchContext } from '../../hooks/context';

const Search = ({ searchData = [] }) => {
  const {
    search,
    setSearch,
    selected,
    setSelected,
    selectedCount,
    onClear,
    advancedSearchType,
  } = useSearchContext();
  const onChange = useCallback((e) => setSearch(e.target.value), [setSearch]);
  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        onClear('');
      }
    },
    [onClear]
  );

  const { openModal } = useModalContext();

  const onOpenSearch = () => {
    openModal(advancedSearchType, {
      search,
      setSearch,
      selected,
      setSelected,
    });
  };

  return (
    <div className={styles.search}>
      <input
        value={search}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Search"
        list="search-data-list"
      />
      <datalist id="search-data-list">
        {searchData.map((val) => (
          <option key={val}>{val}</option>
        ))}
      </datalist>
      {search.trim() && (
        <IconButton onClick={onClear}>
          <ClearSVG />
        </IconButton>
      )}
      {advancedSearchType && (
        <IconButton
          onClick={onOpenSearch}
          badge={selectedCount}
          badgeColor="blue"
        >
          <AdvancedSearchSVG />
        </IconButton>
      )}
    </div>
  );
};

export default Search;
