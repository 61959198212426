import { locale } from '@churchms/shared';
import styles from './styles.module.scss';

const ChangeHeadOfHousehold = ({ onClick, onCloseContextMenu }) => (
  <li className={styles.contextItem}>
    <button
      onClick={() => {
        onClick();
        onCloseContextMenu();
      }}
    >
      {locale.MODALS.CHANGE_HEAD_OF_HOUSEHOLD.SHORT}
    </button>
  </li>
);

export default ChangeHeadOfHousehold;
