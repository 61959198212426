import { useCallback, useEffect, useRef } from 'react';
import { locale } from '@churchms/shared';
import { useHistory } from 'react-router-dom';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { LoadingOrError } from '../../../components';
import { useURLSearchParams } from '../../../hooks';
import { useConfirmChangeEmail } from '../../../hooks/mutate';
import { changeEmailQueryParam } from '../../Layout';

const ConfirmChangeEmail = ({ code, id }) => {
  const { mutate, isPending, error, isSuccess } = useConfirmChangeEmail();
  const history = useHistory();
  const params = useURLSearchParams();

  const onClose = useCallback(() => {
    params.delete(changeEmailQueryParam);
    params.delete('code');
    params.delete('id');
    history.push({ search: params.toString() });
  }, [history, params]);

  const footerBtns = [
    {
      children: locale.COMMON.OK,
      primary: true,
      disabled: isPending,
      onClick: onClose,
    },
  ];

  const stateHasSettled = useRef(false);
  const hasTried = useRef(false);

  useEffect(() => {
    if (!hasTried.current && stateHasSettled.current) {
      mutate({ code, id });
      hasTried.current = true;
    } else {
      stateHasSettled.current = true;
    }
  }, [mutate, code, id]);

  return (
    <>
      <Header title={locale.MODALS.CONFIRM_CHANGE_EMAIL._} />
      <Body>
        <LoadingOrError isLoading={isPending} error={error}>
          {isSuccess && locale.MODALS.CONFIRM_CHANGE_EMAIL.SUCCESS}
        </LoadingOrError>
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default ConfirmChangeEmail;
