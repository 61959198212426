/* istanbul ignore file */

import { useEffect, useState, useRef } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import styles from './layout.module.scss';

const max = 100000;

const ProgressBar = () => {
  const numberOfMutations = useIsMutating();
  const totalBusy = numberOfMutations;
  const isMutating = !!totalBusy;
  const previousNumberOfMutations = useRef(0);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(0);

  // Auto increment the value
  useEffect(() => {
    let isUnmounted = false;
    let interval;

    clearInterval(interval);

    interval = setInterval(() => {
      if (!isUnmounted && isMutating) {
        setValue((prev) => {
          const remainder = max - prev;
          const next = prev + remainder * 0.1;
          if (next < max) {
            return next;
          }
          return max;
        });
      }
    }, 300);

    return () => {
      isUnmounted = true;
      clearInterval(interval);
    };
  }, [isMutating]);

  // hide when done after short delay
  useEffect(() => {
    let isUnmounted = false;
    let timeout;

    clearTimeout(timeout);

    if (!isMutating && value) {
      setValue(max);
      timeout = setTimeout(() => {
        if (!isUnmounted) {
          if (!isMutating && value === max) {
            setValue(0);
            setVisible(false);
          }
        }
      }, 800);
    }

    return () => {
      isUnmounted = true;
      clearTimeout(timeout);
    };
  }, [isMutating, value]);

  // restart progress if more mutations are added
  useEffect(() => {
    if (!!totalBusy) {
      if (totalBusy >= previousNumberOfMutations.current) {
        setValue(0);
        setVisible(true);
      }
      previousNumberOfMutations.current = totalBusy;
    }
  }, [totalBusy]);

  const perc = (value / max) * 100 - 100;

  return (
    visible && (
      <div className={styles.progress}>
        <div style={{ transform: `translateX(${perc}%)` }} />
      </div>
    )
  );
};

export default ProgressBar;
