import { useCallback, useMemo } from 'react';
import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form, NewPasswordForm } from '../../../components';
import { useNewPasswordForm } from '../../../hooks';
import { useLogin } from '../../../hooks/query';
import { useChangePassword } from '../../../hooks/mutate';

const ChangePassword = ({ closeModal, modalType }) => {
  const { data } = useLogin();
  const {
    oldPassword,
    onOldPassword,
    newPassword,
    onNewPassword,
    confirmPassword,
    onConfirmPassword,
    error,
  } = useNewPasswordForm();
  const { mutate } = useChangePassword({ _onMutate: closeModal });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res && !error) {
        mutate({ password: oldPassword, newPassword });
      }
    },
    [modalType, error, mutate, oldPassword, newPassword]
  );

  const footerBtns = useMemo(() => {
    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        disabled: error,
        type: 'submit',
      },
    ];
  }, [error, onSave]);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.CHANGE_PASSWORD._} />
      <Body>
        <NewPasswordForm
          email={data.email}
          showOldPassword
          oldPassword={oldPassword}
          onOldPassword={onOldPassword}
          newPassword={newPassword}
          onNewPassword={onNewPassword}
          confirmPassword={confirmPassword}
          onConfirmPassword={onConfirmPassword}
          error={error}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default ChangePassword;
