/* istanbul ignore file */

import { useState, useEffect } from 'react';
import { getCSSVariable } from '../utils';

const pxToBreakpoint = (px) => {
  const getVariable = (variable) => parseInt(getCSSVariable(variable), 10);

  // const phone = getVariable('--phone-breakpoint');
  const tablet = getVariable('--tablet-breakpoint');
  const desktop = getVariable('--desktop-breakpoint');

  if (px > desktop) return 'desktop';
  if (px > tablet) return 'tablet';
  return 'phone';
};

const useBreakpoint = (callback) => {
  const [breakpoint, setBreakpoint] = useState(
    pxToBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    let isUnmounted = false;
    let timeout;
    const resize = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        if (!isUnmounted) {
          setBreakpoint(pxToBreakpoint(window.innerWidth));
        }
      }, 100);
    };

    window.addEventListener('resize', resize);

    return () => {
      isUnmounted = true;
      window.removeEventListener('resize', resize);
    };
  }, []);

  if (callback && typeof callback === 'function') {
    return callback(breakpoint);
  }

  return breakpoint;
};

export default useBreakpoint;
