import { Link } from 'react-router-dom';
import { PHONE_LABEL, locale } from '@churchms/shared';
import HiddenIcon from '../../HiddenIcon';
import { useDirectory } from '../../../hooks/query';
import Content from './Content';
import styles from './styles.module.scss';
import Label from '../../Label';
import IconButton from '../../IconButton';
import { ReactComponent as MessageSVG } from '../../../icons/message.svg';
import { ReactComponent as PhoneSVG } from '../../../icons/phone.svg';
import { ReactComponent as EmailSVG } from '../../../icons/email.svg';
import { formatPhoneLink, formatSMSLink } from '../../../utils';
import QuickContactButtons from './QuickContactButtons';

const User = ({
  name,
  familyId,
  id,
  isHidden,
  isOptimistic = false,
  email,
  phoneNumbers,
}) => {
  const { data: family } = useDirectory((d) => {
    const found = d.families.find((f) => f.id === familyId);
    return found;
  });

  const isHeadOfTheirHousehold = family?.headOfHousehold === id;

  const cellPhone = phoneNumbers.find(
    ({ label }) => label === PHONE_LABEL.CELL
  );

  const phone = cellPhone || phoneNumbers[0];

  return (
    <div className={`${styles.userOrFamilyItem} ${styles.clickableFlex}`}>
      <Link
        to={`/directory/${familyId}/${id}`}
        className={isOptimistic ? styles.disabledLink : ''}
      >
        <Content name={name} id={!isOptimistic && id}>
          {isHeadOfTheirHousehold && family.members.length > 1 && (
            <Label>{locale.COMMON.HEAD_OF_HOUSEHOLD}</Label>
          )}
          <p>
            {name?.first} {name?.last}
          </p>
          {isHidden && <HiddenIcon />}
        </Content>
      </Link>
      <QuickContactButtons>
        {cellPhone && (
          <IconButton
            type="link"
            href={cellPhone ? formatSMSLink(cellPhone.value) : ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MessageSVG />
          </IconButton>
        )}
        {phone && (
          <IconButton
            type="link"
            href={phone ? formatPhoneLink(phone.value) : ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PhoneSVG />
          </IconButton>
        )}
        {email && (
          <IconButton
            type="link"
            href={`mailto:${encodeURIComponent(email)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <EmailSVG />
          </IconButton>
        )}
      </QuickContactButtons>
    </div>
  );
};

export default User;
