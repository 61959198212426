/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory, login } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';
import useLogin from '../query/useLogin';

const useChangeFamilyName = (familyId, { _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const { data: isOwnFamily } = useLogin((d) => d.familyId === familyId);
  const directoryKey = directory();
  const loginKey = login();

  const changeFamilyName = useCallback(
    (payload) =>
      fetchWithJWT(
        `/api/v1/family/change-name${objectToQueryParams({ familyId })}`,
        {
          method: 'PUT',
          body: payload,
        },
        locale.HTTP.ERROR.CHANGE_FAMILY_NAME
      ),
    [fetchWithJWT, familyId]
  );

  return useMutation({
    mutationFn: changeFamilyName,
    onMutate: async ({ surname, changeMembers }) => {
      _onMutate();

      const mapNewFamilyName = (prev) => {
        if (prev.id === familyId) {
          return {
            ...prev,
            surname,
            members: changeMembers
              ? prev.members.map((u) => ({
                  ...u,
                  name: {
                    ...u.name,
                    last: surname,
                  },
                }))
              : prev.members,
          };
        }
        return prev;
      };

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);
      const previousLogin = queryClient.getQueryData(loginKey);

      // Optimistically update to the new value
      if (isOwnFamily && changeMembers) {
        queryClient.setQueryData(loginKey, (prev) => ({
          ...prev,
          name: {
            ...prev.name,
            last: surname,
          },
        }));
      }

      queryClient.setQueryData(directoryKey, (prev) => ({
        ...prev,
        families: prev.families.map(mapNewFamilyName),
        users: prev.users.map((u) => {
          if (u.familyId === familyId) {
            return {
              ...u,
              name: {
                ...u.name,
                last: surname,
              },
            };
          }
          return u;
        }),
      }));

      // Return a context object with the snapshotted value
      return { previousDirectory, previousLogin };
    },
    onError: (err, variables, { previousDirectory, previousLogin }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      queryClient.setQueryData(loginKey, previousLogin);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: (data, error, { changeMembers }) => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useChangeFamilyName;
