import { useMemo } from 'react';
import { locale } from '@churchms/shared';
import { ReactComponent as AddSVG } from '../../../../icons/add_circle.svg';
import { useFeeds } from '../../../../hooks/query';
import List from './List';
import ListItem from './ListItem';

const FeedList = ({ websites, onToggleFeedUrl, feedUrls }) => {
  const queries = useFeeds(...websites);
  const isLoading = queries.some((q) => q.isLoading);
  const deduped = useMemo(() => {
    return queries.reduce((acc, { data }) => {
      if (data) {
        return [
          ...acc,
          ...data.filter((u) => {
            const alreadyActive = feedUrls.includes(u.url);
            const isDuplicate = acc.some((e) => e.url === u.url);
            return !alreadyActive && !isDuplicate;
          }),
        ];
      }

      return acc;
    }, []);
  }, [queries, feedUrls]);

  return (
    <List
      items={deduped}
      countText={locale.MODALS.CHANGE_BLOG_FEEDS.FEED_RESULTS}
      renderFn={(item) => (
        <ListItem
          key={item.url}
          {...item}
          Icon={AddSVG}
          onToggleFeedUrl={onToggleFeedUrl}
        />
      )}
      isLoading={isLoading}
    />
  );
};

export default FeedList;
