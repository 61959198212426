import { useCallback } from 'react';
import { ADDRESS_LABEL, locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { CreateAddressForm, Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';

export const addressOptions = Object.values(ADDRESS_LABEL).map((key) => ({
  key,
  value: key,
  children: key,
}));

const Address = ({
  formId,
  mutate,
  title,
  state,
  label,
  setLabel,
  userId,
  ...setters
}) => {
  const { data: isDuplicate } = useDirectory((d) => {
    const found = d.users.find(({ id }) => id === userId);
    return found.addresses.some(
      (a) =>
        a.street1 === state.street1 &&
        a.street2 === state.street2 &&
        a.city === state.city &&
        a.state === state.state &&
        a.country === state.country &&
        a.zipCode === state.zipCode &&
        a.label === label.value
    );
  });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[formId].reportValidity();

      if (res) {
        mutate({
          ...state,
          label: label.value,
        });
      }
    },
    [label, state, mutate, formId]
  );

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: locale.COMMON.SAVE,
      disabled: isDuplicate,
      type: 'submit',
    },
  ];

  return (
    <Form.Wrapper onSubmit={onSave} id={formId}>
      <Header title={title} />
      <Body>
        <CreateAddressForm
          required
          hideLegend
          state={state}
          {...setters}
          error={isDuplicate && locale.HTTP.ERROR.DUPLICATE_USER_ADDRESS}
        >
          <Form.Dropdown
            label={locale.COMMON.LABEL}
            options={addressOptions}
            onSelect={setLabel}
            selected={label}
            required
          />
        </CreateAddressForm>
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default Address;
