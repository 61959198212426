const getInitials = (input, numberOfInitials = 2) => {
  const split = input.split(' ');
  return split.reduce((acc, word = '', idx) => {
    if (idx < numberOfInitials) {
      acc += word.charAt(0);
    }
    return acc;
  }, '');
};

export default getInitials;
