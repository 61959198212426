const formatLoginResponse = (res) => {
  if (res.status === 'success') {
    return {
      ...res.data,
      token: res.token,
      expiresAfter: res.expiresAfter,
    };
  }
  throw new Error(res.message);
};

export default formatLoginResponse;
