import { emptyAddress } from '@churchms/shared';
import { useReducer } from 'react';

const actionTypes = {
  SET_ADDRESS: 'SET_ADDRESS',
};

// Destructure label off of state
const { label, ...rest } = emptyAddress;

const initialState = { ...rest, country: 'United States' };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};

const useCreateAddressForm = (init = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => ({
    ...initialState,
    ...init,
  }));

  const onStreet1 = (e) =>
    dispatch({
      type: actionTypes.SET_ADDRESS,
      key: 'street1',
      value: e.target.value,
    });

  const onStreet2 = (e) =>
    dispatch({
      type: actionTypes.SET_ADDRESS,
      key: 'street2',
      value: e.target.value,
    });

  const onCity = (e) =>
    dispatch({
      type: actionTypes.SET_ADDRESS,
      key: 'city',
      value: e.target.value,
    });

  const onState = (e) =>
    dispatch({
      type: actionTypes.SET_ADDRESS,
      key: 'state',
      value: e.target.value,
    });

  const onCountry = (e) =>
    dispatch({
      type: actionTypes.SET_ADDRESS,
      key: 'country',
      value: e.target.value,
    });

  const onZipCode = (e) =>
    dispatch({
      type: actionTypes.SET_ADDRESS,
      key: 'zipCode',
      value: e.target.value,
    });

  return {
    state,
    onStreet1,
    onStreet2,
    onCity,
    onState,
    onCountry,
    onZipCode,
  };
};

export default useCreateAddressForm;
