import { useState, useRef, useEffect, useCallback } from 'react';
import { ReactComponent as MoreSVG } from '../../icons/more_vert.svg';
import IconButton from '../IconButton';
import styles from './styles.module.scss';

const ContextMenu = ({ className, items = [] }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((prev) => !prev);
  const onCloseContextMenu = useCallback(() => setIsOpen(false), []);

  const clickAway = useCallback((e) => {
    if (ref.current && ref.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', clickAway, true);

    return () => document.removeEventListener('mousedown', clickAway, true);
  }, [clickAway]);

  return (
    !!items.length && (
      <div
        className={`${styles.wrapper}${className ? ` ${className}` : ''}`}
        ref={ref}
      >
        <IconButton className={styles.moreButton} onClick={toggleOpen}>
          <MoreSVG />
        </IconButton>
        {isOpen && (
          <ul className={styles.contextMenu}>
            {items.map(({ key, Component, ...rest }) => (
              <Component
                key={key}
                onCloseContextMenu={onCloseContextMenu}
                {...rest}
              />
            ))}
          </ul>
        )}
      </div>
    )
  );
};

export default ContextMenu;
