import { useEffect, useRef } from 'react';
import { ReactComponent as CheckSVG } from '../../icons/check.svg';
import styles from './styles.module.scss';

const Checkbox = ({
  label,
  hideLabel = false,
  indeterminate = false,
  className,
  ...props
}) => {
  const ref = useRef();

  useEffect(() => {
    if (indeterminate && ref.current) {
      ref.current.indeterminate = true;
    }
  }, [indeterminate]);

  const input = (
    <div
      className={`${styles.checkboxWrapper}${className ? ` ${className}` : ''}`}
    >
      <input className={styles.checkbox} ref={ref} type="checkbox" {...props} />
      {!indeterminate && props.checked && <CheckSVG />}
    </div>
  );

  if (!label) {
    return input;
  }

  return (
    <label className={styles.checkboxLabel}>
      <span className={hideLabel ? styles.hideLabel : ''}>{label}</span>
      {input}
    </label>
  );
};

export default Checkbox;
