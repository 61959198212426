import { locale, localeFormat } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useDirectory } from '../../../hooks/query';
import { useDeleteFamily } from '../../../hooks/mutate';
import FamilyList from './components/FamilyList';

const DeleteFamily = ({ closeModal, familyId }) => {
  const { data: family } = useDirectory((d) =>
    d.families.find(({ id }) => id === familyId)
  );
  const { surname } = family;

  const { mutate } = useDeleteFamily({
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate(familyId),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.DELETE_FAMILY._} />
      <Body>
        {localeFormat(locale.MODALS.DELETE_FAMILY.CONFIRM, surname)}
        <FamilyList {...family} />
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeleteFamily;
