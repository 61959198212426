import { LISTING_TYPE } from '@churchms/shared';
import { EditControls, ErrorBoundary, Page } from '../../components';
import EditContext from '../../contexts/EditContext';
import { modalTypes } from '../../contexts/ModalContext';
import { SearchProvider } from '../../contexts/SearchContext';
import { useDebounce } from '../../hooks';
import { useModalContext, useSearchContext } from '../../hooks/context';
import { useListings, useLogin } from '../../hooks/query';
import ListingPreview from './ListingPreview';
import styles from './styles.module.scss';

export const myListingKey = 'my-listings';

export const initialMarketSearchSelected = {
  ...Object.values(LISTING_TYPE).reduce(
    (acc, l) => ({
      ...acc,
      [l]: true,
    }),
    {}
  ),
  [myListingKey]: undefined,
};

const Component = () => {
  const { search, selected } = useSearchContext();
  const [debounce] = useDebounce(search);
  const { data: id } = useLogin((d) => d?.id);
  const { data, isPlaceholderData, error } = useListings((d) => {
    const lowercased = debounce.toLowerCase();
    return d
      ?.filter((i) => {
        const isMine =
          selected[myListingKey] === false ? i.author !== id : true;
        const typeMatches = selected[i.type];
        const searchMatches =
          i.title.toLowerCase().includes(lowercased) ||
          i.body.toLowerCase().includes(lowercased) ||
          i.keywords.includes(lowercased);

        return isMine && typeMatches && searchMatches;
      })
      .sort(({ updatedAt: a }, { updatedAt: b }) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        return aDate < bDate ? 1 : -1;
      });
  });
  const { openModal } = useModalContext();
  const onAdd = () => {
    openModal(modalTypes.CREATE_LISTING, {});
  };

  const editContext = { onAdd };

  return (
    <EditContext.Provider value={editContext}>
      <Page.Wrapper title="Market" canSearch>
        <Page.Body isLoading={isPlaceholderData} error={error}>
          <div className={styles.marketList}>
            {data.map((listing) => (
              <ListingPreview key={listing.id} {...listing} />
            ))}
          </div>
          <EditControls />
        </Page.Body>
      </Page.Wrapper>
    </EditContext.Provider>
  );
};

const Market = () => (
  <ErrorBoundary title="Market" FallbackComponent={Page.FallbackComponent}>
    <SearchProvider
      initialSelected={initialMarketSearchSelected}
      advancedSearchType={modalTypes.MARKET_SEARCH}
    >
      <Component />
    </SearchProvider>
  </ErrorBoundary>
);

export default Market;
