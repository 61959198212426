/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useChangeRole = (userId, { _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const changeRole = useCallback(
    (role) =>
      fetchWithJWT(
        `/api/v1/user/role${objectToQueryParams({ userId })}`,
        {
          method: 'PUT',
          body: { role },
        },
        locale.HTTP.ERROR.CHANGE_ROLE
      ),
    [fetchWithJWT, userId]
  );

  return useMutation({
    mutationFn: changeRole,
    onMutate: async (role) => {
      _onMutate();

      const mapNewRole = (prev) => {
        if (prev.id === userId) {
          return {
            ...prev,
            role,
          };
        }
        return prev;
      };

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => ({
        ...prev,
        families: prev.families.map((family) => {
          const found = family.members.some(({ id }) => id === userId);
          if (found) {
            return {
              ...family,
              members: family.members.map(mapNewRole),
            };
          }
          return family;
        }),
        users: prev.users.map(mapNewRole),
      }));

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, role, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useChangeRole;
