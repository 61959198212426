import { useCallback } from 'react';
import { locale } from '@churchms/shared';
import ButtonAsLink from '../../ButtonAsLink';
import { ReactComponent as EmailSVG } from '../../../icons/email.svg';
import { useModalContext } from '../../../hooks/context';
import { modalTypes } from '../../../contexts/ModalContext';
import Content from './Content';
import styles from './styles.module.scss';

const AddEmail = ({ userId }) => {
  const { openModal } = useModalContext();

  const onClick = useCallback(
    () => openModal(modalTypes.ADD_EMAIL, { userId }),
    [openModal, userId]
  );

  return (
    <ButtonAsLink className={styles.clickableFlex} onClick={onClick}>
      <Content Icon={EmailSVG} name="invite user">
        <p>{locale.USER_DETAILS.ADD_EMAIL}</p>
      </Content>
    </ButtonAsLink>
  );
};

export default AddEmail;
