/* istanbul ignore file */

import { useCallback } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { blog } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 12 * HOUR,
};

const pageSize = 10;

export const useFetchBlog = () => {
  const fetchWithJWT = useFetchWithJWT();

  const fetchBlog = useCallback(
    ({ pageParam }) => {
      return fetchWithJWT(
        `/api/v1/rss/posts?page=${pageParam}&limit=${pageSize}`,
        locale.HTTP.ERROR.GET_RSS
      );
    },
    [fetchWithJWT]
  );

  return fetchBlog;
};

const useBlog = () => {
  const fetchBlog = useFetchBlog();
  const isValid = useTokenIsValid();

  return useInfiniteQuery({
    queryKey: blog(),
    queryFn: fetchBlog,
    enabled: isValid(),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      const totalEntries = lastPage.totalEntries;
      const totalFetched = pages.reduce(
        (acc, { entries }) => acc + entries.length,
        0
      );
      return totalEntries > totalFetched ? pages.length + 1 : null;
    },
    ...config,
  });
};

export default useBlog;
