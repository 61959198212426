import { locale } from '@churchms/shared';
import Header from './Header';
import styles from './styles.module.scss';

const FallbackComponent = ({ title = '', className }) => (
  <>
    <Header title={title} visible />
    <div
      className={`${styles.page} ${styles.fallbackBody}${
        className ? ` page--${className}` : ''
      }`}
    >
      {locale.ERROR_BOUNDARY.PAGE._}
    </div>
  </>
);

export default FallbackComponent;
