import { useProfilePicture } from '../../../hooks/query';
import styles from './styles.module.scss';

const BlurredBackground = ({ id }) => {
  const { data: img } = useProfilePicture(id);

  return (
    <div
      className={styles.blurBg}
      style={{ background: img && `url(${img}) center/cover` }}
    />
  );
};

export default BlurredBackground;
