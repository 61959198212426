/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { jwtExpiresInSeconds, locale } from '@churchms/shared';
import { fetchFn, formatLoginResponse } from '../../utils';
import { login } from '../queryKeys';
import useTokenIsValid from '../useTokenIsValid';
import useFetchWithJWT from '../useFetchWithJWT';

const queryKey = login();
const staleTime = Math.round(jwtExpiresInSeconds * 0.5) * 1000;
const refetchInterval = (query) => {
  // not logged in
  if (!query.state.data?.id) {
    return false; // don't refetch
  }

  return Math.round(jwtExpiresInSeconds * 0.75) * 1000;
};

const useLogin = (select) => {
  const isValid = useTokenIsValid();
  const fetchWithJWT = useFetchWithJWT();
  const auth = useCallback(() => {
    // token is still valid
    if (isValid()) {
      return fetchWithJWT(
        '/api/v1/token',
        {
          raw: true,
        },
        locale.HTTP.ERROR.TOKEN
      )
        .then(formatLoginResponse)
        .catch(() => ({}));
    }

    return (
      fetchFn('/api/v1/login', {}, locale.HTTP.ERROR.LOGIN)
        .then(formatLoginResponse)
        // prevent this from erroring, so it doesn't retry (this was causing the login form to clear on the user)
        // will need to check if `data.id` exists
        .catch(() => ({}))
    );
  }, [isValid, fetchWithJWT]);

  return useQuery({
    queryKey,
    queryFn: auth,
    staleTime,
    gcTime: Infinity,
    retry: false,
    refetchInterval,
    refetchIntervalInBackground: true,
    placeholderData: {},
    select,
  });
};

export default useLogin;
