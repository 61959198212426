/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DAY, locale, objectToQueryParams } from '@churchms/shared';
import { profilePicture } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import useLogin from './useLogin';
import { useTokenIsValid } from '..';

const useProfilePicture = (id) => {
  const { data } = useLogin();

  const fetchWithJWT = useFetchWithJWT();
  const isValid = useTokenIsValid();

  const fetchProfilePicture = useCallback(
    ({ queryKey }) => {
      const [, _id] = queryKey;
      return fetchWithJWT(
        `/api/v1/files/profile-picture${objectToQueryParams({ id: _id })}`,
        { isBlob: true },
        locale.HTTP.ERROR.GET_PROFILE_PICTURE
      ).catch(() => ''); // Prevent any automatic retry
    },
    [fetchWithJWT]
  );

  return useQuery({
    queryKey: profilePicture(id),
    queryFn: fetchProfilePicture,
    enabled: !!data.id && !!id && isValid(),
    retry: false,
    staleTime: DAY,
  });
};

export default useProfilePicture;
