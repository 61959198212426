import { useBreakpoint } from '../../../hooks';
import Avatar from '../../Avatar';
import styles from './styles.module.scss';

const Content = ({ children, ...avatarProps }) => {
  const size = useBreakpoint((breakpoint) => {
    if (breakpoint === 'tablet' || breakpoint === 'desktop') return 'large';

    return 'medium';
  });

  return (
    <>
      <Avatar {...avatarProps} size={size} />
      <div className={styles.itemContent}>{children}</div>
    </>
  );
};

export default Content;
