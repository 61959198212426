import User from './User';
import styles from './styles.module.scss';

const Members = ({ members = [] }) => (
  <div className={styles.members}>
    {members.map((u) => (
      <User key={u.id} {...u} />
    ))}
  </div>
);

export default Members;
