/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import useLogin from './useLogin';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 12 * HOUR,
};

export const useFetchDirectory = () => {
  const fetchWithJWT = useFetchWithJWT();

  const fetchDirectory = useCallback(
    () =>
      fetchWithJWT(`/api/v1/directory`, {}, locale.HTTP.ERROR.FAMILY_GET).then(
        (data) =>
          data.reduce(
            (acc, family) => {
              acc.families.push(family);
              acc.users = acc.users.concat(family.members);
              return acc;
            },
            { families: [], users: [] }
          )
      ),
    [fetchWithJWT]
  );

  return fetchDirectory;
};

const useDirectory = (select) => {
  const { data } = useLogin();
  const churchId = data?.churchId;

  const fetchDirectory = useFetchDirectory();
  const isValid = useTokenIsValid();

  return useQuery({
    queryKey: directory(),
    queryFn: fetchDirectory,
    select,
    enabled: !!churchId && isValid(),
    placeholderData: {
      families: [],
      users: [data],
    },
    ...config,
  });
};

export default useDirectory;
