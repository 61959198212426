import { locale } from '@churchms/shared';
import { ReactComponent as DeleteSVG } from '../../../icons/delete.svg';
import styles from './styles.module.scss';

const DeleteFamily = ({ onClick, onCloseContextMenu }) => (
  <li className={styles.contextItem}>
    <button
      onClick={() => {
        onClick();
        onCloseContextMenu();
      }}
    >
      {locale.MODALS.DELETE_FAMILY._}
      <DeleteSVG />
    </button>
  </li>
);

export default DeleteFamily;
