import { useCallback, useMemo } from 'react';
import { locale, formatDatePickerDate } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form, CreateUserForm } from '../../../components';
import { useCreateUserForm } from '../../../hooks';
import { useDirectory } from '../../../hooks/query';

const CreateUserOrFamily = ({
  mutate,
  isPending,
  error,
  legend,
  title,
  modalType,
  isNewFamily,
  init,
}) => {
  const { data: users } = useDirectory((d) => d?.users || []);
  const createUserProps = useCreateUserForm(init);

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({
          ...createUserProps.state,
          birthday: formatDatePickerDate(createUserProps.state.birthday, true),
        });
      }
    },
    [modalType, createUserProps.state, mutate]
  );

  const _error = useMemo(() => {
    if (error) {
      return error;
    }

    const nameExists = users.some(
      ({ name }) =>
        name &&
        name.first === createUserProps.state.name.first &&
        name.last === createUserProps.state.name.last
    );
    if (nameExists) {
      return new Error(locale.HTTP.ERROR.NAME_EXISTS);
    }

    return null;
  }, [error, users, createUserProps.state]);

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: locale.COMMON.CREATE,
      disabled: !!_error,
      type: 'submit',
    },
  ];

  return (
    <Form.Wrapper onSubmit={onSave} isLoading={isPending} id={modalType}>
      <Header title={title} />
      <Body>
        <CreateUserForm
          legend={legend}
          {...createUserProps}
          isNewFamily={isNewFamily}
          error={_error}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default CreateUserOrFamily;
