/* istanbul ignore file */

import { useQueryClient } from '@tanstack/react-query';
import { forgotPasswordKey } from './queryKeys';

const useForgotPassword = (email) => {
  const queryClient = useQueryClient();

  return (
    queryClient.getQueryState(forgotPasswordKey(email)) || {
      data: null,
      isLoading: false,
      error: null,
    }
  );
};

export default useForgotPassword;
