/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { directory, login } from '../queryKeys';
import useLogin from '../query/useLogin';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useDeletePhoneNumber = (userId, { _onMutate = () => {} } = {}) => {
  const { data } = useLogin();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();
  const loginKey = login();

  const removePhoneNumber = useCallback(
    (phoneId) =>
      fetchWithJWT(
        `/api/v1/user/phoneNumber?phoneId=${phoneId}&userId=${userId}`,
        {
          method: 'DELETE',
        },
        locale.HTTP.ERROR.DELETE_PHONE
      ),
    [userId, fetchWithJWT]
  );

  return useMutation({
    mutationFn: removePhoneNumber,
    onMutate: async (phoneId) => {
      const filterPhone = (p) => p.id !== phoneId;

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await Promise.all(
        [
          // editing someone in the directory:
          queryClient.cancelQueries(directoryKey),
          // editing self:
          data.id === userId && queryClient.cancelQueries(loginKey),
        ].filter(Boolean)
      );

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);
      const previousLogin = queryClient.getQueryData(loginKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        return {
          ...prev,
          families: prev.families.map((family) => {
            const found = family.members.find(({ id }) => id === userId);
            // Need to update this family
            if (found) {
              return {
                ...family,
                members: family.members.map((user) => {
                  if (user.id === userId) {
                    return {
                      ...user,
                      phoneNumbers: user.phoneNumbers.filter(filterPhone),
                    };
                  }
                  return user;
                }),
              };
            }
            return family;
          }),
          users: prev.users.map((user) => {
            if (user.id === userId) {
              return {
                ...user,
                phoneNumbers: user.phoneNumbers.filter(filterPhone),
              };
            }
            return user;
          }),
        };
      });

      if (data.id === userId) {
        queryClient.setQueryData(loginKey, (prev) => {
          return {
            ...prev,
            phoneNumbers: prev.phoneNumbers.filter(filterPhone),
          };
        });
      }

      _onMutate();

      // Return a context object with the snapshotted value
      return { previousDirectory, previousLogin };
    },
    onError: (err, phoneId, { previousDirectory, previousLogin }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      queryClient.setQueryData(loginKey, previousLogin);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useDeletePhoneNumber;
