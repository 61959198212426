import Avatar from '../../../../components/Avatar';
import { Form } from '../../../../components';
import styles from './familyList.module.scss';

const FamilyList = ({
  className,
  members = [],
  headOfHousehold,
  onClick,
  selected,
  size = 'small',
}) => (
  <ul className={`${styles.familyList}${className ? ` ${className}` : ''}`}>
    {members.map(({ name, id, isOptimistic }) => {
      const content = (
        <>
          <div>
            <Avatar name={name} size={size} id={id} />
          </div>
          <div className={styles.name}>
            {name.first} {name.last}
            {id === headOfHousehold && '*'}
          </div>
        </>
      );
      return (
        <li key={id} className={isOptimistic ? styles.isOptimistic : ''}>
          {onClick ? (
            <label>
              {content}
              <Form.Checkbox
                className={styles.checkbox}
                indeterminate={selected[id] === undefined}
                checked={selected[id] === true}
                onChange={() => onClick(id)}
              />
            </label>
          ) : (
            <div>{content}</div>
          )}
        </li>
      );
    })}
  </ul>
);

export default FamilyList;
