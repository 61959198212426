/* istanbul ignore file */

import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { blobToBase64, fetchFn, getTokenCache } from '../utils';
import { login } from './queryKeys';
import { locale } from '@churchms/shared';
import useLogout from './useLogout';

const queryKey = login();

const useFetchWithJWT = () => {
  const logout = useLogout();
  const queryClient = useQueryClient();

  const fetchWithJWT = useCallback(
    (
      path,
      { raw = false, headers = {}, ...config } = {},
      clientErrorMessage
    ) => {
      const { token } = getTokenCache(queryClient);

      return fetchFn(
        path,
        {
          headers: {
            ...headers,
            // ...with the token
            Authorization: `Bearer ${token}`,
          },
          ...config,
        },
        clientErrorMessage
      ).then(async (res) => {
        if (raw) {
          return res;
        }

        if (config.isBlob) {
          return await blobToBase64(res);
        }

        if (res.token) {
          queryClient.setQueryData(queryKey, (prev) => ({
            ...prev,
            token: res.token,
            expiresAfter: res.expiresAfter,
          }));
        }

        if (res.status === 'success') return res.data;

        // request was made with an invalid token
        if (
          res.status === 'fail' &&
          res.message === locale.HTTP.ERROR.INVALID_TOKEN
        ) {
          logout();
        }

        throw new Error(res.message);
      });
    },
    [queryClient, logout]
  );

  return fetchWithJWT;
};

export default useFetchWithJWT;
