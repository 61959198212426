import { useState, useCallback } from 'react';
import { locale, localeFormat } from '@churchms/shared';
import Header from '../../Header';
import Body from '../../Body';
import Footer from '../../Footer';
import { Form } from '../../../../components';
import { useDebounce } from '../../../../hooks';
import { useIssues, useLabels } from '../../../../hooks/query';
import { useSubmitFeedback } from '../../../../hooks/mutate';
import SimilarIssues from './SimilarIssues';

const { FEEDBACK } = locale.MODALS;

const Feedback = ({ modalType, closeModal }) => {
  const { data: issueOptions } = useLabels((d) => {
    const mapped = d.map(({ id, name }) => ({
      key: id,
      value: id,
      children: name,
    }));

    return [
      {
        key: 0,
        value: 0,
        children: 'uncategorized',
      },
      ...mapped,
    ];
  });

  const [error, setError] = useState('');
  const [label, setLabel] = useState(issueOptions[0]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const onSelectFile = (e) => {
    setError('');
    const isTooLarge = e.target.files[0].size > 1024 * 1024 * 5;

    if (isTooLarge) {
      setError(localeFormat(locale.HTTP.ERROR.FILE_TOO_LARGE, '5 MB'));
      e.preventDefault();
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const { mutate } = useSubmitFeedback({ _onMutate: closeModal });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({
          issue: {
            label: label.value,
            title,
            body,
          },
          file: !error && selectedFile && selectedFile,
        });
      }
    },
    [modalType, mutate, label.value, title, body, selectedFile, error]
  );

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSubmit,
      primary: true,
      children: locale.COMMON.SUBMIT,
      disabled: !!error,
      type: 'submit',
    },
  ];

  const [search] = useDebounce(title);
  const { data } = useIssues(search);

  return (
    <Form.Wrapper onSubmit={onSubmit} id={modalType}>
      <Header title={FEEDBACK._} />
      <Body>
        <Form.TextField
          label={locale.COMMON.TITLE}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          fullWidth
        />
        <SimilarIssues data={search && data} />
        <Form.TextArea
          label={locale.COMMON.BODY}
          value={body}
          onChange={(e) => setBody(e.target.value)}
          fullWidth
          placeholder={FEEDBACK.BODY_PLACEHOLDER}
          hideLabel
        />
        <Form.SingleFile
          label={locale.COMMON.IMAGE}
          onChange={onSelectFile}
          accept="image/png, image/jpeg"
          hideLabel
        />
        {error && <span>{error}</span>}
        <Form.Dropdown
          label={locale.COMMON.TYPE}
          options={issueOptions}
          onSelect={setLabel}
          selected={label}
          hideLabel
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default Feedback;
