import { forwardRef } from 'react';
import Component from 'react-new-window';

const NewWindow = forwardRef(({ onUnload, copyStyles, ...props }, ref) => {
  if (process.env.NODE_ENV === 'test') {
    return <div {...props} ref={ref} />;
  }
  return (
    <Component
      onUnload={onUnload}
      copyStyles={copyStyles}
      {...props}
      ref={ref}
    />
  );
});

export default NewWindow;
