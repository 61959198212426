/* istanbul ignore file */

const emptyAddress = {
  label: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
};

module.exports = emptyAddress;
