import { ROLE } from '@churchms/shared';
import { ContextMenu, ContextMenuItem } from '../../components';
import Avatar from '../../components/Avatar';
import { modalTypes } from '../../contexts/ModalContext';
import { useModalContext } from '../../hooks/context';
import { useDirectory, useLogin } from '../../hooks/query';
import { ReactComponent as ServiceSVG } from '../../icons/room_service.svg';
import { ReactComponent as ItemSVG } from '../../icons/shopping_bag.svg';
import styles from './styles.module.scss';

const ListingPreview = (props) => {
  const { id, type, title, body, author, updatedAt, price } = props;

  const { openModal } = useModalContext();
  const { data: authorObject } = useDirectory((d) => {
    return d?.users.find((u) => u.id === author);
  });

  const { data } = useLogin();

  const isAdmin = data.role === ROLE.ADMIN;
  const isOwnListing = author === data.id;

  const icon = type.includes('service') ? <ServiceSVG /> : <ItemSVG />;
  const label = type.includes('wanted') ? 'Wanted' : 'Offered';

  const contextMenuItems =
    isAdmin || isOwnListing
      ? [
          {
            key: 'edit-listing',
            Component: ContextMenuItem.EditListing,
            onClick: () => openModal(modalTypes.EDIT_LISTING, props),
          },
          {
            key: 'delete-listing',
            Component: ContextMenuItem.DeleteListing,
            onClick: () => openModal(modalTypes.DELETE_LISTING, { id }),
          },
        ]
      : [];

  return (
    <article className={styles.listingPreview}>
      <ContextMenu className={styles.contextMenu} items={contextMenuItems} />
      <div className={styles.listingTitle}>
        <h3>{title}</h3>
      </div>
      <div className={styles.listingDate}>
        {new Date(updatedAt).toLocaleString('default', {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </div>
      {price && <div>{price}</div>}
      <div className={styles.listingBody}>{body}</div>
      <div className={styles.listingAuthor}>
        <Avatar id={author} name={authorObject?.name} size="x-small" />
        {authorObject?.name.first} {authorObject?.name.last}
        <span>
          {icon}
          {label}
        </span>
      </div>
    </article>
  );
};

export default ListingPreview;
