const actionType = {
  SET_NAME: 'SET_NAME',
  SET_CITY: 'SET_CITY',
  SET_STATE: 'SET_STATE',
  SET_WEBSITE: 'SET_WEBSITE',
  SET_PHONE: 'SET_PHONE',
  SET_EMAIL: 'SET_EMAIL',
};

export const setName = (name) => ({
  type: actionType.SET_NAME,
  name,
});

export const setWebsite = (website) => ({
  type: actionType.SET_WEBSITE,
  website,
});

export const setPhone = (phone) => ({
  type: actionType.SET_PHONE,
  phone,
});

export const setEmail = (email) => ({
  type: actionType.SET_EMAIL,
  email,
});

export const initialState = {
  name: '',
  website: '',
  phone: '',
  email: '',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case actionType.SET_WEBSITE:
      return {
        ...state,
        website: action.website,
      };
    case actionType.SET_PHONE:
      return {
        ...state,
        phone: action.phone,
      };
    case actionType.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    default:
      return state;
  }
};
