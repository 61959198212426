/* istanbul ignore file */

const PHONE_LABEL = {
  CELL: 'Cell',
  HOME: 'Home',
  WORK: 'Work',
  OTHER: 'Other',
};

module.exports = PHONE_LABEL;
