/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useHideUser = (userId, familyId, { _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const hideUser = useCallback(
    (isHidden) =>
      fetchWithJWT(
        `/api/v1/user/hide${objectToQueryParams({ userId })}`,
        {
          method: 'PUT',
          body: {
            isHidden,
          },
        },
        locale.HTTP.ERROR.HIDE_USER
      ),
    [fetchWithJWT, userId]
  );

  return useMutation({
    mutationFn: hideUser,
    onMutate: async (isHidden) => {
      _onMutate();

      const mapUser = (prev) => {
        if (prev.id === userId) {
          return {
            ...prev,
            isHidden,
          };
        }
        return prev;
      };

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        const users = prev.users.map(mapUser);

        return {
          families: prev.families.map((family) => {
            if (family.id === familyId) {
              return {
                ...family,
                members: family.members.map(mapUser),
              };
            }
            return family;
          }),
          users,
        };
      });

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, isHidden, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useHideUser;
