/* istanbul ignore file */

import styles from './styles.module.scss';

const ButtonAsLink = ({ children, className, ...rest }) => (
  <button
    className={`${styles.linkButton}${className ? ` ${className}` : ''}`}
    type="button"
    {...rest}
  >
    {children}
  </button>
);

export default ButtonAsLink;
