import { useCallback, useMemo, useState } from 'react';
import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useChangeEmail } from '../../../hooks/mutate';

const ChangeEmail = ({ modalType }) => {
  const [newEmail, setNewEmail] = useState('');
  const { data: isDuplicate } = useDirectory((d) => {
    return d.users.some((u) => u.email === newEmail);
  });
  const { mutate, isPending, data, error, isSuccess } = useChangeEmail();

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate(newEmail);
      }
    },
    [modalType, newEmail, mutate]
  );

  const footerBtns = useMemo(() => {
    if (isSuccess || error) {
      return [{ children: locale.COMMON.OK, primary: true }];
    }

    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        disabled: isDuplicate,
        type: 'submit',
      },
    ];
  }, [isSuccess, error, onSave, isDuplicate]);

  const onSetNewEmail = (e) => setNewEmail(e.target.value);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType} isLoading={isPending}>
      <Header title={locale.MODALS.CHANGE_EMAIL._} />
      <Body>
        {data ? (
          <p>{data}</p>
        ) : (
          <>
            <Form.TextField
              label={locale.COMMON.EMAIL}
              type="email"
              value={newEmail}
              onChange={onSetNewEmail}
            />
            {error && <p>{error.message}</p>}
            <p>{locale.MODALS.CHANGE_EMAIL.DESCRIPTION}</p>
          </>
        )}
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default ChangeEmail;
