import { NavLink, Link } from 'react-router-dom';
import styles from './styles.module.scss';

const EmptyComponent = ({ children }) => children;

const NavIcon = ({
  label,
  Icon = EmptyComponent,
  isStatic = false,
  isNavLink = false,
  ...props
}) => {
  const content = (
    <>
      <Icon className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </>
  );

  if (isNavLink) {
    return (
      <NavLink
        className={styles.link}
        {...props}
        activeClassName={styles.active}
      >
        {content}
      </NavLink>
    );
  }

  if (isStatic) {
    return (
      <div className={`${styles.link} ${styles.regularLink}`}>{content}</div>
    );
  }

  return (
    <Link className={`${styles.link} ${styles.regularLink}`} {...props}>
      {content}
    </Link>
  );
};

export default NavIcon;
