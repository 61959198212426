import LoadingOrError from '../LoadingOrError';
import styles from './styles.module.scss';

const Wrapper = ({ className, isLoading, children, ...props }) => (
  <form
    className={`${styles.wrapper}${className ? ` ${className}` : ''}`}
    {...props}
  >
    <LoadingOrError isLoading={isLoading} showChildrenWhileLoading>
      {children}
    </LoadingOrError>
  </form>
);

export default Wrapper;
