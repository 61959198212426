import Wrapper from './Wrapper';
import Header from './Header';
import Body from './Body';
import FallbackComponent from './FallbackComponent';

const Page = {
  Wrapper,
  Header,
  Body,
  FallbackComponent,
};

export default Page;
