import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';

const ErrorModal = ({ message = locale.MODALS.ERROR.BODY }) => {
  const footerBtns = [
    {
      children: locale.COMMON.OK,
      primary: true,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.ERROR._} />
      <Body>{message}</Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default ErrorModal;
