import { useRef } from 'react';
import NewWindow from '../NewWindow';
import { useSortUsersAndFamilies } from '../../hooks';
import { useDirectory } from '../../hooks/query';
import IconButton from '../IconButton';
import { ReactComponent as PrintSVG } from '../../icons/local_printshop.svg';
import Family from './Family';

import styles from './styles.module.scss';
import { AvatarDefinitions } from '../Avatar';

const PrintableDirectory = ({ onUnload }) => {
  const ref = useRef();
  const { data } = useDirectory((d) => d.families);
  const sort = useSortUsersAndFamilies();

  return (
    <NewWindow onUnload={onUnload} ref={ref} copyStyles>
      <AvatarDefinitions />
      <div className={styles.directory}>
        {data.sort(sort).map((f) => {
          return <Family key={f.id} {...f} />;
        })}
      </div>
      <IconButton
        onClick={() => {
          if (ref.current) {
            ref.current.window.print();
          }
        }}
        className={styles.printButton}
      >
        <PrintSVG />
      </IconButton>
    </NewWindow>
  );
};

export default PrintableDirectory;
