/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory, login } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import useLogin from '../query/useLogin';
import { modalTypes } from '../../contexts/ModalContext';

const useMoveUser = (
  userId,
  currentFamilyId,
  { _onMutate = () => {}, _onSuccess = () => {} } = {}
) => {
  const history = useHistory();
  const { data } = useLogin();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();
  const loginKey = login();

  const moveUser = useCallback(
    ({ surname, ...payload }) =>
      fetchWithJWT(
        `/api/v1/user/move${objectToQueryParams({ userId })}`,
        {
          method: 'PUT',
          body: payload,
        },
        locale.HTTP.ERROR.MOVE_USER
      ),
    [fetchWithJWT, userId]
  );

  return useMutation({
    mutationFn: moveUser,
    onMutate: async ({ familyId, shouldUpdateLastName, surname }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);
      const previousLogin = queryClient.getQueryData(loginKey);

      // if no family id, must create new one
      // cannot optimistic update
      if (!familyId) {
        return { previousDirectory, previousLogin };
      }

      _onMutate();

      history.push(`/directory/${familyId}`);

      const mapNewUser = (prev) => {
        if (prev.id === userId) {
          return {
            ...prev,
            name: {
              ...prev.name,
              last: shouldUpdateLastName ? surname : prev.name.last,
            },
            familyId,
            isOptimistic: true,
          };
        }
        return prev;
      };

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        const users = prev.users.map(mapNewUser);
        const user = users.find((u) => u.id === userId);
        return {
          ...prev,
          families: prev.families
            .map((family) => {
              // Remove them from old family
              if (family.id === currentFamilyId) {
                return {
                  ...family,
                  members: family.members.filter((u) => u.id !== userId),
                };
              } else if (family.id === familyId) {
                // add them to new family
                return {
                  ...family,
                  members: [...family.members, user],
                };
              }
              return family;
            })
            // If the family is now empty, remove them
            .filter(({ members }) => !!members.length),
          users,
        };
      });

      if (data.id === userId) {
        queryClient.setQueryData(loginKey, mapNewUser);
      }

      // Return a context object with the snapshotted value
      return { previousDirectory, previousLogin };
    },
    onError: (err, variables, { previousDirectory, previousLogin }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      queryClient.setQueryData(loginKey, previousLogin);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSuccess: (data, { familyId }) => {
      _onSuccess();
      // Need to create a new family
      // this was not optimistic
      // need to navigate now
      if (!familyId) {
        queryClient.setQueryData(directoryKey, (prev) => ({
          ...prev,
          families: [
            ...prev.families,
            {
              surname: data.name.last,
              members: [data],
              id: data.familyId,
              headOfHousehold: data.id,
            },
          ],
          users: prev.users.map((u) => {
            if (u.id === userId) {
              return data;
            }
            return u;
          }),
        }));
        history.push(`/directory/${data.familyId}`);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useMoveUser;
