import styles from './styles.module.scss';

const DataList = ({
  label,
  list = [],
  className,
  required,
  error,
  ...props
}) => {
  const input = (
    <>
      <input
        className={`${styles.textFieldInput}${
          className ? ` ${className}` : ''
        }`}
        required={required}
        list={label}
        {...props}
      />
      <datalist id={label}>
        {list.map(({ value }) => (
          <option key={value} value={value} />
        ))}
      </datalist>
    </>
  );

  if (!label) {
    return input;
  }

  return (
    <label className={styles.textFieldLabel}>
      <span>
        {label}
        {required ? '*' : ''}
      </span>
      {input}
      {error && <div>{error?.message || error}</div>}
    </label>
  );
};

export default DataList;
