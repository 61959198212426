import TextField from './TextField';
import Wrapper from './Wrapper';
import Footer from './Footer';
import Body from './Body';
import Fieldset from './Fieldset';
import Checkbox from './Checkbox';
import DataList from './DataList';
import PhoneNumber from './PhoneNumber';
import Dropdown from './Dropdown';
import DatePicker from './Date';
import TextArea from './TextArea';
import SingleFile from './SingleFile';
import ZipCode from './ZipCode';
import Range from './Range';
import URLInput from './URL';
import statesOfAmerica from './statesOfAmerica';
import countries from './countries';

const Form = {
  Wrapper,
  TextField,
  Footer,
  Body,
  Fieldset,
  Checkbox,
  DataList,
  PhoneNumber,
  Dropdown,
  Date: DatePicker,
  TextArea,
  SingleFile,
  ZipCode,
  Range,
  URL: URLInput,
  statesOfAmerica,
  countries,
};

export default Form;
