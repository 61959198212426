import { useState } from 'react';
import { locale } from '@churchms/shared';
import { useEditPhoneNumber } from '../../../hooks/mutate';
import PhoneNumber, { phoneOptions } from './PhoneNumber';

const EditPhoneNumber = ({
  closeModal,
  userId,
  phoneId,
  modalType,
  ...props
}) => {
  const [value, setValue] = useState(props.value);
  const [label, setLabel] = useState(
    phoneOptions.find(({ value }) => value === props.label)
  );
  const { mutate } = useEditPhoneNumber(userId, phoneId, {
    _onMutate: closeModal,
  });
  return (
    <PhoneNumber
      formId={modalType}
      mutate={mutate}
      title={locale.MODALS.EDIT_PHONE._}
      value={value}
      setValue={setValue}
      label={label}
      setLabel={setLabel}
      closeModal={closeModal}
      userId={userId}
    />
  );
};

export default EditPhoneNumber;
