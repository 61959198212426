/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useAddEmail = (userId, { _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const addEmail = useCallback(
    (email) =>
      fetchWithJWT(
        `/api/v1/user/add-email?userId=${userId}`,
        {
          method: 'POST',
          body: { email },
        },
        locale.HTTP.ERROR.UPDATE_USER
      ),
    [userId, fetchWithJWT]
  );

  return useMutation({
    mutationFn: addEmail,
    onMutate: async (email) => {
      _onMutate();

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      const mapUser = (prev) => {
        if (prev.id === userId) {
          return {
            ...prev,
            isOptimistic: true,
            email,
          };
        }
        return prev;
      };

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        return {
          ...prev,
          families: prev.families.map((f) => {
            const found = f.members.some((u) => u.id === userId);
            if (found) {
              return {
                ...f,
                members: f.members.map(mapUser),
              };
            }
            return f;
          }),
          users: prev.users.map(mapUser),
        };
      });

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, email, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useAddEmail;
