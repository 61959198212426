/* istanbul ignore file */

import { Component } from 'react';
import { useSubmitFeedback } from '../hooks/mutate';

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    if (process.env.NODE_ENV === 'development') {
      console.warn(error, info.componentStack);
    } else {
      let body = '```\n';
      body += info.componentStack;
      body += '\n```';
      const issue = {
        label: 1, // bug
        title: error.message,
        body,
      };
      this.props.logError({ issue });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const { FallbackComponent, children, ...rest } = this.props;
      return <FallbackComponent {...rest} />;
    }

    return this.props.children;
  }
}

const ErrorBoundary = (props) => {
  const { mutate } = useSubmitFeedback();

  return <ErrorBoundaryComponent {...props} logError={mutate} />;
};

export default ErrorBoundary;
