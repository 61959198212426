import { locale, localeFormat } from '@churchms/shared';
import { useDeletePhoneNumber } from '../../../hooks/mutate';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';

const DeletePhoneNumber = ({ closeModal, userId, phoneId, value }) => {
  const { mutate } = useDeletePhoneNumber(userId, {
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate(phoneId),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.DELETE_PHONE._} />
      <Body>{localeFormat(locale.MODALS.DELETE_PHONE.CONFIRM, value)}</Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeletePhoneNumber;
