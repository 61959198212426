import Spinner from '../Spinner';
import styles from './styles.module.scss';

const Wrapper = ({
  className,
  error,
  isLoading,
  isPlaceholderData,
  children,
  noItems = false,
}) => (
  <div
    className={`${styles.grid}${className ? ` ${className}` : ''}${
      noItems ? ` ${styles.gridNoItems}` : ''
    }`}
  >
    {error ? (
      <div className={styles.gridError}>{error.message}</div>
    ) : (
      <>
        <Spinner isLoading={isLoading || isPlaceholderData} />
        {children}
      </>
    )}
  </div>
);

export default Wrapper;
