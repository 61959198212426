/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { listings } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useEditListing = ({ _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const listingsKey = listings();

  const editListing = useCallback(
    ({ id: listingId, ...payload }) =>
      fetchWithJWT(
        `/api/v1/listing${objectToQueryParams({ listingId })}`,
        {
          method: 'PUT',
          body: payload,
        },
        locale.HTTP.ERROR.LISTING_EDIT
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: editListing,
    onMutate: async (payload) => {
      _onMutate();
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(listingsKey);

      // Snapshot the previous value
      const previousListings = queryClient.getQueryData(listingsKey);

      // Optimistically update to the new value
      queryClient.setQueryData(listingsKey, (prev) => {
        return prev.map((l) => {
          if (l.id === payload.id) {
            return {
              ...l,
              ...payload,
              updatedAt: new Date().toISOString(),
              isOptimistic: true,
            };
          }
          return l;
        });
      });

      // Return a context object with the snapshotted value
      return { previousListings };
    },
    onError: (err, payload, { previousListings }) => {
      queryClient.setQueryData(listingsKey, previousListings);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries({ queryKey: listingsKey });
    },
  });
};

export default useEditListing;
