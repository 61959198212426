import { locale, localeFormat } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useEditUserBirthday } from '../../../hooks/mutate';

const DeleteBirthday = ({ userId, closeModal, name }) => {
  const { mutate } = useEditUserBirthday(userId, {
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate({ birthday: '' }),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.DELETE_BIRTHDAY._} />
      <Body>
        {localeFormat(
          locale.MODALS.DELETE_BIRTHDAY.CONFIRM,
          `${name.first} ${name.last}`
        )}
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeleteBirthday;
