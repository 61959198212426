/* istanbul ignore file */

import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { listings } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useCreateListing = ({ _onMutate = () => {} }) => {
  const { openModal } = useModalContext();
  const queryClient = useQueryClient();
  const fetchWithJWT = useFetchWithJWT();
  const listingsKey = listings();

  const createListing = useCallback(
    (newListing) =>
      fetchWithJWT(
        '/api/v1/listing',
        {
          method: 'POST',
          body: newListing,
        },
        locale.HTTP.ERROR.LISTING_CREATE
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: createListing,
    onMutate: async (newListing) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(listingsKey);

      // Snapshot the previous value
      const previousListings = queryClient.getQueryData(listingsKey);

      // Optimistically update to the new value
      queryClient.setQueryData(listingsKey, (prev) => {
        const withMockId = {
          ...newListing,
          isOptimistic: true,
          // placeholder id
          id: uuidv4(),
        };

        if (!prev) {
          return [withMockId];
        }

        return [...prev, withMockId];
      });

      _onMutate();

      // Return a context object with the snapshotted value
      return { previousListings };
    },
    onError: (err, newListing, { previousListings }) => {
      queryClient.setQueryData(listingsKey, previousListings);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: listingsKey });
    },
  });
};

export default useCreateListing;
