/* istanbul ignore file */

const listingType = {
  SERVICE_WANTED: 'service-wanted',
  SERVICE_OFFERED: 'service-offered',
  ITEM_WANTED: 'item-wanted',
  ITEM_FOR_SALE: 'item-for-sale',
};

module.exports = listingType;
