import { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSubmitLogin } from '../hooks/mutate';
import { LoginPage, Form, Button, ErrorMessage } from '../components';

const Login = ({ redirectTo }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { mutate, isPending, error } = useSubmitLogin({
    _onSuccess: () => {
      if (redirectTo) {
        history.push(redirectTo);
      }
    },
  });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      mutate({ email, password });
    },
    [email, password, mutate]
  );

  const onEmail = (e) => setEmail(e.target.value);
  const onPassword = (e) => setPassword(e.target.value);

  return (
    <LoginPage.Wrapper>
      <LoginPage.Form onSubmit={onSubmit} isLoading={isPending}>
        <Form.TextField
          label="Email"
          type="email"
          value={email}
          onChange={onEmail}
          required
        />
        <Form.TextField
          label="Password"
          type="password"
          value={password}
          onChange={onPassword}
          required
        />
        <ErrorMessage error={error} />
        <LoginPage.Footer>
          <Button primary type="submit">
            Login
          </Button>
          <Link to="/forgot-password">Forgot password?</Link>
        </LoginPage.Footer>
      </LoginPage.Form>
    </LoginPage.Wrapper>
  );
};

export default Login;
