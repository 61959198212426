/* istanbul ignore file */

import ConfirmDeleteChurch from './Modals/ConfirmDeleteChurch';
import ErrorModal from './Modals/ErrorModal';
import AddPhoneNumber from './Modals/AddPhoneNumber';
import EditPhoneNumber from './Modals/EditPhoneNumber';
import DeletePhoneNumber from './Modals/DeletePhoneNumber';
import CreateChurch from './Modals/CreateChurch';
import CreateUser from './Modals/CreateUser';
import CreateFamily from './Modals/CreateFamily';
import DeleteUser from './Modals/DeleteUser';
import DeleteFamily from './Modals/DeleteFamily';
import EditBirthday from './Modals/EditBirthday';
import DeleteBirthday from './Modals/DeleteBirthday';
import AddEmail from './Modals/AddEmail';
import EditEmail from './Modals/ChangeEmail';
import ConfirmChangeEmail from './Modals/ConfirmChangeEmail';
import AddAddress from './Modals/AddAddress';
import EditAddress from './Modals/EditAddress';
import DeleteAddress from './Modals/DeleteAddress';
import HideUser from './Modals/HideUser';
import ChangeName from './Modals/ChangeName';
import Feedback from './Modals/Feedback';
import ChangeFamilyName from './Modals/ChangeFamilyName';
import ChangeHeadOfHousehold from './Modals/ChangeHeadOfHousehold';
import ChangeRole from './Modals/ChangeRole';
import MoveUser from './Modals/MoveUser';
import DirectorySearch from './Modals/DirectorySearch';
import ChangePassword from './Modals/ChangePassword';
import AddProfilePicture from './Modals/AddProfilePicture';
import DeleteProfilePicture from './Modals/DeleteProfilePicture';
import ChangeFeeds from './Modals/ChangeFeeds';
import EditChurch from './Modals/EditChurch';
import ShareDirectory from './Modals/ShareDirectory';
import CreateListing from './Modals/CreateListing';
import DeleteListing from './Modals/DeleteListing';
import EditListing from './Modals/EditListing';
import MarketSearch from './Modals/MarketSearch';

const { modalTypes } = require('../../contexts/ModalContext');

const Switcher = (props) => {
  switch (props.modalType) {
    case modalTypes.CREATE_CHURCH:
      return <CreateChurch {...props} />;
    case modalTypes.CONFIRM_DELETE_CHURCH:
      return <ConfirmDeleteChurch {...props} />;
    case modalTypes.ADD_PHONE:
      return <AddPhoneNumber {...props} />;
    case modalTypes.ADD_EMAIL:
      return <AddEmail {...props} />;
    case modalTypes.CHANGE_EMAIL:
      return <EditEmail {...props} />;
    case modalTypes.CONFIRM_NEW_EMAIL:
      return <ConfirmChangeEmail {...props} />;
    case modalTypes.EDIT_PHONE:
      return <EditPhoneNumber {...props} />;
    case modalTypes.DELETE_PHONE:
      return <DeletePhoneNumber {...props} />;
    case modalTypes.CREATE_FAMILY:
      return <CreateFamily {...props} />;
    case modalTypes.CREATE_USER:
      return <CreateUser {...props} />;
    case modalTypes.DELETE_USER:
      return <DeleteUser {...props} />;
    case modalTypes.ERROR:
      return <ErrorModal {...props} />;
    case modalTypes.DELETE_FAMILY:
      return <DeleteFamily {...props} />;
    case modalTypes.EDIT_BIRTHDAY:
      return <EditBirthday {...props} />;
    case modalTypes.DELETE_BIRTHDAY:
      return <DeleteBirthday {...props} />;
    case modalTypes.CREATE_ADDRESS:
      return <AddAddress {...props} />;
    case modalTypes.EDIT_ADDRESS:
      return <EditAddress {...props} />;
    case modalTypes.DELETE_ADDRESS:
      return <DeleteAddress {...props} />;
    case modalTypes.HIDE_USER:
      return <HideUser {...props} />;
    case modalTypes.CHANGE_NAME:
      return <ChangeName {...props} />;
    case modalTypes.FEEDBACK:
      return <Feedback {...props} />;
    case modalTypes.CHANGE_FAMILY_NAME:
      return <ChangeFamilyName {...props} />;
    case modalTypes.CHANGE_HEAD_OF_HOUSEHOLD:
      return <ChangeHeadOfHousehold {...props} />;
    case modalTypes.CHANGE_ROLE:
      return <ChangeRole {...props} />;
    case modalTypes.MOVE_USER:
      return <MoveUser {...props} />;
    case modalTypes.DIRECTORY_SEARCH:
      return <DirectorySearch {...props} />;
    case modalTypes.CHANGE_PASSWORD:
      return <ChangePassword {...props} />;
    case modalTypes.ADD_PROFILE_PICTURE:
      return <AddProfilePicture {...props} />;
    case modalTypes.DELETE_PROFILE_PICTURE:
      return <DeleteProfilePicture {...props} />;
    case modalTypes.CHANGE_BLOG_FEEDS:
      return <ChangeFeeds {...props} />;
    case modalTypes.EDIT_CHURCH:
      return <EditChurch {...props} />;
    case modalTypes.SHARE_DIRECTORY:
      return <ShareDirectory {...props} />;
    case modalTypes.CREATE_LISTING:
      return <CreateListing {...props} />;
    case modalTypes.DELETE_LISTING:
      return <DeleteListing {...props} />;
    case modalTypes.EDIT_LISTING:
      return <EditListing {...props} />;
    case modalTypes.MARKET_SEARCH:
      return <MarketSearch {...props} />;
    default:
      return <ErrorModal />;
  }
};

export default Switcher;
