import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { listings } from '../queryKeys';
import { useFetchListings, config } from '../query/useListings';
import useTokenIsValid from '../useTokenIsValid';

const usePrefetchListings = () => {
  const queryClient = useQueryClient();

  const fetchListings = useFetchListings();
  const isValid = useTokenIsValid();

  return useCallback(async () => {
    if (isValid()) {
      await queryClient.prefetchQuery({
        queryKey: listings(),
        queryFn: fetchListings,
        ...config,
      });
    }
  }, [fetchListings, queryClient, isValid]);
};

export default usePrefetchListings;
