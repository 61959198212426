import styles from './styles.module.scss';

const TextArea = ({
  label,
  hideLabel = false,
  className,
  onBlur,
  onChange,
  onKeyDown,
  required,
  error,
  fullWidth = false,
  ...props
}) => {
  const input = (
    <textarea
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={`${styles.textArea} ${styles.textFieldInput}${
        className ? ` ${className}` : ''
      }`}
      required={required}
      {...props}
    />
  );

  if (!label) {
    return input;
  }

  return (
    <label
      className={`${styles.textFieldLabel}${
        fullWidth ? ` ${styles.fullWidth}` : ''
      }`}
    >
      <span className={hideLabel ? styles.hideLabel : ''}>
        {label}
        {required ? '*' : ''}
      </span>
      {input}
      {error && <div>{error?.message || error}</div>}
    </label>
  );
};

export default TextArea;
