import { locale } from '@churchms/shared';
import { useCreateChurch } from '../../../../hooks/mutate';
import Church from '../Church';

const CreateChurch = ({ modalType, closeModal }) => {
  const { mutate } = useCreateChurch({ _onMutate: closeModal });
  return (
    <Church
      title={locale.MODALS.CREATE_CHURCH._}
      modalType={modalType}
      mutate={mutate}
      isCreating
    />
  );
};

export default CreateChurch;
