import styles from './styles.module.scss';

const MiddleTruncate = ({ children }) => {
  if (typeof children !== 'string') {
    return children;
  }
  const index = Math.floor(children.length / 2);
  return (
    <div className={styles.wrapper}>
      <span className={styles.first}>{children.slice(0, index)}</span>
      <span className={styles.second}>{children.slice(index)}</span>
    </div>
  );
};

export default MiddleTruncate;
