const isAdult = (birthday) => {
  if (
    birthday &&
    birthday instanceof Date &&
    `${birthday}` !== 'Invalid Date'
  ) {
    birthday.setFullYear(birthday.getFullYear() + 18);
    return birthday <= new Date();
  }

  return false;
};

module.exports = isAdult;
