/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { profilePicture } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useDeleteProfilePicture = (
  type = 'user',
  { _onMutate = () => {} } = {}
) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();

  const deleteProfilePicture = useCallback(
    (id) =>
      fetchWithJWT(
        `/api/v1/files/profile-picture${objectToQueryParams({ type, id })}`,
        {
          method: 'DELETE',
        },
        locale.HTTP.ERROR.DELETE_PROFILE_PICTURE
      ),
    [fetchWithJWT, type]
  );

  return useMutation({
    mutationFn: deleteProfilePicture,
    onMutate: _onMutate,
    onError: (err) => {
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: (data, error, id) => {
      const profilePictureKey = profilePicture(id);
      queryClient.setQueryData(profilePictureKey, '');
    },
  });
};

export default useDeleteProfilePicture;
