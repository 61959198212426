import { locale } from '@churchms/shared';
import Form from './Form';
import ErrorMessage from './ErrorMessage';

const NewPasswordForm = ({
  showOldPassword = false,
  email,
  oldPassword,
  onOldPassword,
  newPassword,
  onNewPassword,
  confirmPassword,
  onConfirmPassword,
  error,
}) => (
  <>
    <Form.TextField
      value={email}
      type="email"
      disabled
      hidden
      label={locale.COMMON.EMAIL}
    />
    {showOldPassword && (
      <Form.TextField
        label={locale.MODALS.CHANGE_PASSWORD.OLD_PASSWORD}
        type="password"
        value={oldPassword}
        onChange={onOldPassword}
        required
      />
    )}
    <Form.TextField
      label={locale.MODALS.CHANGE_PASSWORD.NEW_PASSWORD}
      type="password"
      value={newPassword}
      onChange={onNewPassword}
      required
    />
    <Form.TextField
      label={locale.MODALS.CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD}
      type="password"
      value={confirmPassword}
      onChange={onConfirmPassword}
      required
    />
    <ErrorMessage error={error} />
  </>
);

export default NewPasswordForm;
