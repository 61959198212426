import { useMemo } from 'react';
import {
  ADDRESS_LABEL,
  formatDatePickerDate,
  isAdult,
  locale,
  localeFormat,
} from '@churchms/shared';

import styles from './styles.module.scss';
import Members from './Members';

const Family = ({ id, surname, members, headOfHousehold }) => {
  const [adults, children] = members.reduce(
    (acc, u) => {
      if (u.isAdult || isAdult(formatDatePickerDate(u.birthday))) {
        acc[0].push(u);
      } else {
        acc[1].push(u);
      }
      return acc;
    },
    [[], []]
  );

  const address = useMemo(() => {
    const found = members.find((u) => u.id === headOfHousehold);
    const home = found.addresses.find((a) => a.label === ADDRESS_LABEL.HOME);

    return home;
  }, [headOfHousehold, members]);

  return (
    <div className={styles.family}>
      <div className={styles.surname}>
        <div>{localeFormat(locale.COMMON.FAMILY_LABEL_WITH_NAME, surname)}</div>
        {address && (
          <div className={styles.address}>
            <div>
              {address.street1}
              {address.street2 && ', '}
              {address.street2}
            </div>
            <div>
              {address.city}, {address.state} {address.zipCode}
            </div>
          </div>
        )}
      </div>
      <div className={styles.memberGroups}>
        {!!adults.length && <Members members={adults} />}
        {!!children.length && <Members members={children} />}
      </div>
    </div>
  );
};

export default Family;
