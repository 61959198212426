const formatDatePickerDate = require('./formatDatePickerDate');
const isAdultFn = require('./isAdult');

const isValidBirthday = (isAdult, birthday) => {
  // allow clearing birthday
  if (birthday === '') return true;

  const formattedBirthday = formatDatePickerDate(birthday);
  const result = isAdultFn(formattedBirthday);
  return (isAdult && result) || (!isAdult && !result);
};

module.exports = isValidBirthday;
