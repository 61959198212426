/* istanbul ignore file */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { useHistory } from 'react-router-dom';
import { fetchFn, formatLoginResponse } from '../../utils';
import { login } from '../queryKeys';

const useRecoverAccount = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const recoverAccount = (payload) =>
    fetchFn(
      '/api/v1/recover-account',
      {
        method: 'POST',
        body: payload,
      },
      locale.HTTP.ERROR.RECOVER_ACCOUNT
    ).then(formatLoginResponse);

  return useMutation({
    mutationFn: recoverAccount,
    onSuccess: (data) => {
      queryClient.setQueryData(login(), data);
      history.push('/');
    },
  });
};

export default useRecoverAccount;
