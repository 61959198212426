import { usePrefetchChurches } from '../../hooks/prefetch';
import { ReactComponent as HomeSVG } from '../../icons/home_filled.svg';
import { ReactComponent as ChurchSVG } from '../../icons/business.svg';
import NavItem from './NavItem';

const ProvisionerNav = () => {
  const prefetchChurches = usePrefetchChurches();

  return (
    <>
      <NavItem
        to="/"
        Icon={HomeSVG}
        label="Home"
        exact
        onMouseEnter={prefetchChurches}
      />
      <NavItem to="/churches" Icon={ChurchSVG} label="Churches" />
    </>
  );
};

export default ProvisionerNav;
