import { useCallback, useState } from 'react';
import { LISTING_TYPE, locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import {
  initialMarketSearchSelected,
  myListingKey,
} from '../../../pages/Market/Market';

const { MARKET_SEARCH } = locale.MODALS;

const MarketSearch = ({
  closeModal,
  search,
  setSearch,
  selected,
  setSelected,
}) => {
  const [state, setState] = useState(search);
  const [selectedState, setSelectedState] = useState(selected);

  const applySearch = useCallback(() => {
    setSearch(state);
    setSelected(selectedState);
  }, [selectedState, state, setSearch, setSelected]);

  const footerBtns = [
    {
      children: locale.COMMON.RESET,
      onClick: () => {
        setSearch('');
        setSelectedState(initialMarketSearchSelected);
      },
    },
    {
      primary: true,
      onClick: () => {
        applySearch();
        closeModal();
      },
      children: locale.COMMON.APPLY,
    },
  ];

  const onSearch = (e) => {
    setState(e.target.value);
  };

  const onClick = (e) => {
    setSelectedState((prev) => {
      const obj = {
        ...prev,
        [e.target.name]: e.target.checked,
      };
      return obj;
    });
  };

  const onToggleMyListings = (e) => {
    setSelectedState((prev) => {
      const name = e.target.name;
      const currentValue = selectedState[name];
      let newValue = true;
      if (currentValue === true) {
        newValue = false;
      } else if (currentValue === false) {
        newValue = undefined;
      }
      return {
        ...prev,
        [name]: newValue,
      };
    });
  };

  const radios = Object.values(LISTING_TYPE);
  return (
    <>
      <Header title={MARKET_SEARCH._} />
      <Body>
        <Form.TextField
          label={locale.COMMON.SEARCH}
          value={state}
          onChange={onSearch}
          fullWidth
        />
        {radios.map((r) => {
          const plainText = r.replace(/-/g, ' ');
          return (
            <Form.Checkbox
              key={r}
              label={plainText}
              checked={selectedState[r]}
              onChange={onClick}
              name={r}
            />
          );
        })}
        <Form.Checkbox
          label={MARKET_SEARCH.MY_LISTINGS}
          checked={selectedState[myListingKey] === true}
          onChange={onToggleMyListings}
          name={myListingKey}
          indeterminate={selectedState[myListingKey] === undefined}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default MarketSearch;
