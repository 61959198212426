import { Link } from 'react-router-dom';
import FormComponent from '../Form';
import { useLogin } from '../../hooks/query';
import styles from './styles.module.scss';

const Form = ({ children, isLoading, className, ...props }) => {
  const { refetch } = useLogin();
  return (
    <FormComponent.Wrapper
      className={`${styles.form}${className ? ` ${className}` : ''}`}
      isLoading={isLoading}
      {...props}
    >
      <Link to="/" className={styles.logo} onClick={refetch}>
        <img src="/logo512.png" alt="ChurchMS Logo" />
      </Link>
      {children}
    </FormComponent.Wrapper>
  );
};

export default Form;
