/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { emptyUser, locale } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import useLogin from '../query/useLogin';
import { modalTypes } from '../../contexts/ModalContext';

const useCreateFamily = ({ _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const { data } = useLogin();
  const churchId = data?.churchId;
  const queryClient = useQueryClient();
  const fetchWithJWT = useFetchWithJWT();
  const directoryKey = directory();

  const createFamily = useCallback(
    (user) =>
      fetchWithJWT(
        '/api/v1/family',
        {
          method: 'POST',
          body: user,
        },
        locale.HTTP.ERROR.CHURCH_CREATE
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: createFamily,
    onMutate: async (user) => {
      _onMutate();

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      const familyId = uuidv4();
      const mockUser = {
        ...emptyUser,
        ...user,
        churchId,
        isOptimistic: true,
        // placeholder ids
        familyId,
        id: uuidv4(),
      };
      const mockFamily = {
        surname: user.name.last,
        churchId,
        isOptimistic: true,
        members: [mockUser],
        // placeholder id
        id: familyId,
      };

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        return {
          families: [...prev.families, mockFamily],
          users: [...prev.users, mockUser],
        };
      });

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, newChurch, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useCreateFamily;
