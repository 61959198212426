/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { issues } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useSubmitFeedback = ({ _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const issuesKey = issues();

  const createIssue = useCallback(
    ({ issue, file }) => {
      const uploadedFile = file;
      const formData = new FormData();
      const str = JSON.stringify(issue);

      formData.append('file', uploadedFile);
      formData.append('json', str);

      return fetchWithJWT(
        '/api/v1/issue',
        {
          method: 'POST',
          body: formData,
          isMultiPart: true,
        },
        locale.HTTP.ERROR.CREATE_ISSUE
      );
    },
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: createIssue,
    onMutate: _onMutate,
    onError: (err) => {
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: issuesKey });
    },
  });
};

export default useSubmitFeedback;
