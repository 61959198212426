/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory, login } from '../queryKeys';
import useLogin from '../query/useLogin';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useEditUserBirthday = (userId, { _onMutate = () => {} } = {}) => {
  const { data } = useLogin();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();
  const loginKey = login();

  const editUser = useCallback(
    (payload) =>
      fetchWithJWT(
        `/api/v1/user/edit-birthday${objectToQueryParams({ userId })}`,
        {
          method: 'PUT',
          body: payload,
        },
        locale.HTTP.ERROR.UPDATE_USER
      ),
    [userId, fetchWithJWT]
  );

  return useMutation({
    mutationFn: editUser,
    onMutate: async (payload) => {
      _onMutate();

      const mapUpdate = (u) => {
        if (u.id === userId) {
          return {
            ...u,
            ...payload,
            isOptimistic: true,
          };
        }
        return u;
      };

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await Promise.all(
        [
          // editing someone in the directory:
          queryClient.cancelQueries(directoryKey),
          // editing self:
          data.id === userId && queryClient.cancelQueries(loginKey),
        ].filter(Boolean)
      );

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);
      const previousLogin = queryClient.getQueryData(loginKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        return {
          ...prev,
          families: prev.families.map((family) => {
            const found = family.members.some(({ id }) => id === userId);
            if (found) {
              return {
                ...family,
                members: family.members.map(mapUpdate),
              };
            }
            return family;
          }),
          users: prev.users.map(mapUpdate),
        };
      });

      if (data.id === userId) {
        queryClient.setQueryData(loginKey, mapUpdate);
      }

      // Return a context object with the snapshotted value
      return { previousDirectory, previousLogin };
    },
    onError: (err, variables, { previousDirectory, previousLogin }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      queryClient.setQueryData(loginKey, previousLogin);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useEditUserBirthday;
