import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import Modal from './Modal';
import ProgressBar from './ProgressBar';

const Router = () => (
  <BrowserRouter>
    <AppRouter />
    <Modal />
    <ProgressBar />
  </BrowserRouter>
);

export default Router;
