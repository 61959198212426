import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { church } from '../queryKeys';
import { useFetchChurch, config } from '../query/useChurch';
import useTokenIsValid from '../useTokenIsValid';
import { useLogin } from '../query';

const usePrefetchChurch = () => {
  const { data: role } = useLogin((d) => d.role);
  const queryClient = useQueryClient();

  const fetchChurch = useFetchChurch();
  const isValid = useTokenIsValid();

  return useCallback(async () => {
    if (isValid()) {
      await queryClient.prefetchQuery({
        queryKey: church(role),
        queryFn: fetchChurch,
        ...config,
      });
    }
  }, [fetchChurch, queryClient, isValid, role]);
};

export default usePrefetchChurch;
