import { PHONE_LABEL } from '@churchms/shared';
import IconButton from './IconButton';
import { modalTypes } from '../contexts/ModalContext';
import { useModalContext } from '../hooks/context';
import { useLogin } from '../hooks/query';
import { ReactComponent as ShareSVG } from '../icons/share.svg';
import { formatMailtoLink, formatSMSLinkGroup } from '../utils';

const ShareButton = ({ users = [] }) => {
  const { data } = useLogin();
  const { openModal } = useModalContext();

  const usersWithEmail = users.filter(
    ({ email, id }) => email && id !== data.id
  );
  const usersWithSMS = users.filter(
    ({ phoneNumbers, id }) =>
      id !== data.id &&
      phoneNumbers &&
      phoneNumbers.some(({ label }) => label === PHONE_LABEL.CELL)
  );

  const onShare = () => {
    openModal(modalTypes.SHARE_DIRECTORY, {
      mailToLink: formatMailtoLink(usersWithEmail),
      mailToCount: usersWithEmail.length,
      smsLink: formatSMSLinkGroup(usersWithSMS),
      smsCount: usersWithSMS.length,
    });
  };

  const usersToShare = !!usersWithEmail.length || !!usersWithSMS.length;
  return (
    usersToShare && (
      <IconButton onClick={onShare}>
        <ShareSVG />
      </IconButton>
    )
  );
};

export default ShareButton;
