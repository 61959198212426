/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { emptyUser, locale } from '@churchms/shared';
import { v4 as uuidv4 } from 'uuid';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useCreateUser = (familyId, { _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const queryClient = useQueryClient();
  const fetchWithJWT = useFetchWithJWT();
  const directoryKey = directory();

  const createUser = useCallback(
    (user) =>
      fetchWithJWT(
        '/api/v1/user',
        {
          method: 'POST',
          body: { ...user, familyId },
        },
        locale.HTTP.ERROR.USER_CREATE
      ),
    [fetchWithJWT, familyId]
  );

  return useMutation({
    mutationFn: createUser,
    onMutate: async (user) => {
      _onMutate();

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        const withMockId = {
          ...emptyUser,
          ...user,
          isOptimistic: true,
          // placeholder id
          id: uuidv4(),
        };

        return {
          ...prev,
          families: prev.families.map((f) => {
            if (f.id === familyId) {
              return {
                ...f,
                members: [...f.members, withMockId],
              };
            }
            return f;
          }),
          users: [...prev.users, withMockId],
        };
      });

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, user, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useCreateUser;
