import { APP_NAME, ROLE } from '@churchms/shared';
import { ReactComponent as MoreSVG } from '../../icons/settings.svg';
import ProvisionerNav from './ProvisionerNav';
import UserNav from './UserNav';
import './index.scss';
import NavItem from './NavItem';

const MainNav = ({ user }) => {
  return (
    <div className="main-nav">
      <img
        src="/logo192.png"
        alt={`${APP_NAME.LONG} logo`}
        className="main-nav__logo"
      />
      <nav>
        <ul className="main-nav__list">
          {user.role === ROLE.PROVISIONER ? <ProvisionerNav /> : <UserNav />}
          <NavItem to="/more" exact Icon={MoreSVG} label="More" />
        </ul>
      </nav>
    </div>
  );
};

export default MainNav;
