import { useCallback, useState } from 'react';
import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useAddEmail } from '../../../hooks/mutate';

const AddEmail = ({ userId, modalType, closeModal }) => {
  const [email, setEmail] = useState('');
  const { data: isDuplicate } = useDirectory((d) => {
    return d.users.some((u) => u.email === email);
  });
  const { mutate } = useAddEmail(userId, { _onMutate: closeModal });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate(email);
      }
    },
    [modalType, email, mutate]
  );

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: locale.COMMON.SAVE,
      disabled: isDuplicate || !email,
      type: 'submit',
    },
  ];

  const onSetEmail = (e) => setEmail(e.target.value);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.ADD_EMAIL._} />
      <Body>
        <Form.TextField
          label={locale.COMMON.EMAIL}
          type="email"
          required
          value={email}
          onChange={onSetEmail}
        />
        <p>{locale.MODALS.ADD_EMAIL.DESCRIPTION}</p>
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default AddEmail;
