/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useChangeHeadOfHousehold = (familyId, { _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const changeHeadOfHousehold = useCallback(
    (newHeadOfHouseholdId) =>
      fetchWithJWT(
        `/api/v1/family/change-head-of-household${objectToQueryParams({
          familyId,
        })}`,
        {
          method: 'PUT',
          body: { newHeadOfHouseholdId },
        },
        locale.HTTP.ERROR.CHANGE_FAMILY_NAME
      ),
    [fetchWithJWT, familyId]
  );

  return useMutation({
    mutationFn: changeHeadOfHousehold,
    onMutate: async (newHeadOfHouseholdId) => {
      _onMutate();

      const mapNewHead = (prev) => {
        if (prev.id === familyId) {
          return {
            ...prev,
            headOfHousehold: newHeadOfHouseholdId,
          };
        }
        return prev;
      };

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => ({
        ...prev,
        families: prev.families.map(mapNewHead),
      }));

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, newHeadOfHouseholdId, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useChangeHeadOfHousehold;
