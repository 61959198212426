/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { useHistory } from 'react-router-dom';
import { directory } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useDeleteFamily = ({ _onMutate = () => {} } = {}) => {
  const history = useHistory();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();

  const removeFamily = useCallback(
    (familyId) =>
      fetchWithJWT(
        `/api/v1/family${objectToQueryParams({ familyId })}`,
        {
          method: 'DELETE',
        },
        locale.HTTP.ERROR.USER_DELETE
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: removeFamily,
    onMutate: async (familyId) => {
      _onMutate();
      history.push('/directory');
      const filterFamily = (f) => f.id !== familyId;
      const filterUser = (u) => u.familyId !== familyId;

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await Promise.all(
        [queryClient.cancelQueries(directoryKey)].filter(Boolean)
      );

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => {
        return {
          families: prev.families.filter(filterFamily),
          users: prev.users.filter(filterUser),
        };
      });

      // Return a context object with the snapshotted value
      return { previousDirectory };
    },
    onError: (err, variables, { previousDirectory }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useDeleteFamily;
