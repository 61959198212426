import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useDeleteProfilePicture } from '../../../hooks/mutate';

const DeleteProfilePicture = ({ closeModal, id, familyId }) => {
  const { mutate } = useDeleteProfilePicture(id ? 'user' : 'family', {
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.NO,
    },
    {
      onClick: () => mutate(id || familyId),
      primary: true,
      children: locale.COMMON.YES,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.DELETE_PROFILE_PICTURE._} />
      <Body>{locale.COMMON.ARE_YOU_SURE}</Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeleteProfilePicture;
