import { useReducer, useCallback, createContext } from 'react';
import { actionTypes, initialState, reducer } from './reducer';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = useCallback(
    (modalType, content) =>
      dispatch({
        type: actionTypes.OPEN_MODAL,
        modalType,
        content,
      }),
    []
  );

  const closeModal = useCallback(
    () =>
      dispatch({
        type: actionTypes.CLOSE_MODAL,
      }),
    []
  );

  const value = { closeModal, openModal, ...state };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
