/* istanbul ignore file */

const locale = require('./locale');
const functions = require('./functions');
const constants = require('./constants');

module.exports = {
  locale,
  ...constants,
  ...functions,
};
