// convert date object to Date String for date picker
// ie. YYYY-MM-DD

const toDateString = (date) => {
  let _date;

  if (!date) return '';

  if (typeof date === 'string') {
    _date = new Date(date);
  }

  if (date instanceof Date) {
    _date = date;
  }

  if (`${_date}` === 'Invalid Date') {
    return '';
  }

  const year = _date.getFullYear();
  const month = (_date.getMonth() + 1).toString().padStart(2, '0');
  const day = _date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

module.exports = toDateString;
