import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { directory } from '../queryKeys';
import { useFetchDirectory, config } from '../query/useDirectory';
import useTokenIsValid from '../useTokenIsValid';

const usePrefetchDirectory = () => {
  const queryClient = useQueryClient();

  const fetchDirectory = useFetchDirectory();
  const isValid = useTokenIsValid();

  return useCallback(async () => {
    if (isValid()) {
      await queryClient.prefetchQuery({
        queryKey: directory(),
        queryFn: fetchDirectory,
        ...config,
      });
    }
  }, [fetchDirectory, queryClient, isValid]);
};

export default usePrefetchDirectory;
