import { useCallback, useState, useMemo } from 'react';
import { MIN_PASSWORD_LENGTH, locale } from '@churchms/shared';

const useNewPasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onOldPassword = useCallback((e) => setOldPassword(e.target.value), []);
  const onNewPassword = useCallback((e) => setNewPassword(e.target.value), []);
  const onConfirmPassword = useCallback(
    (e) => setConfirmPassword(e.target.value),
    []
  );

  const passwordsMatch = newPassword === confirmPassword;
  const passwordEntered = !!newPassword;
  const passwordIsLongEnough = newPassword.length >= MIN_PASSWORD_LENGTH;
  const invalidPassword =
    !passwordsMatch || !passwordEntered || !passwordIsLongEnough;

  const error = useMemo(() => {
    if (invalidPassword && passwordEntered) {
      if (!passwordsMatch) {
        return new Error(locale.MODALS.CHANGE_PASSWORD.MUST_MATCH);
      }
      if (!passwordIsLongEnough) {
        return new Error(locale.HTTP.ERROR.PASSWORD_TOO_SHORT);
      }
    }
    return null;
  }, [invalidPassword, passwordEntered, passwordsMatch, passwordIsLongEnough]);

  return {
    oldPassword,
    onOldPassword,
    newPassword,
    onNewPassword,
    confirmPassword,
    onConfirmPassword,
    error,
  };
};

export default useNewPasswordForm;
