import { MiddleTruncate, IconButton } from '../../../../components';
import { parseEntities } from 'parse-entities';
import styles from './styles.module.scss';

const ListItem = ({ url, title, onToggleFeedUrl, Icon }) => {
  const getUrlWithoutProtocol = (_url) => {
    return _url.replace(/http(s|):\/\/(www.|)/, '');
  };

  return (
    <li key={url}>
      <MiddleTruncate>
        {title ? parseEntities(title) : getUrlWithoutProtocol(url)}
      </MiddleTruncate>
      <IconButton
        className={styles.toggleButton}
        onClick={() => onToggleFeedUrl(url)}
      >
        <Icon />
      </IconButton>
    </li>
  );
};

export default ListItem;
