import ReactTimeAgo from 'react-time-ago';
import { useBlogMetadata } from '../../hooks/query';
import styles from './styles.module.scss';

const Article = ({
  title,
  url,
  contentSnippet,
  isoDate,
  pubDate,
  feedUrl,
  featuredImage,
  creator,
  isLast,
}) => {
  const date = new Date(pubDate || isoDate);
  const { data: blogMetadata } = useBlogMetadata((d) => d?.[feedUrl]);
  const { image, sourceBlogTitle } = blogMetadata || {};
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.preview}${isLast ? ` ${styles.lastArticle}` : ''}`}
    >
      <div className={styles.container}>
        {featuredImage && (
          <div
            className={styles.featuredImage}
            style={{ background: `url(${featuredImage}) center/cover` }}
          />
        )}
        <div
          className={styles.text}
          style={{ position: featuredImage ? 'absolute' : '' }}
        >
          {image?.url && (
            <img
              className={styles.logo}
              src={image.url}
              alt={`${sourceBlogTitle} logo`}
            />
          )}
          <div>
            <h2 className={styles.articleTitle}>{title}</h2>
            <p>
              <b>{sourceBlogTitle}</b>
              {creator && ' by '}
              {creator && <b>{creator}</b>}
            </p>
            <p>
              <ReactTimeAgo date={date} locale="en-US" />
            </p>
            <p className={styles.contentSnippet}>{contentSnippet}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Article;
