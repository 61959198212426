import { useState } from 'react';
import useDebounce from './useDebounce';

const useDebouncedSearch = (v = '') => {
  const [search, setSearch] = useState(v);

  const [debounce, setDebounce] = useDebounce(search);

  return [search, setSearch, debounce, setDebounce];
};

export default useDebouncedSearch;
