import { stringToHslColor, getInitials, getFullName } from '../../utils';
import { ReactComponent as AddProfilePictureSVG } from '../../icons/add_a_photo.svg';
import { useModalContext } from '../../hooks/context';
import { useProfilePicture } from '../../hooks/query';
import { modalTypes } from '../../contexts/ModalContext';

import styles from './styles.module.scss';
import Svg from './Svg';

const Avatar = ({
  name = '',
  Icon,
  size = 'medium',
  isEditing = false,
  id,
  familyId,
}) => {
  const { openModal } = useModalContext();
  const { data: img } = useProfilePicture(id || familyId);

  const baseClassName = `${styles.avatar} avatar--${size}`;
  const fullName = getFullName(name);
  const initials = getInitials(fullName);

  const outerBg = fullName && stringToHslColor(fullName, 50, 65);
  const imgBg = fullName && stringToHslColor(fullName, 50, 50);

  const onClick = () => {
    if (isEditing) {
      openModal(modalTypes.ADD_PROFILE_PICTURE, { id, familyId });
    }
  };

  return (
    <div
      className={`${baseClassName}${!initials ? ' avatar--no-value' : ''}`}
      role={isEditing ? 'button' : ''}
      onClick={onClick}
    >
      <Svg
        outerBg={outerBg}
        imgBg={imgBg}
        initials={initials}
        img={img}
        Icon={isEditing ? AddProfilePictureSVG : Icon}
        isEditing={isEditing}
      />
    </div>
  );
};

export default Avatar;
