const matchesPhone = (search, phoneNumbers) => {
  if (isNaN(search) || !search) {
    return true;
  }

  return phoneNumbers.some(({ value }) =>
    value.replace(/-/g, '').includes(search)
  );
};

export default matchesPhone;
