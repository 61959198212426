import { useEffect, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { APP_NAME, ROLE } from '@churchms/shared';
import { useCanShowBlog } from '../hooks';
import { useLogin } from '../hooks/query';
import { LoadingOrError, Spinner } from '../components';
import ProtectedRoute from './ProtectedRoute';
import Layout from './Layout';
import EnvironmentBanner from './EnvironmentBanner';
// Pages
import ForgotPassword from '../pages/ForgotPassword';
import RecoverAccount from '../pages/RecoverAccount';
import NotFound from '../pages/NotFound';
import Churches from '../pages/Churches';
import Directory from '../pages/Directory';
import More from '../pages/More';
import FamilyDetails from '../pages/FamilyDetails';
import UserDetails from '../pages/UserDetails';
import LoggedOutRoute from './LoggedOutRoute';
import Homepage from '../pages/Homepage';
import Blog from '../pages/Blog';
import Login from '../pages/Login';
import Marketing from '../pages/Marketing';
import Market from '../pages/Market';

const AppRouter = () => {
  const { data, isPlaceholderData, error } = useLogin();
  const canShowBlog = useCanShowBlog();

  useEffect(() => {
    if (process.env.REACT_APP_VERSION) {
      console.log('App version:', process.env.REACT_APP_VERSION);
    }
  }, []);

  const isProvisioner = data?.role === ROLE.PROVISIONER;
  // const isAdmin = data?.role === ROLE.ADMIN;

  return (
    <LoadingOrError isLoading={isPlaceholderData} error={error}>
      <DocumentTitle title={APP_NAME.SHORT} />
      <EnvironmentBanner />
      <Suspense fallback={<Spinner isLoading />}>
        <Switch>
          {/* LOGGED OUT */}
          {/*
          1. Logged out users can ONLY see these
          2. Logged in users can view these also,
             but they won't see the navigation menu
        */}
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <LoggedOutRoute path="/login" exact>
            <Login redirectTo="/" />
          </LoggedOutRoute>
          {!data?.id && (
            <LoggedOutRoute path="/" exact>
              <Marketing />
            </LoggedOutRoute>
          )}
          <LoggedOutRoute path="/recover-account" exact>
            <RecoverAccount />
          </LoggedOutRoute>
          <LoggedOutRoute path="/create-account" exact>
            <RecoverAccount isCreating />
          </LoggedOutRoute>

          <Route>
            <Layout user={data}>
              <Suspense fallback={<Spinner isLoading />}>
                <Switch>
                  {/* HOME */}
                  <ProtectedRoute path="/" exact>
                    {isProvisioner ? <div>Provisioner</div> : <Homepage />}
                  </ProtectedRoute>

                  {canShowBlog && (
                    <ProtectedRoute path="/blog" exact canView={!isProvisioner}>
                      <Blog />
                    </ProtectedRoute>
                  )}

                  {/* PROVISIONER */}
                  <ProtectedRoute
                    path="/churches"
                    exact
                    canView={isProvisioner}
                  >
                    <Churches />
                  </ProtectedRoute>

                  {/* USER */}
                  <ProtectedRoute path="/market" canView={!isProvisioner}>
                    <Market />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/directory/:familyId/:userId"
                    exact
                    canView={!isProvisioner}
                  >
                    <UserDetails />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/directory"
                    exact
                    canView={!isProvisioner}
                  >
                    <Directory />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/directory/:familyId"
                    exact
                    canView={!isProvisioner}
                  >
                    <FamilyDetails />
                  </ProtectedRoute>

                  {/* Both provisioners and users */}
                  <ProtectedRoute path="/more" exact>
                    <More />
                  </ProtectedRoute>

                  {/* 404 */}
                  <ProtectedRoute>
                    <NotFound />
                  </ProtectedRoute>
                </Switch>
              </Suspense>
            </Layout>
          </Route>
        </Switch>
      </Suspense>
    </LoadingOrError>
  );
};

export default AppRouter;
