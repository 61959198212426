/* istanbul ignore file */

import { useCallback } from 'react';
import { useQueries } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { feed } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useTokenIsValid } from '..';

const useFeeds = (...urls) => {
  const fetchWithJWT = useFetchWithJWT();
  const isValid = useTokenIsValid();

  const fetchFeed = useCallback(
    ({ queryKey }) => {
      const [, _url] = queryKey;
      return fetchWithJWT(
        `/api/v1/rss/feeds?url=${_url}`,
        {},
        locale.HTTP.ERROR.GET_RSS
      );
    },
    [fetchWithJWT]
  );

  return useQueries({
    queries: urls.map((u) => ({
      queryKey: feed(u),
      queryFn: fetchFeed,
      staleTime: HOUR,
      enabled: !!u && isValid(),
      retry: false,
    })),
  });
};

export default useFeeds;
