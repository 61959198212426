import { useReducer, useCallback } from 'react';
import {
  emptyUser,
  isAdult,
  formatDatePickerDate,
  isValidBirthday,
} from '@churchms/shared';

const actionTypes = {
  SET_FIRST_NAME: 'SET_FIRST_NAME',
  SET_LAST_NAME: 'SET_LAST_NAME',
  SET_EMAIL: 'SET_EMAIL',
  SET_HIDDEN: 'SET_HIDDEN',
  SET_BIRTHDAY: 'SET_BIRTHDAY',
  SET_IS_ADULT: 'SET_IS_ADULT',
  SET_SEX: 'SET_SEX',
};

const initialState = { ...emptyUser, sex: '' };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FIRST_NAME:
      return {
        ...state,
        name: {
          ...state.name,
          first: action.first,
        },
      };
    case actionTypes.SET_LAST_NAME:
      return {
        ...state,
        name: {
          ...state.name,
          last: action.last,
        },
      };
    case actionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case actionTypes.SET_SEX:
      return {
        ...state,
        sex: action.sex,
      };
    case actionTypes.SET_HIDDEN:
      return {
        ...state,
        isHidden: action.isHidden,
      };
    case actionTypes.SET_BIRTHDAY:
      return {
        ...state,
        birthday: action.birthday,
        isAdult: isAdult(formatDatePickerDate(action.birthday)),
      };
    case actionTypes.SET_IS_ADULT:
      return {
        ...state,
        isAdult: action.isAdult,
        birthday: isValidBirthday(action.isAdult, state.birthday)
          ? state.birthday
          : '',
      };
    default:
      return state;
  }
};

const useCreateUserForm = (init = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => ({
    ...initialState,
    ...init,
  }));

  const onFirstName = useCallback(
    (e) =>
      dispatch({
        type: actionTypes.SET_FIRST_NAME,
        first: e.target.value,
      }),
    []
  );

  const onLastName = useCallback(
    (e) =>
      dispatch({
        type: actionTypes.SET_LAST_NAME,
        last: e.target.value,
      }),
    []
  );

  const onEmail = useCallback(
    (e) =>
      dispatch({
        type: actionTypes.SET_EMAIL,
        email: e.target.value,
      }),
    []
  );

  const onSex = useCallback(
    ({ value }) =>
      dispatch({
        type: actionTypes.SET_SEX,
        sex: value,
      }),
    []
  );

  const onHideUser = useCallback(
    (e) =>
      dispatch({
        type: actionTypes.SET_HIDDEN,
        isHidden: e.target.checked,
      }),
    []
  );

  const onBirthday = useCallback(
    (e) =>
      dispatch({
        type: actionTypes.SET_BIRTHDAY,
        birthday: e.target.value,
      }),
    []
  );

  const onIsAdult = useCallback(
    (e) =>
      dispatch({
        type: actionTypes.SET_IS_ADULT,
        isAdult: e.target.checked,
      }),
    []
  );

  return {
    state,
    onFirstName,
    onLastName,
    onEmail,
    onHideUser,
    onBirthday,
    onIsAdult,
    onSex,
  };
};

export default useCreateUserForm;
