import { useState } from 'react';
import { locale } from '@churchms/shared';
import Address, { addressOptions } from './Address';
import { useCreateAddressForm } from '../../../hooks';
import { useAddAddress } from '../../../hooks/mutate';

const AddAddress = ({ closeModal, userId, modalType }) => {
  const addressProps = useCreateAddressForm();
  const [label, setLabel] = useState(addressOptions[0]);
  const { mutate } = useAddAddress(userId, { _onMutate: closeModal });

  return (
    <Address
      formId={modalType}
      mutate={mutate}
      title={locale.MODALS.CREATE_ADDRESS._}
      closeModal={closeModal}
      userId={userId}
      label={label}
      setLabel={setLabel}
      {...addressProps}
    />
  );
};

export default AddAddress;
