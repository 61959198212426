import styles from './styles.module.scss';

const IconButton = ({
  children,
  className,
  badge,
  badgeColor = 'red',
  type = 'button',
  href,
  alt,
  ...rest
}) => {
  const content = (
    <>
      {!!badge && (
        <span className={`${styles.badge} ${styles[badgeColor]}`}>{badge}</span>
      )}
      {children}
    </>
  );

  if (type === 'link') {
    return (
      <a
        className={`${styles.iconButton}${className ? ` ${className}` : ''}`}
        href={href}
        alt={alt}
        {...rest}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      className={`${styles.iconButton}${className ? ` ${className}` : ''}`}
      {...rest}
    >
      {content}
    </button>
  );
};

export default IconButton;
