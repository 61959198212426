import { useCallback, useState } from 'react';
import { locale } from '@churchms/shared';
import Header from '../../Header';
import Body from '../../Body';
import Footer from '../../Footer';
import { Form } from '../../../../components';
import FamilyList from '../components/FamilyList';
import { useSortUsersAndFamilies } from '../../../../hooks';
import { useDirectory } from '../../../../hooks/query';
import styles from './styles.module.scss';

const { DIRECTORY_SEARCH } = locale.MODALS;

const DirectorySearch = ({
  closeModal,
  search,
  setSearch,
  selected,
  setSelected,
}) => {
  const [state, setState] = useState(search);
  const [selectedState, setSelectedState] = useState(selected);

  const sortFn = useSortUsersAndFamilies();

  const { data: allMembers } = useDirectory((d) => d.users.sort(sortFn));

  const applySearch = useCallback(() => {
    setSearch(state);
    setSelected(selectedState);
  }, [selectedState, setSearch, setSelected, state]);

  const footerBtns = [
    {
      children: locale.COMMON.RESET,
      onClick: () => {
        setState('');
        setSelectedState({});
      },
    },
    {
      primary: true,
      onClick: () => {
        applySearch();
        closeModal();
      },
      children: locale.COMMON.APPLY,
    },
  ];

  const onSearch = (e) => {
    setState(e.target.value);
  };

  const onClick = (id) =>
    setSelectedState((prev) => ({
      ...prev,
      [id]:
        prev[id] === undefined ? true : prev[id] === true ? false : undefined,
    }));

  return (
    <>
      <Header title={DIRECTORY_SEARCH._} />
      <Body>
        <Form.TextField
          label={locale.COMMON.SEARCH}
          value={state}
          onChange={onSearch}
          fullWidth
        />
        <p className={styles.tip}>{DIRECTORY_SEARCH.SEARCH_TIP}</p>
        <FamilyList
          className={styles.userList}
          members={allMembers}
          onClick={onClick}
          selected={selectedState}
          size="x-small"
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DirectorySearch;
