import { locale, localeFormat } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useDirectory } from '../../../hooks/query';
import { useHideUser } from '../../../hooks/mutate';

const HideUser = ({ closeModal, id }) => {
  const { data: user } = useDirectory((d) => d.users.find((u) => u.id === id));
  const { mutate } = useHideUser(id, user.familyId, {
    _onMutate: closeModal,
  });

  const fullName = `${user.name.first} ${user.name.last}`;
  const isHidden = user.isHidden;

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate(!isHidden),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  const LOCALE_OBJECT = isHidden
    ? locale.MODALS.SHOW_USER
    : locale.MODALS.HIDE_USER;

  return (
    <>
      <Header title={LOCALE_OBJECT._} />
      <Body>{localeFormat(LOCALE_OBJECT.CONFIRM, fullName)}</Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default HideUser;
