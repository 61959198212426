const objectToQueryParams = (obj = {}) => {
  const query = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) {
      query.append(key, value);
    }
  });

  const res = query.toString();

  if (res) {
    return `?${res}`;
  }

  return '';
};

module.exports = objectToQueryParams;
