import { useRef } from 'react';
import { ReactComponent as UpSVG } from '../../icons/arrow_drop_down.svg';
import styles from './styles.module.scss';

const StickyHeader = ({ title }) => {
  const ref = useRef();
  const scrollIntoView = () => {
    if (ref.current) {
      ref.current.nextSibling.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <button className={styles.stickyHeader} ref={ref} onClick={scrollIntoView}>
      <div>
        {title} <UpSVG />
      </div>
    </button>
  );
};

export default StickyHeader;
