import { ROLE } from '@churchms/shared';
import useLogin from './query/useLogin';
import useDirectory from './query/useDirectory';

const usePerms = ({ targetUserId, targetFamilyId } = {}) => {
  const { data: user } = useLogin();
  const { data: family } = useDirectory(
    (d) => targetFamilyId && d.families.find(({ id }) => id === targetFamilyId)
  );
  const isAdmin = user.role === ROLE.ADMIN;
  const isSelf = targetUserId && targetUserId === user.id;
  const isHeadOfHousehold =
    targetFamilyId && family && family.headOfHousehold === user.id;
  const isOwnFamily = targetFamilyId && family && family.id === user.familyId;

  return {
    isAdmin,
    isSelf,
    isHeadOfHousehold,
    isOwnFamily,
  };
};

export default usePerms;
