import { useCallback } from 'react';
import { ReactComponent as LocationSVG } from '../../../icons/location_pin.svg';
import ButtonAsLink from '../../ButtonAsLink';
import { usePerms } from '../../../hooks';
import { useEditContext, useModalContext } from '../../../hooks/context';
import { modalTypes } from '../../../contexts/ModalContext';
import RemovableItem from './RemovableItem';
import Content from './Content';
import styles from './styles.module.scss';
import Label from '../../Label';

const Address = ({
  label,
  street1,
  street2,
  city,
  state,
  zipCode,
  country,
  userId,
  id,
  familyId,
  isOptimistic = false,
}) => {
  const { isSelf, isAdmin, isHeadOfHousehold } = usePerms({
    targetUserId: userId,
    targetFamilyId: familyId,
  });
  const { isEditing } = useEditContext();
  const canRemove = isSelf || isAdmin || isHeadOfHousehold;
  const { openModal } = useModalContext();
  const onDelete = () =>
    openModal(modalTypes.DELETE_ADDRESS, {
      userId,
      addressId: id,
      label,
      street1,
      street2,
      city,
      state,
      zipCode,
      country,
    });
  const onEdit = useCallback(() => {
    openModal(modalTypes.EDIT_ADDRESS, {
      label,
      street1,
      street2,
      city,
      state,
      zipCode,
      country,
      userId,
      addressId: id,
    });
  }, [
    city,
    country,
    id,
    label,
    openModal,
    state,
    street1,
    street2,
    userId,
    zipCode,
  ]);

  const content = (
    <Content name={label} Icon={LocationSVG}>
      <Label>{label}</Label>
      <p>{street1}</p>
      {street2 && <p>{street2}</p>}
      <p>
        {city}, {state}, {zipCode}
      </p>
      <p>{country}</p>
    </Content>
  );

  if (isEditing && canRemove) {
    return (
      <RemovableItem isEditing onRemove={onDelete} disabled={isOptimistic}>
        <ButtonAsLink
          disabled={isOptimistic}
          className={styles.clickableFlex}
          onClick={onEdit}
        >
          {content}
        </ButtonAsLink>
      </RemovableItem>
    );
  }

  return <div className={styles.basicFlex}>{content}</div>;
};

export default Address;
