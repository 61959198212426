import { useRef, useCallback, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { locale, localeFormat } from '@churchms/shared';
import Header from '../../Header';
import Body from '../../Body';
import Footer from '../../Footer';
import { Form } from '../../../../components';
import { useAddProfilePicture } from '../../../../hooks/mutate';

import styles from './styles.module.scss';

const maxFileSize = 1024 * 1024 * 10;

const AddProfilePicture = ({ closeModal, id, familyId }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState('');
  const [scale, setScale] = useState(1);
  const ref = useRef();
  const { mutate, isPending } = useAddProfilePicture(
    id || familyId,
    !!id ? 'user' : 'family',
    { _onMutate: closeModal }
  );

  const onScale = (e) => {
    setScale(parseFloat(e.target.value));
  };

  const onSelectFile = (e) => {
    setError('');
    if (e.target.files?.length) {
      const isTooLarge = e.target.files[0].size > maxFileSize;

      if (isTooLarge) {
        setError(localeFormat(locale.HTTP.ERROR.FILE_TOO_LARGE, '10 MB'));
        e.preventDefault();
        return;
      }

      setSelectedFile(e.target.files[0]);
    }
  };

  const onSave = useCallback(
    (e) => {
      e.preventDefault();

      if (ref.current) {
        const canvas = ref.current.getImage();
        if (selectedFile) {
          canvas.toBlob((blob) => {
            const extension = blob.type.split('/')[1];
            // URL.createObjectURL(object)
            const file = new File([blob], `file.${extension}`, {
              type: blob.type,
            });

            if (file.size > maxFileSize) {
              setError(localeFormat(locale.HTTP.ERROR.FILE_TOO_LARGE, '10 MB'));
            } else {
              mutate(
                { file, blob: URL.createObjectURL(blob) },
                { onSuccess: closeModal }
              );
            }
          });
        }
      }
    },
    [closeModal, mutate, selectedFile]
  );

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: locale.COMMON.SAVE,
    },
  ];

  return (
    <Form.Wrapper isLoading={isPending}>
      <Header title={locale.MODALS.ADD_PROFILE_PICTURE._} />
      <Body>
        <Form.SingleFile
          label={locale.MODALS.ADD_PROFILE_PICTURE.PROFILE_PICTURE}
          hideLabel
          required
          onChange={onSelectFile}
          accept="image/png, image/jpeg"
        />
        {error && <span>{error}</span>}
        <AvatarEditor
          className={styles.cropper}
          ref={ref}
          image={selectedFile}
          scale={scale}
          // color={[0, 0, 0, 0.5]}
        />
        <Form.Range
          label={locale.MODALS.ADD_PROFILE_PICTURE.SCALE}
          fullWidth
          min={1}
          value={scale}
          max={10}
          step={0.1}
          onChange={onScale}
          disabled={!selectedFile}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default AddProfilePicture;
