/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { church, blog, blogMetadata } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';
import { useLogin } from '../query';

const useEditChurch = ({ _onMutate = () => {} } = {}) => {
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const { data: role } = useLogin((d) => d.role);
  const churchKey = church(role);

  const mutateChurch = useCallback(
    (payload) =>
      fetchWithJWT(
        '/api/v1/church',
        {
          method: 'PUT',
          body: payload,
        },
        locale.HTTP.ERROR.EDIT_CHURCH
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: mutateChurch,
    onMutate: async (payload) => {
      _onMutate();
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(churchKey);

      // Snapshot the previous value
      const previousChurch = queryClient.getQueryData(churchKey);

      // Optimistically update to the new value
      queryClient.setQueryData(churchKey, (prev) => {
        return {
          ...prev,
          ...payload,
          homepage: {
            ...prev.homepage,
            ...(payload.homepage ? payload.homepage : {}),
          },
        };
      });

      // Return a context object with the snapshotted value
      return { previousChurch };
    },
    onError: (err, payload, { previousChurch }) => {
      queryClient.setQueryData(churchKey, previousChurch);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries({ queryKey: churchKey });
      queryClient.invalidateQueries({ queryKey: blog() });
      queryClient.invalidateQueries({ queryKey: blogMetadata() });
    },
  });
};

export default useEditChurch;
