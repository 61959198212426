import Church from './Church';
import User from './User';
import Family from './Family';
import AddPhoneNumber from './AddPhoneNumber';
import PhoneNumber from './PhoneNumber';
import Birthday from './Birthday';
import Email from './Email';
import AddEmail from './AddEmail';
import AddAddress from './AddAddress';
import Address from './Address';

const GridItem = {
  Church,
  User,
  Family,
  AddPhoneNumber,
  PhoneNumber,
  Birthday,
  Email,
  AddEmail,
  AddAddress,
  Address,
};

export default GridItem;
