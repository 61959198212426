import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useDeleteListing } from '../../../hooks/mutate';

const DeleteListing = ({ closeModal, id }) => {
  const { mutate } = useDeleteListing({
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate(id),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  return (
    <>
      <Header title={locale.MODALS.DELETE_LISTING._} />
      <Body>{locale.MODALS.DELETE_LISTING.CONFIRM}</Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeleteListing;
