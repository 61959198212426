import DeleteUser from './DeleteUser';
import DeleteFamily from './DeleteFamily';
import HideUser from './HideUser';
import ChangeName from './ChangeName';
import ChangeHeadOfHousehold from './ChangeHeadOfHousehold';
import ChangeRole from './ChangeRole';
import MoveUser from './MoveUser';
import AddProfilePicture from './AddProfilePicture';
import DeleteProfilePicture from './DeleteProfilePicture';
import DeleteListing from './DeleteListing';
import EditListing from './EditListing';

const ContextMenuItem = {
  DeleteUser,
  DeleteFamily,
  HideUser,
  ChangeName,
  ChangeHeadOfHousehold,
  ChangeRole,
  MoveUser,
  AddProfilePicture,
  DeleteProfilePicture,
  DeleteListing,
  EditListing,
};

export default ContextMenuItem;
