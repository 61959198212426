/* istanbul ignore file */

const ROLE = require('./role');
const APP_NAME = {
  SHORT: 'ChurchMS',
  LONG: 'Church Management System',
};
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const jwtExpiresInSeconds = 600;
const emptyUser = require('./emptyUser');
const phonePattern = require('./phonePattern');
const emptyAddress = require('./emptyAddress');
const PHONE_LABEL = require('./phoneLabel');
const ADDRESS_LABEL = require('./addressLabel');
const MIN_PASSWORD_LENGTH = 8;
const LISTING_TYPE = require('./listingType');

module.exports = {
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  jwtExpiresInSeconds,
  APP_NAME,
  ROLE,
  emptyUser,
  phonePattern,
  emptyAddress,
  PHONE_LABEL,
  ADDRESS_LABEL,
  MIN_PASSWORD_LENGTH,
  LISTING_TYPE,
};
