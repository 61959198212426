const modalTypes = {
  CREATE_CHURCH: 'create-church',
  CONFIRM_DELETE_CHURCH: 'confirm-delete-church',
  ADD_PHONE: 'add-phone',
  EDIT_PHONE: 'edit-phone',
  DELETE_PHONE: 'delete-phone',
  ADD_EMAIL: 'add-email',
  CHANGE_EMAIL: 'change-email',
  CONFIRM_NEW_EMAIL: 'confirm-new-email',
  CREATE_USER: 'create-user',
  DELETE_USER: 'delete-user',
  CREATE_FAMILY: 'create-family',
  DELETE_FAMILY: 'delete-family',
  EDIT_BIRTHDAY: 'edit-birthday',
  DELETE_BIRTHDAY: 'delete-birthday',
  CREATE_ADDRESS: 'create-address',
  EDIT_ADDRESS: 'edit-address',
  DELETE_ADDRESS: 'delete-address',
  HIDE_USER: 'hide-user',
  CHANGE_NAME: 'change-name',
  CHANGE_FAMILY_NAME: 'change-family-name',
  FEEDBACK: 'feedback',
  CHANGE_HEAD_OF_HOUSEHOLD: 'change-head-of-household',
  CHANGE_ROLE: 'change-role',
  MOVE_USER: 'move-user',
  DIRECTORY_SEARCH: 'directory-search',
  CHANGE_PASSWORD: 'change-password',
  ADD_PROFILE_PICTURE: 'add-profile-picture',
  DELETE_PROFILE_PICTURE: 'delete-profile-picture',
  CHANGE_BLOG_FEEDS: 'change-blog-feeds',
  EDIT_CHURCH: 'edit-church',
  SHARE_DIRECTORY: 'share-directory',
  CREATE_LISTING: 'create-listing',
  DELETE_LISTING: 'delete-listing',
  EDIT_LISTING: 'edit-listing',
  MARKET_SEARCH: 'market-search',
  ERROR: 'error',
};

export default modalTypes;
