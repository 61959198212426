import styles from './styles.module.scss';

const Fieldset = ({ legend = '', children }) => (
  <fieldset className={styles.fieldset}>
    {legend && <legend>{legend}</legend>}
    {children}
  </fieldset>
);

export default Fieldset;
