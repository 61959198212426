import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useMoveUser } from '../../../hooks/mutate';
import FamilyList from './components/FamilyList';

const MoveUser = ({ closeModal, modalType, id }) => {
  const { data: directory } = useDirectory();
  const targetUser = useMemo(
    () => directory.users.find((u) => u.id === id),
    [directory.users, id]
  );
  const currentFamily = useMemo(
    () => directory.families.find((f) => f.id === targetUser.familyId),
    [directory.families, targetUser.familyId]
  );

  const familyOptions = useMemo(() => {
    let options = directory.families
      .filter((f) => f.id !== targetUser.familyId)
      .map(({ surname, id, headOfHousehold, members }) => {
        const found = members.find((u) => u.id === headOfHousehold);

        return {
          key: id,
          value: id,
          surname,
          children: `${surname}${found ? `, ${found.name.first}` : ''}`,
        };
      })
      .sort(({ children: a }, { children: b }) => a.localeCompare(b));

    // If they are a family one don't let them create a new family
    if (currentFamily.members.length > 1) {
      options.unshift({
        key: uuidv4(),
        value: '',
        children: locale.MODALS.MOVE_USER.NEW_FAMILY,
      });
    }

    return options;
  }, [currentFamily.members.length, directory.families, targetUser.familyId]);

  const [family, setFamily] = useState(familyOptions[0]);
  const [shouldUpdateLastName, setShouldUpdateLastName] = useState(false);

  const { mutate, isPending } = useMoveUser(id, targetUser.familyId, {
    _onMutate: closeModal,
    _onSuccess: closeModal,
  });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({
          familyId: family.value,
          surname: family.surname,
          shouldUpdateLastName: !!family.value && shouldUpdateLastName,
        });
      }
    },
    [modalType, mutate, family, shouldUpdateLastName]
  );

  const footerBtns = useMemo(() => {
    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        type: 'submit',
      },
    ];
  }, [onSave]);

  const futureFamily = useMemo(() => {
    const optimisticUser = {
      ...targetUser,
      name: {
        ...targetUser.name,
        last: shouldUpdateLastName ? family.surname : targetUser.name.last,
      },
      isOptimistic: true,
    };

    if (!family.value)
      return { members: [optimisticUser], headOfHousehold: optimisticUser.id };

    const found = directory.families.find(({ id }) => id === family.value);
    return {
      ...found,
      members: [...found.members, optimisticUser],
    };
  }, [
    directory.families,
    family.surname,
    family.value,
    shouldUpdateLastName,
    targetUser,
  ]);

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType} isLoading={isPending}>
      <Header title={locale.MODALS.MOVE_USER._} />
      <Body>
        <Form.Dropdown
          label={locale.MODALS.MOVE_USER.NEW_FAMILY}
          selected={family}
          options={familyOptions}
          onSelect={setFamily}
          required
        />
        {!!family.value && (
          <Form.Checkbox
            label={locale.MODALS.MOVE_USER.UPDATE_LAST_NAME}
            checked={shouldUpdateLastName}
            onChange={(e) => setShouldUpdateLastName(e.target.checked)}
          />
        )}
        <FamilyList {...futureFamily} />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default MoveUser;
