import { Route, Redirect } from 'react-router-dom';
import { useLogin } from '../hooks/query';

// If the user is logged in, they should be redirected away from this route
const LoggedOutRoute = (props) => {
  const { data: isLoggedIn } = useLogin((d) => !!d.id);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

export default LoggedOutRoute;
