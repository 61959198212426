import styles from './styles.module.scss';

const Button = ({
  className,
  primary = false,
  secondary = false,
  type = 'button',
  ...props
}) => {
  let _className = styles.button;

  if (className) {
    _className += ` ${className}`;
  }

  if (primary) {
    _className += ` ${styles.primary}`;
  }

  if (secondary) {
    _className += ` ${styles.secondary}`;
  }

  return <button className={_className} type={type} {...props} />;
};

export default Button;
