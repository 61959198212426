import { Button } from '../../components';
import { useModalContext } from '../../hooks/context';
import styles from './styles.module.scss';

const Footer = ({ footerBtns = [] }) => {
  const { closeModal } = useModalContext();

  return (
    <div className={styles.footer}>
      {footerBtns.map((btn) => (
        <Button
          key={btn.children}
          onClick={() => {
            if (btn.onClick) {
              btn.onClick();
            }
            closeModal();
          }}
          {...btn}
        />
      ))}
    </div>
  );
};

export default Footer;
