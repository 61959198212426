import Spinner from './Spinner';

const LoadingOrError = ({
  isLoading,
  error,
  showChildrenWhileLoading = false,
  errorClassName = '',
  size,
  children,
}) => {
  if (isLoading)
    return (
      <Spinner size={size} isLoading>
        {showChildrenWhileLoading && children}
      </Spinner>
    );

  if (error)
    return (
      <div className={`error-msg${errorClassName ? ` ${errorClassName}` : ''}`}>
        {error.message || error}
      </div>
    );

  return children;
};

export default LoadingOrError;
