import { Route } from 'react-router-dom';
import { useLogin } from '../hooks/query';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';

const ProtectedRoute = ({ canView = true, children, ...props }) => {
  const { data } = useLogin();

  // User is logged in
  if (data && data.id) {
    if (canView) {
      return <Route {...props}>{children}</Route>;
    }
    return (
      <Route>
        <NotFound />
      </Route>
    );
  }

  // User is logged out
  return (
    <Route {...props}>
      <Login />
    </Route>
  );
};

export default ProtectedRoute;
