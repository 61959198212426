import { locale, localeFormat } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { useDirectory } from '../../../hooks/query';
import { useDeleteUser } from '../../../hooks/mutate';

const DeleteUser = ({ closeModal, userId, name, familyId }) => {
  const { data: family } = useDirectory((d) =>
    d.families.find(({ id }) => id === familyId)
  );
  const willDeleteFamily = family.members.length === 1;

  const { mutate } = useDeleteUser(familyId, willDeleteFamily, {
    _onMutate: closeModal,
  });

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: () => mutate(userId),
      primary: true,
      children: locale.COMMON.CONFIRM,
    },
  ];

  const fullName = `${name.first} ${name.last}`;

  return (
    <>
      <Header title={locale.MODALS.DELETE_USER._} />
      <Body>{localeFormat(locale.MODALS.DELETE_USER.CONFIRM, fullName)}</Body>
      <Footer footerBtns={footerBtns} />
    </>
  );
};

export default DeleteUser;
