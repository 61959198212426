/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { locale, objectToQueryParams } from '@churchms/shared';
import { listings } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import { modalTypes } from '../../contexts/ModalContext';

const useDeleteListing = ({ _onMutate = () => {} } = {}) => {
  const history = useHistory();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const listingsKey = listings();

  const removeUser = useCallback(
    (listingId) =>
      fetchWithJWT(
        `/api/v1/listing${objectToQueryParams({ listingId })}`,
        {
          method: 'DELETE',
        },
        locale.HTTP.ERROR.LISTING_DELETE
      ),
    [fetchWithJWT]
  );

  return useMutation({
    mutationFn: removeUser,
    onMutate: async (listingId) => {
      _onMutate();

      history.push('/market');

      const filterListing = (l) => l.id !== listingId;

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(listingsKey);

      // Snapshot the previous value
      const previousListings = queryClient.getQueryData(listingsKey);

      // Optimistically update to the new value
      queryClient.setQueryData(listingsKey, (prev) => {
        return prev.filter(filterListing);
      });

      // Return a context object with the snapshotted value
      return { previousListings };
    },
    onError: (err, variables, { previousListings }) => {
      queryClient.setQueryData(listingsKey, previousListings);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: listingsKey });
    },
  });
};

export default useDeleteListing;
