import styles from './styles.module.scss';

const DatePicker = ({
  label,
  hideLabel = false,
  required = false,
  onChange,
  value,
  min,
  max,
  ...props
}) => {
  // iOS fix for clear
  // https://stackoverflow.com/questions/36977256/ios-safari-clear-input-type-date
  const onFocus = (e) => {
    e.target.defaultValue = '';
  };

  const input = (
    <input
      type="date"
      value={value || ''}
      onChange={onChange}
      required={required}
      placeholder="dd/mm/yyyy"
      className={styles.textFieldInput}
      onFocus={onFocus}
      min={min}
      max={max}
      {...props}
    />
  );

  if (!label) return input;

  return (
    <label className={styles.textFieldLabel}>
      <span className={hideLabel ? styles.hideLabel : ''}>
        {label}
        {required && '*'}
      </span>
      {input}
    </label>
  );
};

export default DatePicker;
