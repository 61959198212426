/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MINUTE, locale } from '@churchms/shared';
import { listings } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import useLogin from './useLogin';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 10 * MINUTE,
  placeholderData: [],
};

export const useFetchListings = () => {
  const fetchWithJWT = useFetchWithJWT();

  const fetchListings = useCallback(
    () => fetchWithJWT(`/api/v1/listings`, {}, locale.HTTP.ERROR.LISTINGS),
    [fetchWithJWT]
  );

  return fetchListings;
};

const useListings = (select) => {
  const { data } = useLogin();
  const churchId = data?.churchId;

  const fetchListings = useFetchListings();
  const isValid = useTokenIsValid();

  return useQuery({
    queryKey: listings(),
    queryFn: fetchListings,
    select,
    enabled: !!churchId && isValid(),
    ...config,
  });
};

export default useListings;
