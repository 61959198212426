import { locale } from '@churchms/shared';
import { useCreateFamily } from '../../../hooks/mutate';
import CreateUserOrFamily from './CreateUserOrFamily';

const CreateFamily = ({ modalType, closeModal }) => {
  const { mutate, isLoading, error } = useCreateFamily({
    _onMutate: closeModal,
  });

  const init = { isAdult: true };

  return (
    <CreateUserOrFamily
      mutate={mutate}
      isLoading={isLoading}
      error={error}
      modalType={modalType}
      legend={locale.MODALS.CREATE_FAMILY.LEGEND}
      title={locale.MODALS.CREATE_FAMILY._}
      isNewFamily
      init={init}
    />
  );
};

export default CreateFamily;
