import styles from './styles.module.scss';

const Range = ({
  label,
  fullWidth,
  hidden,
  hideLabel,
  required,
  error,
  value,
  onChange,
  min = 1,
  max = 10,
  step = 0.1,
  disabled,
}) => {
  const input = (
    <input
      className={styles.range}
      type="range"
      min={min}
      value={value}
      max={max}
      step={step}
      onChange={onChange}
      disabled={disabled}
    />
  );

  if (!label) {
    return input;
  }

  return (
    <label
      className={`${styles.textFieldLabel}${
        fullWidth ? ` ${styles.fullWidth}` : ''
      }${hidden ? ` ${styles.hidden}` : ''}`}
    >
      <span className={hideLabel ? styles.hideLabel : ''}>
        {label}
        {required ? '*' : ''}
      </span>
      {input}
      {error && <div>{error?.message || error}</div>}
    </label>
  );
};

export default Range;
