import { createContext } from 'react';

const EditContext = createContext({
  isEditing: false,
  setIsEditing: () => {},
  toggleEditing: () => {},
  onAdd: () => {},
  onEdit: () => {},
});

export default EditContext;
