import { useCallback, useState } from 'react';
import {
  formatDatePickerDate,
  locale,
  toDateString,
  isAdult as isAdultFn,
  isValidBirthday,
} from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useEditUserBirthday } from '../../../hooks/mutate';

const EditBirthday = ({ modalType, birthday, isAdult, userId, closeModal }) => {
  const max = toDateString(new Date());
  const [newBirthday, setNewBirthday] = useState(toDateString(birthday));
  const [newIsAdult, setNewIsAdult] = useState(isAdult);
  const { mutate } = useEditUserBirthday(userId, { _onMutate: closeModal });
  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({
          birthday: formatDatePickerDate(newBirthday, true),
          isAdult: newIsAdult,
        });
      }
    },
    [newBirthday, newIsAdult, modalType, mutate]
  );

  const footerBtns = [
    {
      children: locale.COMMON.CANCEL,
    },
    {
      onClick: onSave,
      primary: true,
      children: locale.COMMON.SAVE,
      type: 'submit',
    },
  ];

  const onBirthday = (e) => {
    const value = e.target.value;
    setNewBirthday(value);
    setNewIsAdult(isAdultFn(formatDatePickerDate(value)));
  };

  const onIsAdult = useCallback(
    (e) => {
      const checked = e.target.checked;
      const validBirthday = isValidBirthday(checked, newBirthday)
        ? newBirthday
        : '';
      setNewIsAdult(checked);
      setNewBirthday(validBirthday);
    },
    [newBirthday]
  );

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.EDIT_BIRTHDAY._} />
      <Body>
        <Form.Date
          label={locale.COMMON.BIRTHDAY}
          hideLabel
          value={newBirthday}
          onChange={onBirthday}
          max={max}
        />
        <Form.Checkbox
          label={locale.MODALS.CREATE_USER.IS_ADULT}
          checked={newIsAdult}
          onChange={onIsAdult}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default EditBirthday;
