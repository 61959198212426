import LoadingOrError from '../LoadingOrError';
import styles from './styles.module.scss';

const Body = ({ className, isLoading, error, children }) => (
  <div className={`${styles.pageBody}${className ? ` ${className}` : ''}`}>
    <LoadingOrError
      errorClassName={styles.error}
      isLoading={isLoading}
      error={error}
    >
      {children}
    </LoadingOrError>
  </div>
);

export default Body;
