import { locale } from '@churchms/shared';
import IconButton from '../IconButton';
import { ReactComponent as EditSVG } from '../../icons/create.svg';
import { ReactComponent as DoneSVG } from '../../icons/done.svg';
import { ReactComponent as AddSVG } from '../../icons/add.svg';
import styles from './styles.module.scss';
import { useEditContext } from '../../hooks/context';

const EditControls = ({ children }) => {
  const { isEditing, toggleEditing, onAdd, onEdit } = useEditContext();

  if (isEditing) {
    return (
      <IconButton className={styles.doneButton} onClick={toggleEditing}>
        {locale.COMMON.DONE} <DoneSVG />
      </IconButton>
    );
  }

  return (
    <div className={styles.editControls}>
      {children}
      {onAdd && (
        <IconButton onClick={onAdd}>
          <AddSVG />
        </IconButton>
      )}
      {onEdit && (
        <IconButton onClick={onEdit}>
          <EditSVG />
        </IconButton>
      )}
    </div>
  );
};

export default EditControls;
