/* istanbul ignore file */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale } from '@churchms/shared';
import { fetchFn } from '../../utils';
import { forgotPasswordKey } from '../queryKeys';
import mapJSend from '../../utils/mapJSend';

const useForgotPassword = () => {
  const queryClient = useQueryClient();

  const forgotPassword = (email) =>
    fetchFn(
      '/api/v1/forgot-password',
      {
        method: 'PUT',
        body: {
          email,
        },
      },
      locale.HTTP.ERROR.FORGOT_PASSWORD
    ).then(mapJSend);

  return useMutation({
    mutationFn: forgotPassword,
    onSuccess: (data, email) => {
      queryClient.setQueryData(forgotPasswordKey(email), data);
    },
  });
};

export default useForgotPassword;
