import RemoveableItemButton from '../../RemoveableItemButton';
import styles from './styles.module.scss';

const RemovableItem = ({ isEditing, disabled, children, onRemove }) => (
  <div
    className={`${styles.removableItem}${
      disabled ? ` ${styles.disabledLink}` : ''
    }`}
  >
    {children}
    {isEditing && (
      <RemoveableItemButton onClick={onRemove} disabled={disabled} />
    )}
  </div>
);

export default RemovableItem;
