import styles from './styles.module.scss';

const SingleFile = ({ label, fullWidth, required, hideLabel, ...props }) => {
  const input = (
    <input type="file" name="file" className={styles.singleFile} {...props} />
  );

  if (!label) {
    return input;
  }

  return (
    <label
      className={`${styles.textFieldLabel}${
        fullWidth ? ` ${styles.fullWidth}` : ''
      }`}
    >
      <span className={hideLabel ? styles.hideLabel : ''}>
        {label}
        {required ? '*' : ''}
      </span>
      {input}
    </label>
  );
};

export default SingleFile;
