/* istanbul ignore file */

import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { locale, objectToQueryParams } from '@churchms/shared';
import { directory, login } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useModalContext } from '../context';
import useLogin from '../query/useLogin';
import { modalTypes } from '../../contexts/ModalContext';

const useChangeName = (userId, { _onMutate = () => {} } = {}) => {
  const { data } = useLogin();
  const { openModal } = useModalContext();
  const fetchWithJWT = useFetchWithJWT();
  const queryClient = useQueryClient();
  const directoryKey = directory();
  const loginKey = login();

  const changeName = useCallback(
    (name) =>
      fetchWithJWT(
        `/api/v1/user/change-name${objectToQueryParams({ userId })}`,
        {
          method: 'PUT',
          body: { name },
        },
        locale.HTTP.ERROR.CHANGE_USER_NAME
      ),
    [fetchWithJWT, userId]
  );

  return useMutation({
    mutationFn: changeName,
    onMutate: async (name) => {
      _onMutate();

      const mapNewName = (prev) => {
        if (prev.id === userId) {
          return {
            ...prev,
            name,
          };
        }
        return prev;
      };

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(directoryKey);

      // Snapshot the previous value
      const previousDirectory = queryClient.getQueryData(directoryKey);
      const previousLogin = queryClient.getQueryData(loginKey);

      // Optimistically update to the new value
      queryClient.setQueryData(directoryKey, (prev) => ({
        ...prev,
        families: prev.families.map((family) => {
          const found = family.members.some(({ id }) => id === userId);
          if (found) {
            return {
              ...family,
              members: family.members.map(mapNewName),
            };
          }
          return family;
        }),
        users: prev.users.map(mapNewName),
      }));

      if (data.id === userId) {
        queryClient.setQueryData(loginKey, mapNewName);
      }

      // Return a context object with the snapshotted value
      return { previousDirectory, previousLogin };
    },
    onError: (err, name, { previousDirectory, previousLogin }) => {
      queryClient.setQueryData(directoryKey, previousDirectory);
      queryClient.setQueryData(loginKey, previousLogin);
      openModal(modalTypes.ERROR, { message: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: directoryKey });
    },
  });
};

export default useChangeName;
