const extractKeywords = (search) => {
  const searchString = search.trim().toLowerCase();
  const split = searchString.split(' ');

  const ageRegex = /age(>|<)[0-9]{1,3}/;

  const { searchArray, ageWords, ...rest } = split.reduce(
    (acc, word) => {
      if (ageRegex.test(word)) {
        acc.ageWords = [...acc.ageWords, word];
        return acc;
      }

      switch (word) {
        case 'men':
          acc.men = true;
          return acc;
        case 'women':
          acc.women = true;
          return acc;
        case 'children':
          acc.children = true;
          return acc;
        default:
          acc.searchArray = [...acc.searchArray, word];
          return acc;
      }
    },
    {
      searchArray: [],
      ageWords: [],
      men: false,
      women: false,
      children: false,
    }
  );

  const ageFn = (birthday) => {
    if (!ageWords.length) {
      return true;
    }

    if (!birthday) {
      return false;
    }

    const date = new Date(birthday);
    const today = new Date();
    const age = today.getFullYear() - date.getFullYear();

    return ageWords.every((ageWord) => {
      const less = ageWord.includes('<');
      const number = parseInt(ageWord.replace(/age(<|>)/, ''));
      return less ? age < number : age > number;
    });
  };

  return {
    searchString: searchArray.join(' '),
    ageFn,
    ...rest,
  };
};

export default extractKeywords;
