import { locale } from '@churchms/shared';
import { useEditListing } from '../../../hooks/mutate';
import Listing, { useListingState } from './Listing';

const EditListing = ({ modalType, closeModal, id, ...listing }) => {
  const { mutate } = useEditListing({ _onMutate: closeModal });

  const props = useListingState(listing);

  return (
    <Listing
      modalTitle={locale.MODALS.EDIT_LISTING._}
      submitButtonText={locale.COMMON.SAVE}
      mutate={mutate}
      modalType={modalType}
      {...props}
      id={id}
    />
  );
};

export default EditListing;
