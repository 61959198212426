import { useCallback } from 'react';
import { phonePattern } from '@churchms/shared';
import TextField from './TextField';
import { formatPhoneNumber } from '../../utils';

const PhoneNumber = ({ onChange, ...props }) => {
  const _onChange = useCallback(
    (e) => {
      const value = e.target.value;

      // prepend 1 to the beginning
      if (value.length === 10 && !isNaN(value)) {
        e.target.value = formatPhoneNumber(`1${value}`);
      } else {
        e.target.value = formatPhoneNumber(e.target.value);
      }
      onChange(e);
    },
    [onChange]
  );
  return (
    <TextField
      placeholder="X-XXX-XXX-XXXX"
      onChange={_onChange}
      {...props}
      type="tel"
      pattern={phonePattern}
    />
  );
};

export default PhoneNumber;
