import { useCallback, useMemo, useState } from 'react';
import { locale } from '@churchms/shared';
import Header from '../Header';
import Body from '../Body';
import Footer from '../Footer';
import { Form } from '../../../components';
import { useDirectory } from '../../../hooks/query';
import { useChangeName } from '../../../hooks/mutate';

const ChangeName = ({ closeModal, modalType, id }) => {
  const { data: found } = useDirectory((d) => d.users.find((u) => u.id === id));
  const [first, setFirst] = useState(found.name.first);
  const [last, setLast] = useState(found.name.last);
  const { data: isDuplicate } = useDirectory((d) => {
    return d.users.some((u) => u.name.first === first && u.name.last === last);
  });

  const { mutate, error } = useChangeName(id, { _onMutate: closeModal });

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      // Force it to check validity:
      const res = document.forms[modalType].reportValidity();

      if (res) {
        mutate({ first, last });
      }
    },
    [modalType, first, last, mutate]
  );

  const footerBtns = useMemo(() => {
    return [
      {
        children: locale.COMMON.CANCEL,
      },
      {
        onClick: onSave,
        primary: true,
        children: locale.COMMON.SAVE,
        disabled: isDuplicate,
        type: 'submit',
      },
    ];
  }, [onSave, isDuplicate]);

  const onFirst = (e) => setFirst(e.target.value);
  const onLast = (e) => setLast(e.target.value);

  const _error = isDuplicate ? locale.HTTP.ERROR.NAME_EXISTS : error;

  return (
    <Form.Wrapper onSubmit={onSave} id={modalType}>
      <Header title={locale.MODALS.CHANGE_NAME._} />
      <Body>
        <Form.TextField
          label={locale.COMMON.FIRST_NAME}
          value={first}
          onChange={onFirst}
          required
        />
        <Form.TextField
          label={locale.COMMON.LAST_NAME}
          value={last}
          onChange={onLast}
          required
          error={_error}
        />
      </Body>
      <Footer footerBtns={footerBtns} />
    </Form.Wrapper>
  );
};

export default ChangeName;
