const formatPhoneNumber = (value) => {
  const numbersOnly = value.replace(/[^0-9]+/g, '').slice(0, 11);

  if (!numbersOnly.length) return '';

  const country = numbersOnly[0];
  const area = numbersOnly.slice(1, 4);
  const group1 = numbersOnly.slice(4, 7);
  const group2 = numbersOnly.slice(7, 11);

  if (numbersOnly.length >= 8) {
    return `${country}-${area}-${group1}-${group2}`;
  } else if (numbersOnly.length >= 5) {
    return `${country}-${area}-${group1}`;
  } else if (numbersOnly.length >= 2) {
    return `${country}-${area}`;
  }

  return country;
};

export default formatPhoneNumber;
