import { Link } from 'react-router-dom';
import HiddenIcon from '../../HiddenIcon';
import Content from './Content';
import styles from './styles.module.scss';
import QuickContactButtons from './QuickContactButtons';
import ShareButton from '../../ShareButton';

const Family = ({
  surname,
  id,
  members,
  isOptimistic = false,
  headOfHousehold,
}) => {
  const isHidden = members.every(({ isHidden }) => isHidden);

  const headUser = members.find((m) => m.id === headOfHousehold);

  return (
    <div className={`${styles.userOrFamilyItem} ${styles.clickableFlex}`}>
      <Link
        to={`/directory/${id}`}
        className={isOptimistic ? styles.disabledLink : ''}
      >
        <Content name={surname} familyId={!isOptimistic && id}>
          <p>
            {surname}, {headUser?.name?.first}
          </p>
          {isHidden && <HiddenIcon />}
        </Content>
      </Link>
      <QuickContactButtons>
        <ShareButton users={members} />
      </QuickContactButtons>
    </div>
  );
};

export default Family;
