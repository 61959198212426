import { useCallback } from 'react';
import { ReactComponent as PhoneSVG } from '../../../icons/phone.svg';
import { ReactComponent as MessageSVG } from '../../../icons/message.svg';
import { formatPhoneLink, formatSMSLink } from '../../../utils';
import ButtonAsLink from '../../ButtonAsLink';
import { usePerms } from '../../../hooks';
import { useEditContext, useModalContext } from '../../../hooks/context';
import { modalTypes } from '../../../contexts/ModalContext';
import RemovableItem from './RemovableItem';
import Content from './Content';
import styles from './styles.module.scss';
import Label from '../../Label';

const PhoneNumber = ({
  sms = false,
  label,
  value,
  userId,
  id,
  familyId,
  isOptimistic = false,
}) => {
  const { isSelf, isAdmin, isHeadOfHousehold } = usePerms({
    targetUserId: userId,
    targetFamilyId: familyId,
  });
  const { isEditing } = useEditContext();
  const canRemove = isSelf || isAdmin || isHeadOfHousehold;
  const { openModal } = useModalContext();
  const onDelete = () =>
    openModal(modalTypes.DELETE_PHONE, { userId, phoneId: id, value });
  const onEdit = useCallback(() => {
    openModal(modalTypes.EDIT_PHONE, {
      label,
      value,
      userId,
      phoneId: id,
    });
  }, [label, value, userId, id, openModal]);

  const overrideLabel = sms ? 'Text' : label;
  const content = (
    <Content name={label} Icon={sms ? MessageSVG : PhoneSVG}>
      <Label>{overrideLabel}</Label>
      <p>{value}</p>
    </Content>
  );

  if (isEditing && canRemove) {
    return (
      <RemovableItem isEditing onRemove={onDelete} disabled={isOptimistic}>
        <ButtonAsLink
          disabled={isOptimistic}
          className={styles.clickableFlex}
          onClick={onEdit}
        >
          {content}
        </ButtonAsLink>
      </RemovableItem>
    );
  }

  return (
    <a
      href={sms ? formatSMSLink(value) : formatPhoneLink(value)}
      className={`${styles.clickableFlex}${
        isOptimistic ? ` ${styles.disabledLink}` : ''
      }`}
    >
      {content}
    </a>
  );
};

export default PhoneNumber;
