import { middle, smallerRadius } from './Svg';
import styles from './styles.module.scss';

const AvatarDefinitions = () => (
  <svg className={styles.definitions}>
    <clipPath id="clip">
      <circle cx={middle} cy={middle} r={smallerRadius} />
    </clipPath>
    <filter id="blur">
      <feGaussianBlur stdDeviation="2" in="SourceGraphic" result="BLUR" />
    </filter>
  </svg>
);

export default AvatarDefinitions;
